
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

import videosList from '@/data/support-videos.json';

@Component
export default class DashboardWelcome extends BaseComponent {
  showModal = false;

  selectedVideo?: object = {};

  get userName() {
    return this.$store.state.auth.identity?.firstName;
  }

  get videos() {
    return videosList;
  }

  showVideo(video: object) {
    this.selectedVideo = video;
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }
}
