
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  BreadcrumbItem, CreateWorkflow,
} from '@/types';
import { actions } from '@/store';

@Component
export default class CreateTaskAssignToMe extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.contacts',
    },
  ];

  formHandler(payload: CreateWorkflow) {
    this.$store.dispatch(actions.WORKFLOW_CREATE, payload)
      .then(() => {
        this.$router.replace('/workflows');
      })
      .catch((err) => {
        this.handleError(err, 'screen.workflows.create.error.title');
      });
  }
}
