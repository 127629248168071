import { AxiosResponse } from 'axios';

import { DocumentStatus } from '@/types';

import {
  api,
} from './api_base';

export default class {
  static list(): Promise<AxiosResponse<DocumentStatus []>> {
    return api.get<DocumentStatus []>('/document-request-statuses');
  }
}
