import { Component, Prop, Watch } from 'vue-property-decorator';
import { createNamespacedHelpers } from 'vuex';

import store, { actions } from '@/store';
import { AuthRequest, AuthResponse } from '@/types';

import {
  required,
} from '@/utils/validations';

import BaseComponent from './base-component';

const { mapState, mapActions } = createNamespacedHelpers('auth');

@Component({
  computed: {
    ...mapState([
      'loading',
      'authenticating',
    ]),
  },

  methods: mapActions([
    'authenticate',
  ]),
})
export default class SignInBase extends BaseComponent {
  @Prop() identifier?: string;

  @Prop() password?: string;

  private authenticate?: (payload: AuthRequest) => Promise<AuthResponse>;

  validators = {
    email: required(this.$t('required.field', [this.$t('email')])),
    password: required(this.$t('required.field', [this.$t('password')])),
  };

  state: any;

  @Watch('show')
  onShow(newValue: boolean, oldValue: boolean) {
    (this.$refs.identifier as any).clearValue('');
    (this.$refs.password as any).clearValue('');
  }

  get show() {
    return this.$store.state.ui.showLogin;
  }

  updateState(state: any) {
    this.state = state;
  }

  submit(values: AuthRequest) {
    if (this.snackbarRef) {
      this.snackbarRef.close();
    }
    if (this.authenticate) {
      this.authenticate(values).then(() => {
        if (this.$route.query.returnUrl) {
          this.$router.replace({
            name: 'TwoFactorAuth',
            query: {
              returnUrl: this.$route.query.returnUrl,
            },
          });
        } else {
          this.$router.replace('/dashboard');
        }
      }).catch((err) => {
        this.handleError(err, 'form.signIn.error.title');
      });
    }
  }

  hide() {
    this.$store.dispatch(actions.LOGIN_HIDE);
  }

  async navigateToResetPassword() {
    await this.$router.push('/forgot-password');
  }
}
