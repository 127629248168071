
import { Component, Emit, Prop } from 'vue-property-decorator';
import { actions } from '@/store';
import { Workflow } from '@/types';

import BaseComponent from './base-component';

const defaultWorkflow = {
  id: '',
  name: 'Select Workflow',
};

@Component
export default class WorkflowDropdown extends BaseComponent {
  defaultWorkflow = defaultWorkflow;

  @Prop({ default: () => defaultWorkflow })
    selected?: Workflow;

  @Prop({ default: () => [] }) workflows?: Workflow[];

  get pSelected() {
    return this.selected;
  }

  get pWorkflows() {
    return this.$store.state.workflowSearch.data.map((id: string) => this.getById(id));
  }

  getById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  get computed() {
    return {
      loading: this.$store.state.workflowSearch.loading,
    };
  }

  mounted() {
    this.$store.dispatch(actions.WORKFLOWS_SEARCH);
  }

  @Emit('change')
  change(workflow: Workflow) {
    if (workflow.id !== this.selected?.id) {
      this.selected = workflow;
    }
  }
}
