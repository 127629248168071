
import { Component } from 'vue-property-decorator';

import {
  CreateContact,
} from '@/types';
import { actions } from '@/store';

import Contact from './Contact';

@Component
export default class EditContact extends Contact {
  loading = false;

  formHandler(payload: CreateContact) {
    this.$store.dispatch(actions.CONTACT_UPDATE, {
      id: this.$route.params.id,
      ...payload,
    })
      .then(() => {
        this.$router.replace(`/contacts/${this.$route.params.id}`);
      })
      .catch((err) => {
        this.handleError(err, 'form.updateContact.error.title');
      });
  }

  async loadData() {
    this.loading = true;
    this.$store.dispatch(actions.CONTACT_GET_BY_ID, this.$route.params.id)
      .then((contactId: string) => {
        this.loading = false;
        (this.$refs.AddUpdateContact as any)
          .assignValues(this.$store.state.contactList.data[contactId]);
      });
  }
}
