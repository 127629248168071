import Vue from 'vue';

import Field from './Field.vue';
import TextField from './TextField.vue';
import EmailField from './EmailField.vue';
import PhoneField from './PhoneField.vue';

Vue.component('Field', Field);
Vue.component('TextField', TextField);
Vue.component('EmailField', EmailField);
Vue.component('PhoneField', PhoneField);
