
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Workflow } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class WorkflowList extends BaseComponent {
  @Prop({ default: true }) hasActions?: boolean;

  @Prop({ default: () => [] }) workflows?: Workflow[];

  @Prop() contact?: string;

  @Prop({ default: false }) isThirdParty?: boolean;

  get pHasActions() {
    return this.hasActions;
  }

  private get pWorkflows(): Workflow[] | undefined {
    return this.workflows;
  }

  private set pWorkflows(value: Workflow[] | undefined) {
    this.workflows = value;
  }

  get pIsThirdParty() {
    return this.isThirdParty;
  }

  @Emit('onActionClick')
  onActionClick(workflow: Workflow, action: string) {
    // ignore
  }

  onToggle(contact: Workflow, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }
}
