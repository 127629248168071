import { ActionTree } from 'vuex';

import {
  Country,
  CustomError,
  Module,
} from '@/types';
import { Country as api } from '@/api';
import { RootState, CountryState } from '../states';

const getDefaultState = (): CountryState => ({
  all: [],
  loading: false,
  error: null,
});

const getters = {
  isFetched(state: CountryState) {
    return state.all.length > 0;
  },
  getCountries(state: CountryState) {
    return state.all;
  },
};

const actions: ActionTree<CountryState, RootState> = {
  async getAll({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Country[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getAll();
        const { data } = resp;

        commit('setLoading', false);
        commit('setCountries', data);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: CountryState, loading: boolean) {
    state.loading = loading;
  },
  setCountries(state: CountryState, countries: Country[]) {
    state.all = countries;
  },
  setError(state: CountryState, error: CustomError) {
    state.error = error;
  },
  reset(state: CountryState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): CountryState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const country: Module<CountryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
