import Vue from 'vue';

Vue.filter('name-initial', (value: string) => {
  if (!value) return '';

  const arr = value.split(' ');
  let initial = arr[0][0]?.trim().toUpperCase();

  if (arr[1] && arr[1][0]) {
    initial += arr[1][0].trim().toUpperCase();
  }

  return initial;
});
