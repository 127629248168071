
import { Component, Watch } from 'vue-property-decorator';

import { actions } from '@/store';
import storage from '@/storage';
import { Business, BusinessUser } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class DashboardHeader extends BaseComponent {
  sBusiness = '';

  get businesses() {
    if (this.$store.state.auth.identity) {
      return this.$store.state.auth.identity.businesses;
    }
    return [];
  }

  showSidebar() {
    this.$store.dispatch(actions.SIDEBAR_SHOW_ON_MOBILE);
  }

  get isPersonalSubscribed() {
    return this.$store.state.auth.identity?.subscription?.status === 'paid';
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  async onChangeBusiness(businessId: string, preventRedirect = false) {
    if (!businessId) {
      if (this.isPersonalSubscribed) {
        this.sBusiness = '';
        this.$store.commit('business/setSelectedBusiness', '');
        if (!preventRedirect) {
          await this.$router.replace({ name: 'Dashboard' });
        }
      } else {
        setTimeout(this.onChangeSelectedBusiness, 500);
        this.$buefy.dialog.alert({
          title: 'Individual plan not subscribed',
          message: 'You don\'t have a individual account subscription.',
          type: 'is-danger',
          hasIcon: true,
          confirmText: 'Subscribe',
          canCancel: true,
          onConfirm: () => {
            this.$store.commit('business/setSelectedBusiness', '');
            this.$router.push({
              name: 'Subscription',
              query: {
                expired: 'true',
              },
            });
          },
        });
      }
      return;
    }
    const selectedBusiness = this.businesses.find(
      (business: Business) => business.id === businessId,
    );
    if (selectedBusiness) {
      if (selectedBusiness?.subscription?.status === 'paid') {
        this.sBusiness = businessId;
        this.$store.commit('business/setSelectedBusiness', businessId);
        if (!preventRedirect) {
          try {
            await this.$router.replace({ name: 'Dashboard' });
          } catch (err) {
            //
          }
        }
      } else {
        const ref = this.$buefy.loading.open({
          isFullPage: true,
        });
        let isAdmin = false;
        try {
          const users = await this.$store.dispatch('business/getBusinessUsers', businessId);

          const me = users.find(
            (user: BusinessUser) => user.status === 'accepted'
              && this.$store.state.auth.identity.id === user.user.id,
          );

          if (me) {
            isAdmin = me.business_role.name === 'Administrator';
          }
        } catch (err) {
          this.handleError(err as Error);
        } finally {
          ref.close();
        }
        this.$buefy.dialog.alert({
          title: 'Business not subscribed',
          message: 'The selected business doesn\'t have a active subscription',
          type: 'is-danger',
          hasIcon: true,
          confirmText: isAdmin ? 'Subscribe' : 'Okay',
          canCancel: isAdmin,
          onConfirm: () => {
            if (isAdmin) {
              this.$store.commit('business/setSelectedBusiness', businessId);
              this.$router.push({
                name: 'Subscription',
                query: {
                  expired: 'true',
                },
              });
            }
          },
        });
        setTimeout(this.onChangeSelectedBusiness, 500);
      }
    }
  }

  @Watch('selectedBusiness')
  onChangeSelectedBusiness() {
    this.sBusiness = this.selectedBusiness;
  }

  mounted() {
    const { selectedBusiness } = storage;
    if (this.businesses && this.businesses.length) {
      if (this.isPersonalSubscribed && !selectedBusiness) {
        return;
      }
      const exits = this.businesses.find(
        (business: Business) => business.id === selectedBusiness,
      );
      if (exits) {
        this.onChangeBusiness(selectedBusiness, true);
      } else {
        const subscribedB = this.businesses.find(
          (business: Business) => business?.subscription?.status === 'paid',
        );
        this.onChangeBusiness(subscribedB.id, true);
      }
    } else {
      this.onChangeBusiness('', true);
    }
  }

  toggleSearch() {
    this.$router.push('/search');
  }
}
