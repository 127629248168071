
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusinessUser } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class UsersList extends BaseComponent {
  @Prop({ default: false }) selectable?: boolean;

  @Prop({ default: () => [] }) users?: BusinessUser[];

  selected: string[] = [];

  get pSelectable() {
    return this.selectable;
  }

  get pUsers() {
    return this.users || [];
  }

  get businessRoles() {
    return this.$store.getters['business/getRoles'];
  }

  get isAdmin() {
    const me = this.pUsers.find((user) => user.status === 'accepted' && this.isMe(user.user.id));
    if (!me) {
      return false;
    }
    return me.business_role.name === 'Administrator';
  }

  isMe(id: string) {
    return this.$store.state.auth.identity.id === id;
  }

  async changeRole(bUserId: string, role: string) {
    const roleLoadingRef = this.$buefy.loading.open({
      isFullPage: true,
    });
    try {
      await this.$store.dispatch('business/changeRole', {
        userId: bUserId,
        role,
      });
      this.$buefy.toast.open({
        message: 'Role Changed',
        position: 'is-bottom',
      });
    } catch (err) {
      this.handleError(err as Error);
    } finally {
      roleLoadingRef.close();
    }
  }

  async sendReminder(user: BusinessUser) {
    const roleLoadingRef = this.$buefy.loading.open({
      isFullPage: true,
    });
    try {
      await this.$store.dispatch('business/inviteUser', {
        email: user.user.email ? user.user.email : user.invite_user.email,
        role: user.business_role.id,
      });
      this.$buefy.toast.open({
        message: 'Reminder Sent',
        position: 'is-bottom',
      });
    } catch (err) {
      this.handleError(err);
    } finally {
      roleLoadingRef.close();
    }
  }

  @Watch('selectable')
  onChangeSelectable() {
    this.selected = [];
  }

  @Watch('selected')
  onSelectedChange() {
    this.$emit('selectedChange', this.selected);
  }
}
