
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class WorkflowsTabBar extends BaseComponent {
  @Prop({ default: 0 }) selectedIndex?: number;

  get pSelectedIndex() {
    return this.selectedIndex;
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }
}
