import Vue from 'vue';
import { ActionTree } from 'vuex';

import {
  Category as api,
} from '@/api';

import {
  Category,
  CustomError,
  Module,
} from '@/types';

import { RootState, CategoriesState } from '../states';
import { Callback, callback } from './utils';

const getDefaultState = (): CategoriesState => ({
  data: {},
  ids: [],
  loading: false,
  error: null,
});

const getters = {
  list: (state: CategoriesState) => state.ids.map((id) => state.data[id]),
  otherId: (state: CategoriesState) => state.ids.map((id) => state
    .data[id]).find((category) => category.code === process.env.VUE_APP_CATEGORY_OTHER_CODE)?.id,
};

let listPromises: Callback[] = [];

const actions: ActionTree<CategoriesState, RootState> = {
  async list({ commit, state }: any) {
    if (state.loading) {
      const cb = callback();

      listPromises.push(cb);

      return cb.promise;
    }

    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Category[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.list();
        const { data } = resp;
        const ids = data.map((action) => action.id);

        commit('setData', data);
        commit('setIds', ids);

        resolve(ids);

        listPromises.forEach((cb) => {
          cb.resolve(ids);
        });
      } catch (error) {
        commit('setError', error);

        reject(error);

        listPromises.forEach((cb) => {
          cb.reject(error);
        });
      }

      commit('setLoading', false);

      listPromises = [];
    });
  },
};

const mutations = {
  setLoading(state: CategoriesState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: CategoriesState, error: CustomError) {
    state.error = error;
  },
  setData(state: CategoriesState, data: Category []) {
    const d = state.data || {};

    data.forEach((item) => {
      d[item.id || ''] = item;
    });

    Vue.set(state, 'data', d);
  },

  setIds(state: CategoriesState, ids: string[]) {
    state.ids = ids;
  },
  reset(state: CategoriesState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): CategoriesState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const categories: Module<CategoriesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
