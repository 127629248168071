import { AxiosResponse } from 'axios';

import { SearchResults } from '@/types';
import {
  jwtApi,
} from './api_base';

export default class {
  static search(query: string): Promise<AxiosResponse<SearchResults>> {
    return jwtApi.get<SearchResults>(`/search?_q=${query}&page=1&pageSize=10`);
  }
}
