
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { Business, User } from '@/types';

@Component
export default class SubscriptionSuccess extends BaseComponent {
  created() {
    this.loadUser();
  }

  async loadUser() {
    let user: User | null = null;

    try {
      user = await this.$store.dispatch(actions.USER_ME);
    } catch (error) {
      // ignore error
    }

    let isSubscribed = false;

    const selectedBusiness = this.$store.getters['business/getSelectedBusiness'];
    if (selectedBusiness) {
      const sBusiness = user?.businesses?.find(
        (business: Business) => business.id === selectedBusiness,
      );
      isSubscribed = sBusiness?.subscription?.status === 'paid';
    } else {
      isSubscribed = user?.subscription?.status === 'paid';
    }

    if (isSubscribed) {
      await this.$router.replace({
        name: 'Dashboard',
        query: {
          payment: 'success',
        },
      });

      return;
    }

    setTimeout(() => {
      this.loadUser();
    }, 10000);
  }
}
