export interface Callback {
  promise: Promise<any>;
  resolve: (value: any) => any;
  reject: (value: any) => any;
}

export const callback = (): Callback => {
  let resolve = (value: any): void => undefined;
  let reject = (value: any): void => undefined;

  const promise = new Promise<any>((a, b) => {
    resolve = a;
    reject = b;
  });

  return {
    promise,
    resolve,
    reject,
  };
};
