
import { Component, Prop } from 'vue-property-decorator';

import { Contact } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class ContactListDropdown extends BaseComponent {
  @Prop({ default: () => [] }) contacts?: Contact[];

  @Prop() selected?: Contact;

  get pContact() {
    return this.contacts;
  }

  get pSelected() {
    return this.selected;
  }

  set pSelected(contact: Contact | undefined) {
    this.selected = contact;
  }

  changeContact(contact: Contact) {
    this.pSelected = contact;

    this.$emit('change', contact);
  }
}
