import Pagination from './pagination';

interface PaginatedResult<T> {
  results: Array<T>;
  pagination: Pagination;
}

export default PaginatedResult;

const emptyPagination: Pagination = {
  page: 0,
  pageSize: 10,
};

export const empty = (pagination = emptyPagination) => ({
  results: [],
  pagination,
});
