
import omit from 'lodash/omit';
import { Component } from 'vue-property-decorator';

import { sortByDateDesc } from '@/utils/functions';

import {
  defaultQuery,
} from '@/types';
import { actions } from '@/store';

import WorkflowComponent from './Workflow';

const queryWithFilters = omit(defaultQuery, 'pageSize');

@Component
export default class WorkflowRequests extends WorkflowComponent {
  dataFetched = false;

  private query = queryWithFilters;

  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.workflows',
      },
    ];
  }

  get pWorkflows() {
    const ids = this.$store.state.workflowSearch.requests.data.results as string[];

    const items = ids.map((id) => this.$store.getters['workflows/getById'](id));

    return items.sort(sortByDateDesc('updatedBy'));
  }

  get pagination() {
    return this.$store.state.workflowSearch.requests.data.pagination;
  }

  created() {
    this.loadData();
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  async loadData(query = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
      };

      await this.$store.dispatch(
        actions.WORKFLOWS_REQUESTS,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }
}
