import Vue from 'vue';

import TaskQuickActions from './TaskQuickActions.vue';
import TaskActions from './TaskActions.vue';
import TaskTabBar from './TaskTabBar.vue';
import CreateUpdateTask from './CreateUpdateTask.vue';
import CreateUpdateTaskAssignToMe from './CreateUpdateTaskAssignToMe.vue';
import TaskList from './TaskList.vue';
import TaskListItem from './TaskListItem.vue';
import TaskSummaryList from './TaskSummaryList.vue';
import TaskInfo from './TaskInfo.vue';
import ViewTaskQuickActions from './ViewTaskQuickActions.vue';

Vue.component('TaskQuickActions', TaskQuickActions);
Vue.component('TaskActions', TaskActions);
Vue.component('TaskTabBar', TaskTabBar);
Vue.component('CreateUpdateTask', CreateUpdateTask);
Vue.component('CreateUpdateTaskAssignToMe', CreateUpdateTaskAssignToMe);
Vue.component('TaskList', TaskList);
Vue.component('TaskListItem', TaskListItem);
Vue.component('TaskSummaryList', TaskSummaryList);
Vue.component('TaskInfo', TaskInfo);
Vue.component('ViewTaskQuickActions', ViewTaskQuickActions);
