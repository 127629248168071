
import { Component } from 'vue-property-decorator';

import { RegistrationRequest } from '@/types';
import { actions } from '@/store';
import {
  required,
  minTrimmed,
  max,
  isStrongPassword,
} from '@/utils/validations';

import BaseComponent from './base-component';

const { REGISTER_USER } = actions;

@Component
export default class SignUp extends BaseComponent {
  isBusiness = false;

  currentStep = 1;

  businessPayload: any = {};

  isInvited = false;

  inviteLoading = false;

  validators = {
    companyName: [
      required(this.$t('required.field', [this.$t('companyName')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyName')])),
      max(150, this.$t('tooLong', [this.$t('companyName')])),
    ],
    companyRegistrationID: [
      required(this.$t('required.field', [this.$t('companyRegistrationID')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyRegistrationID')])),
      max(20, this.$t('tooLong', [this.$t('companyRegistrationID')])),
    ],
    companyType: [
      required(this.$t('required.field', [this.$t('companyType')])),
    ],
    companyTypeDesc: [
      required(this.$t('required.field', [this.$t('companyTypeDesc')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyTypeDesc')])),
      max(250, this.$t('tooLong', [this.$t('companyTypeDesc')])),
    ],
    companyAddress1: [
      required(this.$t('required.field', [this.$t('companyAddress1')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyAddress1')])),
      max(200, this.$t('tooLong', [this.$t('companyAddress1')])),
    ],
    companyAddress2: [
      required(this.$t('required.field', [this.$t('companyAddress2')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyAddress2')])),
      max(200, this.$t('tooLong', [this.$t('companyAddress2')])),
    ],
    companyCity: [
      required(this.$t('required.field', [this.$t('companyCity')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyCity')])),
      max(150, this.$t('tooLong', [this.$t('companyCity')])),
    ],
    companyState: [
      required(this.$t('required.field', [this.$t('companyState')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyState')])),
      max(150, this.$t('tooLong', [this.$t('companyState')])),
    ],
    companyCountry: [
      required(this.$t('required.field', [this.$t('companyCountry')])),
    ],
    companyPostcode: [
      required(this.$t('required.field', [this.$t('companyPostcode')])),
      minTrimmed(3, this.$t('tooShort', [this.$t('companyPostcode')])),
      max(10, this.$t('tooLong', [this.$t('companyPostcode')])),
    ],
    firstName: [
      required(this.$t('required.field', [this.$t('firstName')])),
      max(30, this.$t('tooLong', [this.$t('firstName')])),
    ],
    lastName: [
      required(this.$t('required.field', [this.$t('lastName')])),
      max(30, this.$t('tooLong', [this.$t('lastName')])),
    ],
    email: [
      required(this.$t('required.field', [this.$t('workEmail')])),
      minTrimmed(5, this.$t('tooShort', [this.$t('workEmail')])),
      max(50, this.$t('tooLong', [this.$t('workEmail')])),
    ],
    mobile: [
      required(this.$t('required.field', [this.$t('mobileNumber')])),
      minTrimmed(8, this.$t('tooShort', [this.$t('mobileNumber')])),
      max(15, this.$t('tooLong', [this.$t('mobileNumber')])),
    ],
    country: required(this.$t('required.default')),
    password: [
      required(this.$t('required.field', [this.$t('createPassword')])),
      isStrongPassword('Password must match the password policy'),
    ],
    confirmPassword: [
      required(this.$t('required.field', [this.$t('confirmPassword')])),
      max(20, this.$t('tooLong', [this.$t('password')])),
      this.passwordMismatched,
    ],
    terms: required(this.$t('required.field', [this.$t('this')])),
  };

  companyTypeId = '';

  onChangeCompanyType(companyTypeId: string) {
    this.companyTypeId = companyTypeId;
  }

  get isTypeOther() {
    if (this.companyTypeId) {
      const types = this.$store.getters['business/getTypes'];
      const type = types.find((_type: any) => _type.id === this.companyTypeId);
      if (type.nameLower === 'other') {
        return true;
      }
    }
    return false;
  }

  private passwordMismatched(value: any, values: any) {
    return value === values.password ? undefined : this.$t('form.signUp.error.passwordMismatched');
  }

  async submit(values: any): Promise<void> {
    if (this.isBusiness && this.currentStep === 1) {
      this.businessPayload = {
        name: values.companyName.trim(),
        registrationId: values.companyRegistrationID.trim(),
        type: values.companyType.trim(),
        address1: values.companyAddress1.trim(),
        address2: values.companyAddress2.trim(),
        city: values.companyCity.trim(),
        state: values.companyState.trim(),
        businessCountry: values.companyCountry,
        zip: values.companyPostcode.trim(),
      };

      if (values.companyTypeDesc) {
        this.businessPayload.typeDesc = values.companyTypeDesc.trim();
      }

      this.currentStep = 2;
      return;
    }

    if (this.snackbarRef) {
      this.snackbarRef.close();
    }

    const payload: RegistrationRequest = {
      username: values.email.trim().toLowerCase(),
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email.trim().toLowerCase(),
      country: values.country,
      mobile: values.country.dialCode + values.mobile.trim(),
      password: values.password,
      tnc: values.tnc,
      optIn: values.optIn || false,
    };

    try {
      if (this.isBusiness && !this.$route.query.token) {
        await this.$store.dispatch('business/registerBusiness', {
          ...this.businessPayload,
          ...payload,
        });
        await this.$router.replace('/registration-success');
      } else {
        await this.$store.dispatch(REGISTER_USER, payload);
        if (this.$route.query.token) {
          const users = await this.$store.dispatch('business/acceptInvite', this.$route.query.token);
          if (users.status === 'accepted') {
            this.$buefy.toast.open({
              message: 'Invite Accepted',
              position: 'is-bottom',
            });
          }
        }
        await this.$router.replace('/registration-success');
      }
    } catch (err) {
      this.handleError(err, 'form.signUp.error.title');
    }
  }

  async mounted() {
    if (this.$store.state.auth?.token) {
      await this.$store.dispatch(actions.SIGN_OUT);
    }
    if (this.$route.query.business) {
      this.isBusiness = true;
    }
    if (this.$route.query.token) {
      this.isInvited = true;
      try {
        this.inviteLoading = true;
        const user = await this.$store.dispatch('business/acceptInvite', this.$route.query.token);
        if (user.status === 'accepted') {
          this.$buefy.dialog.alert({
            title: 'Invitation',
            message: 'Invitation has been accepted.',
            confirmText: 'Okay',
            closeOnConfirm: true,
          });
          await this.$router.replace({
            name: 'Home',
          });
        } else if (user.status === 'registration_required') {
          this.isBusiness = true;
          setTimeout(() => {
            (this.$refs.companyName as any).changeValue(user.business.name);
            (this.$refs.companyRegistrationID as any).changeValue(user.business.registrationId);
            (this.$refs.companyType as any).changeValue(user.business.type);
            (this.$refs.companyAddress1 as any).changeValue(user.business.address1);
            (this.$refs.companyAddress2 as any).changeValue(user.business.address2);
            (this.$refs.companyCity as any).changeValue(user.business.city);
            (this.$refs.companyState as any).changeValue(user.business.state);
            (this.$refs.companyPostcode as any).changeValue(user.business.zip);
            (this.$refs.companyCountry as any).changeValue(user.business.country);
            setTimeout(() => {
              if (user.business.typeDesc) {
                (this.$refs.companyTypeDesc as any).changeValue(user.business.typeDesc);
              }
              this.currentStep = 2;
            }, 200);
          }, 200);
          (this.$refs.workEmail as any).changeValue(user.invite_user.email);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.inviteLoading = false;
      }
    }
  }

  goBack() {
    this.currentStep = 1;
  }
}
