import { ActionTree } from 'vuex';

import { AxiosResponse } from 'axios';
import {
  IdentityResponse,
  CustomError,
  Module,
  IdentityStatusResponse,
  IdentityVerification,
} from '@/types';
import storage from '@/storage';
import { identityVerification as api } from '@/api';
import { RootState, IdentityVerificationState } from '../states';

const getDefaultState = (): IdentityVerificationState => ({
  loading: false,
  data: null,
  error: null,
});

const getters = {};

const actions: ActionTree<IdentityVerificationState, RootState> = {
  async token({ commit }: any): Promise<IdentityResponse | any> {
    return new Promise<IdentityResponse | any>((resolve, reject) => {
      commit('reset');
      commit('setLoading', true);

      api.generateToken()
        .then((resp: AxiosResponse<IdentityResponse>) => {
          const { data } = resp;
          commit('setData', data);
          resolve(data);
        })
        .catch((error: CustomError) => {
          commit('setError', error);
          reject(error);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  async updateStatus({ commit }: any, { applicantId }: any):
    Promise<IdentityStatusResponse | any> {
    return new Promise<IdentityStatusResponse | any>((resolve, reject) => {
      commit('setLoading', true);

      api.updateStatus(applicantId)
        .then((resp: AxiosResponse<IdentityStatusResponse>) => {
          const { data } = resp;
          commit('setData', data);

          storage.user = data.user;

          resolve(data);
        })
        .catch((error: CustomError) => {
          // commit('setError', error); // no need to set error state
          reject(error);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },

  async checkStatus({ commit }: any): Promise<IdentityVerification | any> {
    return new Promise<IdentityVerification | any>((resolve, reject) => {
      commit('setLoading', true);

      api.checkStatus()
        .then((resp: AxiosResponse<IdentityVerification>) => {
          const { data } = resp;
          commit('setData', data);

          const { user } = storage;
          user.onfido = data;
          storage.user = user;

          resolve(data);
        })
        .catch((error: CustomError) => {
          // commit('setError', error); // no need to set error state
          reject(error);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    });
  },
};

const mutations = {
  setLoading(state: IdentityVerificationState, loading: boolean) {
    state.loading = loading;
  },
  setData(state: IdentityVerificationState, data: IdentityResponse) {
    state.data = data;
  },
  setError(state: IdentityVerificationState, error: CustomError) {
    state.error = error;
  },
  reset(state: IdentityVerificationState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): IdentityVerificationState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const identityVerification: Module<IdentityVerificationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
