
import { Component, Prop } from 'vue-property-decorator';
import { Contact } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class ContactQuickActions extends BaseComponent {
  @Prop({ default: () => [] }) contacts?: Contact[];

  get pContacts() {
    return this.contacts;
  }

  handleDelete() {
    this.$buefy.dialog.confirm({
      title: this.$t('screen.contacts.delete.title') as string,
      message: this.$tc('screen.contacts.delete.message', this.contacts?.length) as string,
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('yes') as string,
      onConfirm: (value, dialog) => {
        this.$emit('onDeleteClick', this.contacts);
        dialog.close();
      },
    });
  }
}
