
import { Component } from 'vue-property-decorator';

import {
  CreateContact,
} from '@/types';
import { actions } from '@/store';

import Contact from './Contact';

@Component
export default class CreateContactComponent extends Contact {
  formHandler(payload: CreateContact) {
    this.$store.dispatch(actions.CONTACT_CREATE, payload)
      .then((id: string) => {
        this.$router.replace(`/contacts/${id}`);
      })
      .catch((err) => {
        this.handleError(err, 'form.createContact.error.title');
      });
  }
}
