
import { Component, Prop } from 'vue-property-decorator';
import { Contact } from '@/types';

import BaseComponent from '../base-component';

@Component
export default class ViewContactTopTabBar extends BaseComponent {
  @Prop() contact?: Contact;

  @Prop({ default: 0 }) selectedIndex?: number;

  get pContact() {
    return this.contact;
  }

  get pSelectedIndex() {
    return this.selectedIndex;
  }
}
