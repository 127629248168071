import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

export default class FieldComponent extends Vue {
  @Prop({ default: '' }) label?: string;

  @Prop({ default: '' }) value?: string;

  get pLabel() {
    return this.label;
  }

  get pValue() {
    return this.value;
  }
}
