
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class BusinessAcceptInvitation extends BaseComponent {
  loading = true;

  async mounted() {
    try {
      const users = await this.$store.dispatch('business/acceptInvite', this.$route.params.token);
      if (users.status === 'accepted') {
        this.$buefy.dialog.alert({
          title: 'Invitation',
          message: 'Invitation has been accepted.',
          confirmText: 'Okay',
          closeOnConfirm: true,
        });
        await this.$router.replace({
          name: 'Home',
        });
      } else if (users.status === 'registration_required') {
        await this.$router.replace({
          name: 'SignUp',
          query: {
            token: this.$route.params.token,
          },
        });
      }
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading = false;
    }
  }
}
