
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

import storage from '@/storage';
import { actions } from '@/store';
import { InvitationAcceptResponse, ThirdPartyResponse } from '@/types';

@Component
export default class ThirdPartyAcceptInvitation extends BaseComponent {
  isLoading = true;

  created() {
    this.acceptInvitation();
  }

  async acceptInvitation() {
    try {
      const { token } = this.$route.params;

      const resp: InvitationAcceptResponse = await this
        .$store.dispatch(actions.THIRD_PARTY_ACCEPT_INVITATION, token);

      if (resp.location) {
        const { user } = storage;

        if (user) {
          const resp2 = await this.$store.dispatch('thirdParty/getRequest', token) as ThirdPartyResponse;

          if (resp2.contact.email === user.email) {
            const arr = resp.location.split('/');
            const path = arr[arr.length - 1];
            let name = 'AHDocumentRequestHistory';

            if (path === 'request') {
              name = 'AHDocumentRequest';
            }

            this.$router.replace({ name, params: { token } });

            return;
          }
        }

        setTimeout(() => {
          this.$router.replace(resp.location);
        }, 1000);
      }
    } finally {
      this.isLoading = false;
    }
  }
}
