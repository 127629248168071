
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';

@Component
export default class UsersInvite extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.users',
    },
  ];
}
