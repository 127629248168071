import Vue from 'vue';

const defaultTitle = 'Glide';

const getTitle = (vm: Vue) => {
  const { title } = vm.$options as any;
  let finalTitle;

  if (title) {
    finalTitle = typeof title === 'function'
      ? title.call(vm)
      : title;
  }

  return finalTitle ? `${finalTitle} || ${defaultTitle}` : defaultTitle;
};

export default class TitleMixin extends Vue {
  created() {
    const title = getTitle(this);

    if (title) {
      document.title = title;
    }
  }
}
