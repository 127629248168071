import { AxiosResponse } from 'axios';

import {
  BusinessType,
  BusinessRole,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static getTypes(): Promise<AxiosResponse<BusinessType[]>> {
    return jwtApi.get<BusinessType[]>('/businesses-types');
  }

  static getRoles(): Promise<AxiosResponse<BusinessRole[]>> {
    return jwtApi.get<BusinessRole[]>('/businesses-roles');
  }

  static create(payload: any): Promise<AxiosResponse> {
    return jwtApi.post('/businesses', payload);
  }

  static getUsers(businessId: string): Promise<AxiosResponse> {
    return jwtApi.get(`/businesses/${businessId}/users`);
  }

  static inviteUser(payload: any): Promise<AxiosResponse> {
    return jwtApi.post('/businesses/invite', payload);
  }

  static acceptInvite(token: string): Promise<AxiosResponse> {
    return jwtApi.post(`/businesses/users/${token}/accept`);
  }

  static changeRole(bUserId: string, payload: any): Promise<AxiosResponse> {
    return jwtApi.put(`/businesses/users/${bUserId}`, payload);
  }

  static deleteBusiness(): Promise<AxiosResponse> {
    return jwtApi.post('/businesses/remove');
  }
}
