import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  PaginatedResult,
  Query,
} from '@/types';
import { Contact as api } from '@/api';
import { RootState, SearchContactsState } from '../states';

const getDefaultState = (): SearchContactsState => ({
  data: {
    results: [],
    pagination: {
      page: 1,
      pageSize: 20,
    },
  },
  loading: false,
  error: null,
  workflows: {
    loading: false,
    error: null,
    data: {},
  },
});

const getters = {
  workflowIdsByContacts:
    (state: SearchContactsState) => (id: string) => state.workflows.data[id] || [],
};

const actions: ActionTree<SearchContactsState, RootState> = {
  async search({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query || {});
        const { data } = resp;

        const result = {
          ...data,
          results: data.results.map((contact) => contact.id),
        };

        commit('setLoading', false);
        commit('setContacts', result);
        commit('contactList/setContacts', data.results, { root: true });

        resolve(result);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async workflows({ commit }: any, { id, query }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setWorkflowsLoading', true);
      commit('setWorkflowsError', null);

      try {
        const updateQuery = {
          ...query,
        };

        const resp = await api.workflows(id, updateQuery);
        const { data } = resp;

        const ids = data.map((w: any) => w.id);

        commit('setWorkflowsLoading', false);
        commit('setWorkflows', { id, ids });
        commit('workflows/setWorkflows', data, { root: true });

        resolve(ids);
      } catch (error) {
        commit('setWorkflowsLoading', false);
        commit('setWorkflowsError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: SearchContactsState, loading: boolean) {
    state.loading = loading;
  },
  removeContact(state: SearchContactsState, id: string) {
    state.data = {
      ...state.data,
      results: state.data.results.filter((key) => key !== id),
    };
  },
  setContacts(state: SearchContactsState, data: PaginatedResult<string>) {
    state.data = data;
  },
  setError(state: SearchContactsState, error: CustomError) {
    state.error = error;
  },
  setWorkflowsLoading(state: SearchContactsState, loading: boolean) {
    state.workflows.loading = loading;
  },
  setWorkflowsError(state: SearchContactsState, error: CustomError) {
    state.workflows.error = error;
  },
  setWorkflows(state: SearchContactsState, data: { id: string; ids: string[] }) {
    state.workflows.data[data.id] = data.ids;
    state.workflows.data = { ...state.workflows.data };
  },
  reset(state: SearchContactsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): SearchContactsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const searchContacts: Module<SearchContactsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
