import { render, staticRenderFns } from "./ThirdPartyRequestedByBarSmall.vue?vue&type=template&id=31009623&scoped=true&"
import script from "./ThirdPartyRequestedByBarSmall.vue?vue&type=script&lang=ts&"
export * from "./ThirdPartyRequestedByBarSmall.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31009623",
  null
  
)

export default component.exports