import { AxiosResponse } from 'axios';

import { Session } from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static start(): Promise<AxiosResponse<Session>> {
    return jwtApi.post<Session>('/session/start');
  }

  static update(id: string): Promise<AxiosResponse<void>> {
    return jwtApi.put<void>(`/session/${id}/update`);
  }

  static end(id: string): Promise<AxiosResponse<void>> {
    return jwtApi.put<void>(`/session/${id}/end`);
  }
}
