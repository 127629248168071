
import { Component } from 'vue-property-decorator';
import padStart from 'lodash/padStart';

import { actions } from '@/store';
import {
  AuthResponse, IdentityVerification, TwoFactorAuthVerifyRequest, User,
} from '@/types';
import { identityVerificationStatus } from '@/utils/constants';

import { required, min } from '@/utils/validations';
import BaseComponent from './base-component';

@Component
export default class TwoFactorAuth extends BaseComponent {
  validators = {
    code: [
      required(this.$t('required.field', [this.$t('code')])),
      min(6, this.$t('validations.nCharLong', {
        field: this.$t('code'),
        num: 6,
      })),
    ],
  };

  get mobile() {
    const { mobile, country: { dialCode } } = this.$store.state.auth.identity;

    return `${dialCode}${padStart(mobile.substr(-2), mobile.length, '*')}`;
  }

  mounted() {
    this.sendTextCode(() => this.sendTextCode(() => null));
  }

  sendTextCode(onAction: () => void) {
    this.closeNotification();
    this.$store.dispatch(actions.TWO_FA_SEND_CODE)
      .catch(() => {
        this.notify({
          message: this.$t('form.TwoFactorAuth.error.createFailed') as string,
          type: 'is-danger',
          indefinite: true,
          actionText: this.$t('retry') as string,
          onAction,
        });
      });
  }

  verifyTextCode(code: string) {
    this.closeNotification();

    // return this.$router.replace('/dashboard');

    const payload: TwoFactorAuthVerifyRequest = {
      phoneNumber: this.$store.state.auth?.identity?.mobile,
      code,
      returnJwt: true,
    };

    this.$store.dispatch(actions.TWO_FA_VERIFY_CODE, payload)
      .then((data: AuthResponse): Promise<object> => {
        if (data.jwt) {
          const { auth } = this.$store.state;
          const user: User = auth.identity;

          if (identityVerificationStatus.isVerified(user?.onfido as IdentityVerification)) {
            let returnUrl;
            if (this.$route.query.returnUrl) {
              if (typeof this.$route.query.returnUrl === 'string') {
                returnUrl = this.$route.query.returnUrl;
              } else {
                [returnUrl] = this.$route.query.returnUrl;
              }
            }
            if (returnUrl) {
              return this.$router.replace(atob(returnUrl));
            }
            return this.$router.replace('/dashboard');
          }
          return this.$router.replace('/subscription');
        }

        return this.notify({
          message: this.$t('form.TwoFactorAuth.error.invalidCode') as string,
          type: 'is-danger',
          indefinite: true,
        });
      })
      .catch((err: any) => {
        if (err.from && err.to) {
          // ignore: redirection when halfway so ignore
          return;
        }

        this.notify({
          message: this.$t('form.TwoFactorAuth.error.invalidCode') as string,
          type: 'is-danger',
          indefinite: true,
        });
      });
  }

  submit(values: any) {
    this.verifyTextCode(values.code);
  }

  resendCode() {
    this.sendTextCode(() => this.sendTextCode(() => null));
  }
}
