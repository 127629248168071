import { Prop } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';

export default class Layout extends BaseComponent {
  @Prop({ default: '' }) title?: string;

  @Prop({ default: false }) isFullWidth?: boolean;

  @Prop({ default: () => [] }) breadcrumbs?: BreadcrumbItem[];

  @Prop({ default: true }) hasTitleBar?: boolean;

  @Prop({ default: true }) hasTopTabBar?: boolean;

  @Prop({ default: true }) hasQuickActionBar?: boolean;

  @Prop({ default: true }) hasGlobalMenu?: boolean;

  @Prop({ default: false }) hasPagination?: boolean;

  get pIsFullWidth() {
    return this.isFullWidth;
  }

  get pTitle() {
    return this.title;
  }

  get pBreadcrumbs() {
    return this.breadcrumbs;
  }

  get pHasTitleBar() {
    return this.hasTitleBar;
  }

  get pHasTopTabBar() {
    return this.hasTopTabBar;
  }

  get pHasQuickActionBar() {
    return this.hasQuickActionBar;
  }

  get pHasGlobalMenu() {
    return this.hasGlobalMenu;
  }

  get pHasPagination() {
    return this.hasPagination;
  }

  navigateBack() {
    if (window.history.length > 2) {
      this.$router.back();
    } else {
      this.$router.push('/dashboard');
    }
  }
}
