import { salt } from '@/utils/constants';
import { crypto } from '@/crypto';

export default class {
  private storage: Storage;

  private crypto: any;

  constructor(storage: Storage) {
    this.storage = storage;
    this.crypto = crypto(salt.STORAGE as string);
  }

  setObject(key: string, value: any) {
    this.set(key, JSON.stringify(value));
  }

  getObject(key: string) {
    try {
      const value = this.get(key);

      if (value) {
        return JSON.parse(value);
      }
    } catch (error) {
      // ignore error
    }
    return null;
  }

  set(key: string, value: string) {
    try {
      const cypherText = this.crypto.encrypt(value);
      this.storage.setItem(key, cypherText);
    } catch (error) {
      // ignore error
    }
  }

  get(key: string): string | null | undefined {
    try {
      const cypherText = this.storage.getItem(key);
      const value = this.crypto.decrypt(cypherText);

      return value;
    } catch (e) {
      // ignore error
    }

    return null;
  }

  remove(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
