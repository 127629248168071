var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: 'CreateContact' }}},[_c('b-icon',{attrs:{"icon":"account-supervisor"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('createContact')))])],1)],1),(_vm.pContacts.length)?_c('li',[_c('router-link',{attrs:{"to":{
        name: 'WorkflowInvite',
        query: {
          contacts: _vm.pContacts
            .map((contact) => contact.id),
        },
      }}},[_c('b-icon',{attrs:{"icon":"call-merge"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('inviteToWorkflow')))])],1)],1):_vm._e(),(_vm.pContacts.length)?_c('li',[_c('router-link',{attrs:{"to":{
        name: 'CreateTask',
        query: {
          contacts: _vm.pContacts
            .map((contact) => contact.id),
        },
      }}},[_c('b-icon',{attrs:{"icon":"clipboard-clock-outline"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('createTask')))])],1)],1):_vm._e(),(_vm.pContacts.length === 1)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'EditContact', params: { id: _vm.pContacts[0].id } }}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('edit')))])],1)],1):_vm._e(),(_vm.pContacts.length)?_c('li',[_c('a',{on:{"click":_vm.handleDelete}},[_c('b-icon',{attrs:{"icon":"delete has-text-primary"}}),_c('span',{staticClass:"text has-text-primary"},[_vm._v(_vm._s(_vm.$t('delete')))])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }