import { ActionTree } from 'vuex';

import {
  Company,
  CustomError,
  Module,
} from '@/types';
import { Company as api } from '@/api';
import { RootState, CompanyListState } from '../states';
import { Callback, callback } from './utils';

const getDefaultState = (): CompanyListState => ({
  all: [],
  loading: false,
  error: null,
});

const getters = {};

let listPromises: Callback[] = [];

const actions: ActionTree<CompanyListState, RootState> = {
  async getAll({ commit, state }: any) {
    if (state.loading) {
      const cb = callback();

      listPromises.push(cb);

      return cb.promise;
    }

    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Company[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.getAll();
        const { data } = resp;

        commit('setLoading', false);
        commit('setCompanies', data.results);

        resolve(data.results);

        listPromises.forEach((cb) => {
          cb.resolve(data.results);
        });
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);

        listPromises.forEach((cb) => {
          cb.reject(error);
        });
      }

      listPromises = [];
    });
  },
};

const mutations = {
  setLoading(state: CompanyListState, loading: boolean) {
    state.loading = loading;
  },
  setCompanies(state: CompanyListState, companies: Company[]) {
    state.all = companies;
  },
  setError(state: CompanyListState, error: CustomError) {
    state.error = error;
  },
  reset(state: CompanyListState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): CompanyListState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const companyList: Module<CompanyListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
