import { InternalAxiosRequestConfig } from 'axios';

import storage from '@/storage';

const getHeaders = () => {
  const { selectedBusiness } = storage;

  if (selectedBusiness) {
    return {
      business: selectedBusiness,
    };
  }

  return {};
};

export default (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const c = config;
  const headers: any = {};
  Object.assign(headers, getHeaders(), config.headers);
  c.headers = headers;

  return c;
};
