
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Avatar extends Vue {
  @Prop({ default: '' }) name?: string;

  private get pName() {
    return this.name;
  }
}
