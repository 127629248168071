
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class UsersQuickActions extends BaseComponent {
  @Prop({ default: () => [] }) selected?: string[];

  get pSelected() {
    return this.selected;
  }

  handleDelete() {
    this.$emit('delete');
  }
}
