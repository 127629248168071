
import { Component, Prop } from 'vue-property-decorator';

import { DocumentType } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class DocumentTypeList extends BaseComponent {
  // @Prop({ default: true }) error: boolean;

  @Prop() selected?: string | undefined;

  @Prop() category?: string | undefined;

  get pSelected(): string | undefined {
    return this.selected;
  }

  set pSelected(selected: string | undefined) {
    this.selected = selected;
  }

  get documentTypes() {
    const documentTypes: DocumentType[] = this.$store.getters['documentTypes/list'];

    return documentTypes.filter((documentType) => documentType.categories.includes(this.category || ''));
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.$store.dispatch('documentTypes/list');
  }

  onChange(value: string) {
    this.$emit('change', value);
  }
}
