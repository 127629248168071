import Crypto, { AES } from 'crypto-js';

// eslint-disable-next-line import/prefer-default-export
export const crypto = (passPhrase: string) => ({
  encrypt(message: string) {
    return AES.encrypt(message, passPhrase).toString();
  },

  decrypt(message: string) {
    return AES.decrypt(message, passPhrase).toString(Crypto.enc.Utf8);
  },
});
