
import { Component } from 'vue-property-decorator';
import Workflow from './Workflow';
import ViewWorkflowTaskList from './ViewWorkflowTaskList.vue';
import ViewWorkflowAssignedTaskList from './ViewWorkflowAssignedTaskList.vue';

@Component({
  components: {
    ViewWorkflowTaskList,
    ViewWorkflowAssignedTaskList,
  },
})
export default class ViewWorkflowTasks extends Workflow {
  activeTab = 0;
}
