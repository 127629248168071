import { AxiosResponse } from 'axios';

import {
  Tag,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static getAll(): Promise<AxiosResponse<Tag[]>> {
    return jwtApi.get<Tag[]>('/tags');
  }
}
