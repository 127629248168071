var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"c-workflows__lists__item",class:{
      'c-workflows__lists__item--success': _vm.pWorkflow.completed > 0
        && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
      'c-workflows__lists__item--primary': _vm.showDueDateWarning(_vm.pWorkflow)
        && _vm.pWorkflow.total > 0,
      'c-workflows__lists__item--inactive': !_vm.pWorkflow.active,
    }},[_c('div',{staticClass:"c-workflows__lists__item__title"},[_c('a',{staticClass:"is-flex is-flex-grow-1",on:{"click":_vm.handleWorkflowClick}},[_c('h4',[_vm._v(_vm._s(_vm.pWorkflow.name))])]),_c('div',{staticClass:"right"},[(_vm.pWorkflow.dueTaskCount)?_c('b-tag',{attrs:{"type":"flag-count is-primary is-inverted"}},[_c('img',{attrs:{"src":require("../../assets/flag2.svg"),"width":"18","alt":""}}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(' ')+_vm._s(_vm.pWorkflow.dueTaskCount)+" ")])]):_vm._e(),(_vm.pHasActions)?_vm._t("actions",function(){return [_c('WorkflowActions',{attrs:{"workflow":_vm.pWorkflow},on:{"onActionClick":_vm.onActionClick}})]}):_vm._e()],2)]),_c('a',{on:{"click":_vm.handleWorkflowClick}},[_c('div',{staticClass:"c-workflows__lists__item__content"},[(!_vm.isThirdParty)?_c('b-progress',{attrs:{"type":{
            'is-success': _vm.pWorkflow.completed > 0
              && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
            'is-primary': _vm.showDueDateWarning(_vm.pWorkflow) && _vm.pWorkflow.total > 0,
            'is-default': _vm.pWorkflow.completed === 0,
          },"size":"is-medium","max":100,"value":_vm.calculatePercentage(_vm.pWorkflow),"show-value":false}}):_vm._e(),_c('div',{staticClass:"progress-data"},[_c('div',{staticClass:"progress-data__left"},[(!_vm.isThirdParty)?_c('p',{staticClass:"sub-text",class:{
                'has-text-success': _vm.pWorkflow.completed > 0
                  && (_vm.pWorkflow.total === _vm.pWorkflow.completed || !_vm.showDueDateWarning(_vm.pWorkflow)),
                'has-text-primary': _vm.showDueDateWarning(_vm.pWorkflow) && _vm.pWorkflow.total > 0,
                'sub-text': _vm.pWorkflow.completed === 0,
              }},[_vm._v(" "+_vm._s(_vm.calculatePercentage(_vm.pWorkflow))+"% completed "),(_vm.pWorkflow.dueDate && _vm.pWorkflow.status !== 'completed')?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatRelative")(_vm.pWorkflow.dueDate,{ startWithCaps: true }))+" ")]):_vm._e()]):_vm._e(),(!_vm.pWorkflow.active)?_c('p',{staticClass:"mt-2"},[_vm._v(" Upgrade your plan to enable more than two workflows. ")]):_vm._e()]),_c('div',{staticClass:"progress-data__right"},[_c('Avatar',{attrs:{"name":_vm._f("full-name")(_vm.owner)}})],1)])],1)])]),_c('b-modal',{attrs:{"canCancel":false},model:{value:(_vm.upgradeModal),callback:function ($$v) {_vm.upgradeModal=$$v},expression:"upgradeModal"}},[_c('div',{staticClass:"modal-card",staticStyle:{"margin":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" Upgrade your plan ")])]),_c('section',{staticClass:"modal-card-body"},[_c('p',{staticClass:"sub-text is-size-6 mt-4"},[_vm._v(" Starter account is limited to two active workflows. If you want to create more workflows, you can upgrade to Individual account. ")])]),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"type":"button is-primary is-medium is-fullwidth"},on:{"click":_vm.onUpgradeConfirm}},[_vm._v(" Upgrade ")]),_c('b-button',{attrs:{"type":"button is-medium is-fullwidth"},on:{"click":_vm.onUpgradeCancel}},[_vm._v(" Cancel ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }