
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import {
  BusinessUser,
  SubscriptionCreateSessionResponse,
} from '@/types';

@Component
export default class Settings extends BaseComponent {
  deleteModal = false;

  deleteConfirmation = '';

  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.settings',
      },
    ];
  }

  async handleSubscriptionClick(e: Event) {
    e.preventDefault();

    const ref = this.$buefy.loading.open({
      isFullPage: true,
    });

    try {
      const data: SubscriptionCreateSessionResponse = await this.$store
        .dispatch(actions.PAYMENT_GATEWAY_CREATE_BILLING_SESSION, this.selectedBusiness);

      window.location.href = data.url;
    } catch (error) {
      this.handleError(error, 'form.subscription.error.title');
    }

    ref.close();
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  get bUsers() {
    return this.$store.getters['business/getUsers'];
  }

  get isSubscribedBasic() {
    const user = this.$store.getters['auth/identity'];

    const selectedBusiness = this.$store.getters['business/getSelectedBusiness'];
    if (selectedBusiness) {
      return false;
    }

    return user.subscription?.status === 'paid' && user.subscription?.nickname === 'basic';
  }

  async mounted() {
    const ref = this.$buefy.loading.open({
      isFullPage: true,
    });
    try {
      await this.$store.dispatch('business/getUsers');
    } catch (err) {
      this.handleError(err as Error);
    } finally {
      ref.close();
    }
  }

  get isAdmin() {
    if (!this.selectedBusiness) {
      return true;
    }

    const me = this.bUsers.find(
      (user: BusinessUser) => user.status === 'accepted'
        && this.$store.state.auth.identity.id === user.user.id,
    );

    if (!me) {
      return false;
    }

    return me.business_role.name === 'Administrator';
  }

  async onClickDelete() {
    const ref = this.$buefy.loading.open({
      isFullPage: true,
    });
    try {
      if (this.selectedBusiness) {
        await this.$store.dispatch('business/deleteBusiness');
      } else {
        await this.$store.dispatch('users/deleteUser');
      }
      await this.$store.dispatch(actions.SIGN_OUT);
      await this.$router.replace({ name: 'Home' });
    } catch (err) {
      this.handleError(err as Error);
    } finally {
      ref.close();
    }
    // /users/remove (POST)
    // /businesses/remove (POST)
  }
}
