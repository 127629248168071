
import { Component, Prop, Watch } from 'vue-property-decorator';
import { File, Tag, Pagination } from '@/types';
import { actions } from '@/store';
import { fileUtils } from '@/utils';
import FileBrowser from './file-browser';

@Component
export default class SearchFileBrowser extends FileBrowser {
  @Prop({ default: 'search' }) mode?: string;

  @Prop({ default: '' }) selectedTag?: string;

  pagination: Pagination = {
    page: 1,
    pageSize: 10,
    pageCount: 1,
    total: 0,
  };

  get filesToShow() {
    return this.files;
  }

  get pSelectedTag() {
    return this.selectedTag;
  }

  get selectedTagName() {
    if (this.pSelectedTag) {
      return this.$store.state.tags.all.find((tag: Tag) => tag.id === this.pSelectedTag).name;
    }
    return '';
  }

  showFileActions(file: File): boolean {
    return !(this.isWorkflowFolder(file) || this.isFileLocked(file));
  }

  contextAction(id: string, action: string) {
    const modalRef = this.$refs.fileManagerModal as any;
    if (action === 'upload') {
      modalRef.onClickUpload(this.$store.state.files.data[id].pathLower);
    } else if (action === 'rename') {
      modalRef.onClickRename([id]);
    } else if (action === 'manage-tags') {
      this.$router.push({
        name: 'FileManagerTags',
        query: {
          path: fileUtils.parseFilePath(this.$store.state.files.data[id]),
        },
      });
    } else if (action === 'delete') {
      const forDeleteNames = [this.$store.state.files.data[id].name];
      this.$buefy.dialog.confirm({
        title: 'Delete?',
        message: `Are you sure you want to delete these files/folders?
                <br><b>${forDeleteNames.join('</b><br><b>')}</b>`,
        onConfirm: () => this.doDelete(id),
      });
    }
  }

  doDelete(id: string) {
    const entries = [{ path: this.$store.state.files.data[id].pathLower }];
    this.$store.dispatch(
      actions.FILE_MANAGER_DELETE,
      { payload: { entries }, ids: [id] },
    ).then(() => {
      this.notify({
        type: 'is-success',
        message: 'File deleted successfully.',
      });
      if (this.pagination.total) {
        this.pagination.total -= 1;
      }
    }).catch((err) => {
      this.handleError(err, 'error.generic');
    });
  }

  changePath(file: File) {
    this.$router.push({ name: 'FileManagerDocuments', query: { path: file.pathLower } });
  }

  @Watch('pSelectedTag')
  onTagChange() {
    this.pagination = {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      total: 0,
    };
    this.fetchFiles();
  }

  @Watch('pagination.page')
  fetchFiles() {
    if (this.pSelectedTag) {
      this.$store.dispatch('fileManager/getFilesByTagId', {
        id: this.pSelectedTag,
        query: this.pagination,
      })
        .then((res) => {
          this.pagination = res.pagination;
        });
    }
  }

  onPageChange(page: number) {
    this.pagination.page = page;
  }

  mounted() {
    if (this.mode === 'search') {
      this.setFiles();
    }
  }
}
