
import { Component, Emit, Prop } from 'vue-property-decorator';
import { actions } from '@/store';
import { Contact, defaultQuery } from '@/types';

import BaseComponent from './base-component';

@Component
export default class ContactsListDropdown extends BaseComponent {
  @Prop({
    default: () => ({
      id: '',
      firstName: 'All',
      lastName: 'Contacts',
    }),
  })
    selected?: Contact;

  private query = {
    ...defaultQuery,
    pageSize: 100,
    filters: {
      _sort: 'firstNameLower:ASC,lastNameLower:ASC',
    },
  };

  get pSelected() {
    return this.selected;
  }

  get computed() {
    return {
      loading: this.$store.state.contact.loading,
    };
  }

  @Emit('change')
  change(contact: Contact) {
    if (contact.id !== this.selected?.id) {
      this.selected = contact;
    }
  }

  mounted() {
    return this.$store.dispatch(
      actions.CONTACT_GET_ALL,
      this.query,
    );
  }
}
