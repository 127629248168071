import Vue from 'vue';

import FileBrowser from './FileBrowser.vue';
import FileManagerTopTabBar from './FileManagerTopTabBar.vue';
import FileManagerActions from './FileManagerActions.vue';
import FileManagerModal from './FileManagerModal.vue';
import FileManagerTags from './FileManagerTags.vue';
import SearchFileBrowser from './SearchFileBrowser.vue';

Vue.component('FileBrowser', FileBrowser);
Vue.component('FileManagerTopTabBar', FileManagerTopTabBar);
Vue.component('FileManagerActions', FileManagerActions);
Vue.component('FileManagerModal', FileManagerModal);
Vue.component('FileManagerTags', FileManagerTags);
Vue.component('SearchFileBrowser', SearchFileBrowser);
