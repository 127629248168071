
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import layouts from '@/layouts';
import { Document } from '@/types';

@Component
export default class ThirdPartyDocumentRequest extends BaseComponent {
  @Prop({ default: true }) globalCheck?: boolean;

  @Prop({ default: false }) showModal?: boolean;

  @Prop({ default: () => ({}) }) modalItem?: Document;

  get documents(): Document[] {
    return this.$store.state.thirdParty.newDocument.data?.documents || [];
  }

  get requestedBy() {
    return this.$store.state.thirdParty.newDocument.data?.contact || {};
  }

  get assignedTo() {
    return this.$store.state.thirdParty.newDocument.data?.created_by || {};
  }

  get workflowName() {
    return this.$store.state.thirdParty.newDocument.data?.workflow?.name || '';
  }

  get loading() {
    return this.$store.state.thirdParty.newDocument.loading;
  }

  get saving() {
    return this.$store.state.thirdParty.newDocument.saving;
  }

  created() {
    this.loadData();
  }

  async loadData() {
    if (!this.$store.state.thirdParty.newDocument.isDataFetched) {
      const { token } = this.$route.params;

      if (token) {
        await this.$store.dispatch('thirdParty/getNewDocument', token);

        this.$emit('onDataLoaded', this.$store.state.thirdParty.newDocument.data);
      }
    } else {
      this.$emit('onDataLoaded', this.$store.state.thirdParty.newDocument.data);
    }
  }

  handleAddRow() {
    this.$store.commit('thirdParty/addPartialNewDocument');
  }

  validate() {
    if (!this.documents.some((doc) => doc.selected)) {
      this.handleError({
        detail: {
          code: 'form.thirdParty.document.request.payload.empty.message',
        },
      }, 'form.thirdParty.document.request.error.title');
      return false;
    }

    let valid = true;

    const otherCategoryId = this.$store.getters['categories/otherId'];

    this.documents.forEach((doc) => {
      if (doc.documentName && doc.documentName.length < 2) {
        this.notify({
          message: 'Document name needs to be atleast 2 characters.',
          type: 'is-danger',
        });
        valid = false;
      }
      if (doc.selected) {
        if (!this.validateDocument(doc, otherCategoryId)) {
          valid = false;
        }
      }
    });

    return valid;
  }

  validateDocument(doc: Document, otherCategoryId: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    let valid = true;

    if (!doc.category) {
      valid = false;
      errors.category = 'Document Category is required';
    }

    if (doc.category === otherCategoryId && !doc.documentName) {
      valid = false;
      errors.documentName = 'Document Name is required';
    }

    if (doc.category !== otherCategoryId && !doc.document) {
      valid = false;
      errors.document = 'Document Type is required';
    }

    if (doc.hasDueDate && !doc.dueDate) {
      valid = false;
      errors.dueDate = 'Due Date is required';
    }

    this.$store.commit('thirdParty/mutateNewDocumentObject', {
      ...doc,
      errors,
      showError: true,
    });

    return valid;
  }

  async handleSubmit() {
    let loadingComponent;
    try {
      if (!this.validate()) {
        return;
      }

      const { token } = this.$route.params;

      loadingComponent = this.$buefy.loading.open({
        isFullPage: true,
        canCancel: false,
      });

      await this.$store.dispatch('thirdParty/submitNewDocumentRequest', token);

      this.notify({
        type: 'is-success',
        message: this.$t('form.thirdParty.document.request.success.message') as string,
      });

      if (loadingComponent) {
        loadingComponent.close();
      }

      if (this.$route.meta?.layout === layouts.DashboardLayout) {
        this.$router.replace({ name: 'AHDocumentRequestHistory', params: { token } });

        return;
      }

      this.$router.replace({
        name: 'ThirdPartyDocumentRequestHistory',
        params: {
          token,
        },
        query: {
          assignedTo: 'user',
        },
      });
    } catch (error) {
      if (loadingComponent) {
        loadingComponent.close();
      }

      this.handleError(error, 'form.thirdParty.document.request.error.title');
    }
  }

  handleShowModal(document: Document) {
    const { action } = document;

    this.modalItem = {
      ...document,
      action: action || '',
      files: document.files || [],
    };

    this.showModal = true;
  }

  handleCloseModal() {
    this.showModal = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.modalItem = {} as any;
  }

  onActionChange(action: string) {
    this.modalItem = {
      ...this.modalItem,
      action,
    } as Document;
  }

  checkChanged({ id, selected }: { id: string; selected: boolean }) {
    this.$store.commit('thirdParty/setNewDocumentSelected', { id, selected });

    const selectedCount = this.documents.filter((doc) => doc.selected).length;

    this.globalCheck = this.documents.length === selectedCount;
  }

  categoryChanged({ id, category }: { id: string; category: string }) {
    this.$store.commit('thirdParty/setNewDocumentCategory', { id, category });
  }

  documentTypeChanged({ id, document }: { id: string; document: string }) {
    this.$store.commit('thirdParty/setNewDocumentDocument', { id, document });
  }

  toggleGlobalCheck(checked: boolean) {
    this.globalCheck = checked;
    this.$store.commit('thirdParty/checkNewDocumentAll', checked);
  }

  handleModalSave() {
    if (this.modalItem && this.modalItem.action) {
      const action = this.$store.getters['actions/getById'](this.modalItem.action);
      const actionsRequiredFile = ['download_document', 'comment_document', 'esign_document'];
      if (action && actionsRequiredFile.includes(action.code) && !this.modalItem.files?.length) {
        this.notify({
          message: 'Please upload atleast one file',
          type: 'is-danger',
        });
        return;
      }
    }
    this.$store.commit('thirdParty/mutateNewDocumentObject', this.modalItem);
    this.showModal = false;
  }

  handleNewRequest() {
    if (this.$route.meta?.layout === layouts.DashboardLayout) {
      this.$router.push({ name: 'AHDocumentRequestHistory' });
    } else {
      this.$router.push({ name: 'ThirdPartyDocumentRequestHistory' });
    }
  }
}
