import { ActionTree } from 'vuex';

import {
  AuthResponse,
  BusinessType,
  BusinessRole,
  CustomError,
  Module,
  BusinessUser,
} from '@/types';
import { Business as api } from '@/api';
import storage from '@/storage';
import { RootState, BusinessState } from '../states';

const storeAuth = (data: AuthResponse) => {
  try {
    if (data.jwt) {
      storage.token = data.jwt;
    }

    if (data.user) {
      storage.user = data.user;
    }
  } catch (e) {
    // ignore error
  }
};

const getDefaultState = (): BusinessState => ({
  types: [],
  roles: [],
  users: [],
  selectedBusiness: storage.selectedBusiness,
  loading: false,
  error: null,
});

const getters = {
  isTypesFetched(state: BusinessState) {
    return state.types.length > 0;
  },
  getTypes(state: BusinessState) {
    return state.types;
  },
  getRoles(state: BusinessState) {
    return state.roles;
  },
  getUsers(state: BusinessState) {
    return state.users;
  },
  getSelectedBusiness(state: BusinessState) {
    return state.selectedBusiness;
  },
};

const actions: ActionTree<BusinessState, RootState> = {
  async getTypes({ commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<BusinessType[]>(async (resolve, reject) => {
      try {
        const resp = await api.getTypes();
        const { data } = resp;
        commit('setTypes', data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getRoles({ commit }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<BusinessRole[]>(async (resolve, reject) => {
      try {
        const resp = await api.getRoles();
        const { data } = resp;
        commit('setRoles', data);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async registerBusiness({ commit }, payload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.create(payload);
        const { data } = resp;

        commit('setLoading', false);

        storeAuth(data);
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async getBusinessUsers(ctx, businessId) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.getUsers(businessId);
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getUsers({ commit, state }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<[] | void>(async (resolve, reject) => {
      if (state.selectedBusiness) {
        try {
          const resp = await api.getUsers(state.selectedBusiness);
          const { data } = resp;
          commit('setUsers', data);
          resolve(data);
        } catch (error) {
          reject(error);
        }
      } else {
        resolve();
      }
    });
  },
  async inviteUser(ctx, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.inviteUser({
          ...payload,
          business: ctx.state.selectedBusiness,
        });
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async acceptInvite(ctx, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.acceptInvite(token);
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async changeRole({ state }, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.changeRole(payload.userId, {
          business: state.selectedBusiness,
          role: payload.role,
        });
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async revokeUser({ state }, userId: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.changeRole(userId, {
          business: state.selectedBusiness,
          status: 'revoked',
        });
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async deleteBusiness() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.deleteBusiness();
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: BusinessState, loading: boolean) {
    state.loading = loading;
  },
  setTypes(state: BusinessState, types: BusinessType[]) {
    state.types = types;
  },
  setRoles(state: BusinessState, roles: BusinessRole[]) {
    state.roles = roles;
  },
  setUsers(state: BusinessState, users: BusinessUser[]) {
    state.users = users;
  },
  setError(state: BusinessState, error: CustomError) {
    state.error = error;
  },
  setSelectedBusiness(state: BusinessState, businessId: string) {
    state.selectedBusiness = businessId;
    storage.selectedBusiness = businessId;
  },
  reset(state: BusinessState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): BusinessState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const business: Module<BusinessState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
