
import { Component } from 'vue-property-decorator';
import ms from 'ms';
import BaseComponent from '@/components/base-component';

import storage from '@/storage';
import { actions } from '@/store';

const expiryThreshold = ms(process.env.VUE_APP_IDLE_TIME_BEFORE_SESSION_EXPIRY || '30m');

@Component({
})
export default class OnboardingLayout extends BaseComponent {
  userFetchHandler: any = 0;

  sessionExpiryHandler: any = 0;

  created() {
    this.checkSessionExpiry();
    this.scheduleSessionExpire();
    this.registerFetchUser();
  }

  async registerFetchUser() {
    clearInterval(this.userFetchHandler);

    await this.fetchUser();

    this.userFetchHandler = setInterval(() => {
      this.fetchUser();
    }, ms('10m'));
  }

  async fetchUser() {
    await this.$store.dispatch(actions.USER_ME);
  }

  scheduleSessionExpire() {
    this.sessionExpiryHandler = setInterval(() => {
      this.expireSession();
    }, expiryThreshold);
  }

  checkSessionExpiry() {
    let { lastActiveAt } = storage;

    if (!lastActiveAt) {
      lastActiveAt = Date.now();
      storage.lastActiveAt = lastActiveAt;
    }

    if (Date.now() > lastActiveAt + expiryThreshold) {
      this.expireSession();
    }
  }

  async expireSession() {
    const { user } = storage;
    if (user) {
      await this.$store.dispatch(actions.SIGN_OUT);
      await this.$router.replace({ name: 'Home' });
    }
  }

  beforeDestroy() {
    clearInterval(this.sessionExpiryHandler);
    clearInterval(this.userFetchHandler);
  }
}
