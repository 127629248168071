import { AxiosResponse } from 'axios';

import {
  Workflow,
  Query,
  PaginatedResult,
  WorkflowContact,
  ContactInviteRequest,
  CreateWorkflow,
  Document,
  DocumentCommentPayload,
  DocumentStatusPayload,
  UpdateDocumentPayload,
  DocumentAssignFilesPayload,
  ESignRequestPayload,
  ESign,
  DocumentRequestPayload,
} from '@/types';

import {
  jwtApi,
} from './api_base';

import { buildQuery, buildSearchQuery } from './utils';

export default class {
  static search(query: Query): Promise<AxiosResponse<Workflow[]>> {
    return jwtApi.get<Workflow[]>(`/workflows${buildQuery(query)}`);
  }

  static requests(query: Query): Promise<AxiosResponse<PaginatedResult<Workflow>>> {
    return jwtApi.get<PaginatedResult<Workflow>>(`/workflows/requests${buildSearchQuery(query)}`);
  }

  static getById(id: string): Promise<AxiosResponse<Workflow>> {
    return jwtApi.get<Workflow>(`/workflows/${id}`);
  }

  static create(payload: CreateWorkflow): Promise<AxiosResponse<Workflow>> {
    return jwtApi.post<Workflow>('/workflows', payload);
  }

  static update(id: string, name: string): Promise<AxiosResponse<Workflow>> {
    return jwtApi.put<Workflow>(`/workflows/${id}`, { name });
  }

  static delete(id: string): Promise<AxiosResponse<Workflow>> {
    return jwtApi.delete<Workflow>(`/workflows/${id}`);
  }

  static initialDocuments(id: string): Promise<AxiosResponse<Document[]>> {
    return jwtApi.get<Document[]>(`/workflows/${id}/initial-documents`);
  }

  static contacts(id: string, query: Query):
  Promise<AxiosResponse<PaginatedResult<WorkflowContact>>> {
    const filters = buildSearchQuery(query);
    return jwtApi.get<PaginatedResult<WorkflowContact>>(`/workflows/${id}/contacts${filters}${filters.length ? '&' : '?'}populate_contact=1`);
  }

  static documents(id: string, query: Query): Promise<AxiosResponse<WorkflowContact[]>> {
    const filters = buildQuery(query);
    return jwtApi.get<WorkflowContact[]>(`/workflows/${id}/documents${filters}`);
  }

  static documentDetail(id: string, documentId: string): Promise<AxiosResponse<Document>> {
    return jwtApi.get<Document>(`/workflows/${id}/documents/${documentId}`);
  }

  static comment(payload: DocumentCommentPayload): Promise<AxiosResponse<void>> {
    const { id, documentId, comment } = payload;
    return jwtApi.post<void>(`/workflows/${id}/documents/${documentId}/comment`, { comment });
  }

  static changeDocumentStatus(payload: DocumentStatusPayload): Promise<AxiosResponse<void>> {
    const { id, documentId, status } = payload;
    return jwtApi.post<void>(`/workflows/${id}/documents/${documentId}/status`, { status });
  }

  static assignFiles(payload: DocumentAssignFilesPayload): Promise<AxiosResponse<void>> {
    const { id, documentId, paths } = payload;
    return jwtApi.post<void>(`/workflows/${id}/documents/${documentId}/files`, { paths });
  }

  static updateDocument(payload: UpdateDocumentPayload): Promise<AxiosResponse<void>> {
    const {
      id,
      workflow,
      ...data
    } = payload;

    return jwtApi.put<void>(`/workflows/${workflow}/documents/${id}`, data);
  }

  static sendDocuments(id: string): Promise<AxiosResponse<void>> {
    return jwtApi.post<void>(`/workflows/${id}/send-documents`);
  }

  static invite(payload: ContactInviteRequest): Promise<AxiosResponse<WorkflowContact>> {
    return jwtApi.post<WorkflowContact>('/workflows/invite', payload);
  }

  static sendLink(payload: ContactInviteRequest): Promise<AxiosResponse<WorkflowContact>> {
    return jwtApi.post<WorkflowContact>('/workflows/send/link', payload);
  }

  static newDocumentsRequest(payload: DocumentRequestPayload): Promise<AxiosResponse<void>> {
    const { workflow, ...data } = payload;

    return jwtApi.put<void>(`/workflows/${workflow}/requests/new`, data);
  }

  static getSignUrl(payload: ESignRequestPayload): Promise<AxiosResponse<ESign>> {
    const { id, documentId } = payload;

    return jwtApi.get<ESign>(`/workflows/${id}/documents/${documentId}/esign`);
  }

  static signUrl(payload: ESignRequestPayload): Promise<AxiosResponse<ESign>> {
    const { id } = payload;

    return jwtApi.post<ESign>(`/workflows/files/${id}/esign`);
  }
}
