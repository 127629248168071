import { AxiosResponse } from 'axios';

import {
  Category,
} from '@/types';

import {
  api,
} from './api_base';

export default class {
  static list(): Promise<AxiosResponse<Category []>> {
    return api.get<Category []>('/categories');
  }
}
