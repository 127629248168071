
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Pagination } from '@/types';
import Layout from '@/layouts/Layout';

@Component
export default class ListViewLayout extends Layout {
  @Prop({ default: () => ({}) }) pagination?: Pagination;

  get pPagination() {
    return this.pagination;
  }

  @Emit('onPageChange')
  onPageChange() {
    // ignore
  }
}
