
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { File, FileAttribute } from '@/types';
import { fileUtils } from '@/utils';

@Component
export default class FileManagerActions extends BaseComponent {
  @Prop({ default: () => [] }) checkedIds?: string[];

  get showActions(): boolean {
    if (this.$route.name === 'FileManagerRecent') {
      return false;
    }
    if (this.$route.query.path && this.$store.state.fileManager.currentDir) {
      return !this.isWorkflow(this.$store.state.fileManager.currentDir);
    }
    return true;
  }

  get isFile() {
    if (this.checkedIds && this.checkedIds.length === 1
      && this.$store.state.files.data[this.checkedIds[0]]) {
      return fileUtils.isFile(this.$store.state.files.data[this.checkedIds[0]]);
    }
    return false;
  }

  isWorkflow = (file: File) => file && !!file.attributes.find(
    (attributes: FileAttribute) => attributes.name === 'type' && ['workflow', 'link'].includes(attributes.value),
  );

  get showFileActions(): boolean {
    if (this.checkedIds && this.checkedIds.length) {
      return !this.checkedIds.some((id) => this.isWorkflow(this.$store.state.files.data[id])
          || this.isFileLocked(this.$store.state.files.data[id]));
    }
    return true;
  }

  isFileLocked = (file: File) => {
    if (file && file.lockInfo) {
      return file.lockInfo.locked;
    }
    return false;
  };

  performAction(action: string) {
    this.$emit('action', action);
  }
}
