
import omit from 'lodash/omit';
import { Component, Prop } from 'vue-property-decorator';

import { sortByDateDesc } from '@/utils/functions';

import {
  Workflow,
  defaultQuery,
  SortItem,
} from '@/types';
import { actions } from '@/store';

import WorkflowComponent from './Workflow';

const sortItems: SortItem[] = [
  {
    key: 'createdAt',
    label: 'Created date',
    icon: 'calendar',
  },
  {
    key: 'dueDate',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'name-asc',
    label: 'Name asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'name-desc',
    label: 'Name desc',
    icon: 'sort-alphabetical-descending',
  },
];

const queryWithFilters = omit(defaultQuery, 'pageSize');

@Component
export default class WorkflowsCompleted extends WorkflowComponent {
  private query = queryWithFilters;

  dataFetched = false;

  @Prop({ default: () => [] }) selected?: Workflow[];

  @Prop({ default: false }) allChecked?: boolean;

  sortItems = sortItems;

  selectedSort = sortItems[0];

  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.workflows',
      },
    ];
  }

  get loading() {
    return this.$store.state.workflows.loading;
  }

  get pWorkflows() {
    const workflowsList = this.$store.getters['workflowSearch/getWorkflows'];
    let workflows = workflowsList.map((id: string) => this.getWorkflowById(id));
    workflows = workflows.sort(sortByDateDesc('createdAt'));
    if (this.isSubscribedBasic) {
      workflows = workflows.map((wf: Workflow, index: number) => ({
        ...wf,
        active: index < 2,
      }));
    }
    workflows = workflows.filter((wf: Workflow) => wf.status === 'completed');
    return workflows.sort(this.sort);
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;
  }

  sort(a: Workflow, b: Workflow) {
    switch (this.selectedSort.key) {
      case 'name-asc':
      case 'name-desc':
        return this.sortByName(this.selectedSort.key)(a, b);
      default:
        return sortByDateDesc(this.selectedSort.key)(a, b);
    }
  }

  sortByName = (key: string) => (a: Workflow, b: Workflow) => {
    const name1 = (a.name).toLowerCase();
    const name2 = (b.name).toLowerCase();
    if (name1 > name2) {
      return key === 'name-desc' ? -1 : 1;
    }

    if (name1 < name2) {
      return key === 'name-desc' ? 1 : -1;
    }

    return 0;
  };

  get pSelected() {
    return this.selected;
  }

  get pAllChecked(): boolean | undefined {
    return this.allChecked;
  }

  set pAllChecked(value: boolean | undefined) {
    this.allChecked = value;
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getWorkflowById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  mounted() {
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      this.query = {
        ...this.query,
        ...query,
      };

      this.selected = [];

      await this.$store.dispatch(
        actions.WORKFLOWS_SEARCH,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  onActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'delete':
        this.handleDelete(workflow);
        break;
      default:
        // do nothing
    }
  }

  onGlobalToggle(checked: boolean) {
    if (checked) {
      this.selected = this.pWorkflows;
    } else {
      this.pAllChecked = false;
      this.selected = [];
    }
  }

  onToggle(workflow: Workflow, checked: boolean) {
    this.selected = checked
      ? [...this.selected || [], workflow]
      : this.selected?.filter((c) => c.id !== workflow.id);
  }
}
