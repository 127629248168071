
import { Component } from 'vue-property-decorator';
import FileBrowser from './file-browser';

@Component
export default class FileBrowserComponent extends FileBrowser {
  mounted() {
    this.fetchFiles();
  }
}
