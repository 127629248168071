import isFunction from 'lodash/isFunction';
import { InternalAxiosRequestConfig } from 'axios';

export default {
  addHeader: (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if (config.data instanceof FormData) {
      const formData = config.data as any;

      if (isFunction(formData.getHeaders)) {
        Object.assign(config.headers, formData.getHeaders());
      }
    }

    return config;
  },
};
