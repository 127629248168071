import qs from 'qs';

import { Query } from '@/types';

// eslint-disable-next-line import/prefer-default-export
export const buildQuery = (query: Query) => {
  const arr = [];

  if (query.page > 0) {
    arr.push(`_start=${query.page}`);
  }

  if (query.pageSize) {
    arr.push(`_limit=${query.pageSize}`);
  }

  if (query.filters) {
    arr.push(qs.stringify(query.filters));
  }

  return arr.length ? `?${arr.join('&')}` : '';
};

export const buildSearchQuery = (query: Query) => {
  const arr = [];

  if (query.page > 0) {
    arr.push(`page=${query.page}`);
  }

  if (query.pageSize) {
    arr.push(`pageSize=${query.pageSize}`);
  }

  if (query.filters) {
    arr.push(qs.stringify(query.filters));
  }

  return arr.length ? `?${arr.join('&')}` : '';
};
