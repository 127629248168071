import Vue from 'vue';

import DocumentTopTabBar from './DocumentTopTabBar.vue';
import Documents from './Documents.vue';
import IdentityDocs from './IdentityDocs.vue';
import DocumentListItem from './DocumentListItem.vue';
import DocumentTags from './DocumentTags.vue';
import DocumentTagsInfo from './DocumentTagsInfo.vue';

Vue.component('Documents', Documents);
Vue.component('DocumentTopTabBar', DocumentTopTabBar);
Vue.component('IdentityDocs', IdentityDocs);
Vue.component('DocumentListItem', DocumentListItem);
Vue.component('DocumentTags', DocumentTags);
Vue.component('DocumentTagsInfo', DocumentTagsInfo);
