
import { Component } from 'vue-property-decorator';

import actions from '@/store/constants';
import BaseComponent from './base-component';

@Component
export default class VerifyEmail extends BaseComponent {
  verifyEmail() {
    const { confirmation } = this.$route.query;

    if (confirmation) {
      this.$store.dispatch(actions.VERIFY_EMAIL, confirmation);
    }
  }

  mounted() {
    this.verifyEmail();
  }
}
