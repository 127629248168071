
import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { Tag } from '@/types';

@Component
export default class FileManagerActions extends BaseComponent {
  ids: string[] = [];

  isModalActive = false;

  folderName = '';

  file = null;

  tags: Tag[] = [];

  modal = {
    mode: 'add',
    title: '',
    btnText: '',
    error: '',
  };

  path = '';

  allTags: Tag[] = [];

  filteredTags: Tag[] = [];

  @Watch('tags')
  @Watch('$store.state.tags.all')
  setAllTags() {
    const tagIds = this.tags.filter((tag: Tag) => tag.id).map((tag) => tag.id);
    this.allTags = this.$store.state.tags.all.filter((tag: Tag) => !tagIds.includes(tag.id));
    this.filteredTags = this.allTags;
  }

  getFilteredTags(text: string) {
    this.filteredTags = this.allTags.filter((option: any) => option.name
      .toString().toLowerCase().indexOf(text.toLowerCase()) >= 0);
  }

  @Watch('isModalActive')
  emitModalClose() {
    if (!this.isModalActive) {
      this.$emit('modalClose');
    }
  }

  onClickAddFolder() {
    this.path = '';
    this.isModalActive = true;
    this.modal = {
      mode: 'add',
      title: 'Add Folder',
      error: '',
      btnText: 'Save',
    };
    this.folderName = '';
    this.file = null;
  }

  onClickUpload(path?: string) {
    this.isModalActive = true;
    this.modal = {
      mode: 'upload',
      title: 'Upload Document',
      error: '',
      btnText: 'Upload',
    };
    this.folderName = '';
    this.file = null;
    this.tags = [];
    this.$store.dispatch(actions.TAGS_GET_ALL);
    if (path) {
      this.path = path;
    } else {
      this.path = '';
    }
  }

  onClickRename(ids: string[]) {
    this.ids = ids;
    if (this.ids.length) {
      this.isModalActive = true;
      this.modal = {
        mode: 'edit',
        title: `Rename ${this.$store.state.files.data[this.ids[0]].type}`,
        error: '',
        btnText: 'Save',
      };
      if (this.$store.state.files.data[this.ids[0]].type === 'file') {
        const nameArr = this.$store.state.files.data[this.ids[0]].name.split('.');
        if (nameArr.length > 1) {
          nameArr.pop();
        }
        this.folderName = nameArr.join('.');
      } else {
        this.folderName = this.$store.state.files.data[this.ids[0]].name;
      }
      this.file = null;
    }
  }

  handleFileUpload(file: any) {
    this.file = file;
  }

  handleModalSubmit() {
    this.modal.error = '';
    if (this.modal.mode === 'upload') {
      if (!this.file) {
        this.modal.error = 'Please select a file';
        return;
      }
    } else if (!this.folderName.trim().length) {
      this.modal.error = 'Name can\'t be empty';
      return;
    } else if (this.folderName.trim().length > 120) {
      this.modal.error = 'Name cannot exceed 120 characters';
      return;
    }

    if (this.modal.mode === 'upload') {
      this.doFileUpload();
    } else if (this.modal.mode === 'add') {
      this.doAddFolder();
    } else if (this.modal.mode === 'edit') {
      this.doRename();
    }
  }

  async doFileUpload() {
    try {
      let basePath = '';
      if (this.$route.query.path && typeof this.$route.query.path === 'string') {
        basePath = this.$route.query.path;
      }

      const file = await this.$store.dispatch(actions.FILE_MANAGER_FILE_UPLOAD, {
        path: this.path || basePath,
        files: this.file,
      });
      if (this.tags.length) {
        await this.$store.dispatch(actions.FILE_MANAGER_ADD_TAGS, {
          id: file.id,
          path: file.pathLower,
          tags: this.tags.map((tag: Tag) => {
            if (tag.id) {
              return {
                id: tag.id,
              };
            }
            return {
              name: tag.name,
            };
          }),
        });
      }
      if (this.path) {
        this.$store.commit('fileManager/deleteFiles', [file.id]);
      }
      this.notify({
        message: 'File uploaded successfully.',
        type: 'is-success',
      });
      this.isModalActive = false;
    } catch (err) {
      this.handleError(err, 'error.generic');
    }
  }

  doAddFolder() {
    let basePath = '';
    if (this.$route.query.path && typeof this.$route.query.path === 'string') {
      basePath = this.$route.query.path;
    }
    this.$store.dispatch(
      actions.FILE_MANAGER_ADD_FOLDER,
      `${basePath}/${this.folderName.trim()}`,
    ).then(() => {
      this.notify({
        message: 'Folder created successfully.',
        type: 'is-success',
      });
      this.isModalActive = false;
    }).catch((err) => {
      this.handleError(err, 'error.fileManager.create.folder');
    });
  }

  doRename() {
    if (this.ids && this.ids.length) {
      const pathArr = this.$store.state.files.data[this.ids[0]].pathLower.split('/');
      pathArr.pop();
      let { folderName } = this;
      folderName = folderName.trim();
      if (this.$store.state.files.data[this.ids[0]].type === 'file') {
        const nameArr = this.$store.state.files.data[this.ids[0]].name.split('.');
        if (nameArr.length > 1) {
          folderName = `${folderName}.${nameArr[nameArr.length - 1]}`;
        }
      }
      if (this.$store.state.files.data[this.ids[0]].name.toLowerCase()
        === folderName.toLowerCase()) {
        this.isModalActive = false;
        return;
      }
      pathArr.push(folderName);
      const obj = {
        from: this.$store.state.files.data[this.ids[0]].pathLower,
        to: pathArr.join('/'),
      };
      this.$store.dispatch(actions.FILE_MANAGER_RENAME, obj).then(() => {
        this.notify({
          message: 'File/Folder Rename Successful.',
          type: 'is-success',
        });
        this.isModalActive = false;
        this.$emit('rename');
      }).catch((err) => {
        this.handleError(err, 'error.generic');
      });
    }
  }

  addTag = (tag: string | Tag) => {
    if (typeof tag === 'string') {
      return {
        name: tag,
      };
    }
    return {
      id: tag.id,
      name: tag.name,
    };
  };

  addTagOnClick() {
    (this.$refs.tagInput as any).addTag();
  }

  removeTag(index: number) {
    this.tags.splice(index, 1);
  }

  checkIfTagExists(text: string) {
    if (text.length > 30) {
      this.notify({
        message: this.$t('screen.tags.error.size') as string,
        type: 'is-danger',
      });
      return false;
    }
    return !this.tags.some((tag) => tag.name?.toLowerCase() === text.toLowerCase());
  }
}
