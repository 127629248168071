import Vue from 'vue';
import { ActionTree } from 'vuex';

import {
  DocumentStatus as api,
} from '@/api';

import {
  CustomError,
  DocumentStatus,
  Module,
} from '@/types';

import { RootState, DocumentStatusesState } from '../states';
import { Callback, callback } from './utils';

const getDefaultState = (): DocumentStatusesState => ({
  data: {},
  ids: [],
  loading: false,
  error: null,
});

const getters = {
  list: (state: DocumentStatusesState) => state.ids.map((id) => state.data[id]),
  completedStatusId: (state: DocumentStatusesState) => state.ids
    .map((id) => state.data[id])
    .find((status) => status.code === process.env.VUE_APP_COMPLETED_STATUS_CODE)?.id,
};

let listPromises: Callback[] = [];

const actions: ActionTree<DocumentStatusesState, RootState> = {
  async list({ commit, state }: any) {
    if (state.loading) {
      const cb = callback();

      listPromises.push(cb);

      return cb.promise;
    }

    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<DocumentStatus[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.list();
        const { data } = resp;
        const ids = data.map((item) => item.id);

        commit('setData', data);
        commit('setIds', ids);

        resolve(ids);

        listPromises.forEach((cb) => {
          cb.resolve(ids);
        });
      } catch (error) {
        commit('setError', error);

        reject(error);

        listPromises.forEach((cb) => {
          cb.reject(error);
        });
      }

      commit('setLoading', false);

      listPromises = [];
    });
  },
};

const mutations = {
  setLoading(state: DocumentStatusesState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: DocumentStatusesState, error: CustomError) {
    state.error = error;
  },
  setData(state: DocumentStatusesState, data: DocumentStatus []) {
    const d = state.data || {};

    data.forEach((item) => {
      d[item.id as string] = item;
    });

    state.error = null;
    state.data = { ...d };
  },

  setIds(state: DocumentStatusesState, ids: string[]) {
    state.ids = ids;
  },
  reset(state: DocumentStatusesState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): DocumentStatusesState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const documentStatuses: Module<DocumentStatusesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
