
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

@Component
export default class UserActivity extends BaseComponent {
  activityData = [];

  columns = [
    {
      field: 'message',
      label: 'Action',
    },
    {
      field: 'ipAddress',
      label: 'IP Address',
    },
    {
      field: 'createdAt',
      label: 'Time',
    },
  ];

  get myActivity() {
    const activity = this.activityData.filter((item: any) => item.message && item.ipAddress);

    return activity;
  }

  get pBreadcrumbs() {
    return [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.activity',
      },
    ];
  }

  async mounted() {
    const ref = this.$buefy.loading.open({
      isFullPage: true,
    });
    try {
      this.activityData = await this.$store.dispatch('users/getActivity');
    } catch (err) {
      this.handleError(err as Error);
    } finally {
      ref.close();
    }
  }
}
