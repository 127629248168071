import Vue from 'vue';
import { ActionTree } from 'vuex';

import { AxiosResponse } from 'axios';
import {
  CustomError,
  Comment,
  DocumentRequestResult,
  DocumentHistoryResult,
  Module,
  Document,
  InvitationAcceptResponse,
  DocumentRequestPayload,
  DocumentStatus,
  DocumentCommentPayload,
  Task,
  UpdateTaskPayload,
  TaskStatusPayload,
  TaskCommentPayload,
  ThirdPartyResponse,
  ThirdPartyESignPayload,
  IdentityResponse,
  IdentityStatusResponse,
  IdentityVerification,
} from '@/types';
import {
  ThirdParty as api,
} from '@/api';
import { RootState, ThirdPartyState } from '../states';

const getDefaultState = (): ThirdPartyState => ({
  data: null,
  loading: false,
  isRequestFetched: false,
  saving: false,
  error: null,
  history: {
    loading: false,
    saving: false,
    data: null,
    error: null,
    isDataFetched: false,
    comment: {
      loading: false,
      saving: false,
      error: null,
    },
  },
  newDocument: {
    loading: false,
    saving: false,
    data: null,
    error: null,
    isDataFetched: false,
  },
  task: {
    loading: false,
    loadingComments: false,
    saving: false,
    error: null,
    data: null,
  },
});

const getters = {
  assignedTo: (state: ThirdPartyState) => state.data?.contact || {},
  requestedBy: (state: ThirdPartyState) => state.data?.created_by || {},
  workflowName: (state: ThirdPartyState) => state.data?.workflow?.name || '',
  documents: (state: ThirdPartyState) => state.data?.documents || [],
};

const getNewDocument = (): any => ({
  id: `temp_${Date.now()}`,
  selected: true,
  category: '',
  document: '',
});

const postRequest = async (context: any, token: string, data: DocumentRequestResult, filesField = 'files') => {
  const payload: DocumentRequestPayload = {
    documents: [],
  };

  const documents = data.documents.filter((doc) => doc.selected);

  let uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];

  if (!uploadDocumentId) {
    await context.dispatch('actions/list', {}, { root: true });

    uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];
  }

  documents.forEach((doc) => {
    const {
      id,
      selected,
      hasDueDate,
      dueDate,
      document: documentId,
      documentName,
      showError,
      errors,
      ...rest
    } = doc;
    const document: any = rest;

    if (!(id as string).startsWith('temp_')) {
      document.id = id;
    }

    const otherId = context.rootGetters['categories/otherId'];

    if (doc.category === otherId) {
      document.documentName = documentName;
    } else {
      document.document = documentId;
    }

    if (hasDueDate && dueDate) {
      document.dueDate = new Date(dueDate).toISOString();
    }

    if (!doc.action) {
      document.action = uploadDocumentId;
    }

    payload.documents.push(document);
  });

  // eslint-disable-next-line no-restricted-syntax
  for await (const document of payload.documents as any[]) {
    if (document[filesField]) {
      const files = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const file of document[filesField]) {
        const f = await api.uploadFile(token, {
          path: '/',
          file: file as File,
        });

        files.push(f.data);
      }

      document.files = files;
    }
  }

  return payload;
};

const actions: ActionTree<ThirdPartyState, RootState> = {
  async accept({ commit }: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<InvitationAcceptResponse | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.accept(token);
        const { data } = resp;

        commit('setLoading', false);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async detail({ commit }: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<ThirdPartyResponse>(async (resolve, reject) => {
      try {
        const resp = await api.detail(token);
        const { data } = resp;

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  async getRequest(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<DocumentRequestResult | any>(async (resolve, reject) => {
      context.commit('setLoading', true);
      context.commit('setError', null);

      try {
        const resp = await api.getRequest(token);
        const { data } = resp;

        const documents = data.documents.map((doc) => ({
          ...doc,
          selected: true,
        }));

        if (documents.length) {
          data.documents = documents;
        } else {
          data.documents = [getNewDocument()];
        }

        context.commit('setLoading', false);
        context.commit('setRequestData', data);

        resolve(data);
      } catch (error) {
        context.commit('setLoading', false);
        context.commit('setError', error);

        reject(error);
      }
    });
  },

  async history(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<DocumentHistoryResult | any>(async (resolve, reject) => {
      context.commit('setHistoryLoading', true);
      context.commit('setHistoryError', null);

      try {
        const resp = await api.history(token);
        const { data } = resp;

        const documents = data.documents.map((doc) => ({
          ...doc,
          selected: false,
        }));

        data.documents = documents;

        context.commit('setHistoryLoading', false);
        context.commit('setHistoryData', data);

        resolve(data);
      } catch (error) {
        context.commit('setHistoryLoading', false);
        context.commit('setHistoryError', error);

        reject(error);
      }
    });
  },

  async submitRequest(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setSaving', true);
      context.commit('setError', null);

      try {
        const payload = await postRequest(context, token, context.state.data);

        await api.postRequest(token, payload);

        // context.commit('setSaving', false);

        context.commit('reset');

        resolve();
      } catch (error) {
        context.commit('setSaving', false);
        context.commit('setError', error);

        reject(error);
      }
    });
  },

  async updateRequest(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setHistorySaving', true);
      context.commit('setHistoryError', null);

      try {
        const payload: { documents: any[] } = {
          documents: [],
        };

        const { data }: {data: DocumentRequestResult} = context.state.history;

        const processedData = await postRequest(context, token, data, 'attachments');

        processedData.documents.forEach((doc) => {
          const {
            id,
            comment,
            files = [],
            status,
          } = doc;

          const document: any = {
            id,
            files,
            status: (status as DocumentStatus).id,
          };

          if (comment?.trim()) {
            document.comment = comment?.trim();
          }

          payload.documents.push(document);
        });

        await api.updateRequest(token, payload);

        context.commit('reset');

        resolve();
      } catch (error) {
        context.commit('setHistorySaving', false);
        context.commit('setHistoryError', error);

        reject(error);
      }
    });
  },
  async documentComments(context: any, { token, id }: { token: string; id: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Comment[]>(async (resolve, reject) => {
      context.commit('setHistoryCommentLoading', true);
      context.commit('setHistoryCommentError', null);

      try {
        const resp = await api.documentComments(token, id);

        const { data } = resp;

        context.commit('setHistoryCommentLoading', false);
        context.commit('setHistoryCommentsData', { id, comments: data });

        resolve(data);
      } catch (error) {
        context.commit('setHistoryCommentLoading', false);
        context.commit('setHistoryCommentError', error);

        reject(error);
      }
    });
  },
  async postDocumentComment(context: any, { token, payload }:
    { token: string; payload: DocumentCommentPayload }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Comment>(async (resolve, reject) => {
      context.commit('setHistoryCommentSaving', true);
      context.commit('setHistoryCommentError', null);

      try {
        const resp = await api.postDocumentComment(token, payload);

        const { data } = resp;

        context.commit('setHistoryCommentSaving', false);
        context.commit('setHistoryCommentData', data);

        resolve(data);
      } catch (error) {
        context.commit('setHistoryCommentSaving', false);
        context.commit('setHistoryCommentError', error);

        reject(error);
      }
    });
  },

  async getNewDocument(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<DocumentRequestResult | any>(async (resolve, reject) => {
      context.commit('setNewDocumentLoading', true);
      context.commit('setNewDocumentError', null);

      try {
        const resp = await api.getRequest(token);
        const { data } = resp;

        data.documents = [getNewDocument()];

        context.commit('setNewDocumentLoading', false);
        context.commit('setNewDocumentData', data);

        resolve(data);
      } catch (error) {
        context.commit('setNewDocumentLoading', false);
        context.commit('setNewDocumentError', error);

        reject(error);
      }
    });
  },

  async submitNewDocumentRequest(context: any, token: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setNewDocumentSaving', true);
      context.commit('setNewDocumentError', null);

      try {
        const payload = await postRequest(context, token, context.state.newDocument.data);

        await api.postNewDocumentRequest(token, payload);

        // context.commit('setNewDocumentSaving', false);

        context.commit('reset');

        resolve();
      } catch (error) {
        context.commit('setNewDocumentSaving', false);
        context.commit('setNewDocumentError', error);

        reject(error);
      }
    });
  },

  async task(context: any, { token, id }: { token: string; id: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Task>(async (resolve, reject) => {
      context.commit('setTaskLoading', true);
      context.commit('setTaskError', null);

      try {
        const resp = await api.task(token, id);

        const { data } = resp;

        context.commit('setTaskLoading', false);
        context.commit('setTask', data);

        resolve(data);
      } catch (error) {
        context.commit('setTaskLoading', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },

  async updateTask(context: any, payload: UpdateTaskPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setTaskSaving', true);
      context.commit('setTaskError', null);

      try {
        const files = [];
        const { token, id } = payload;

        // eslint-disable-next-line no-restricted-syntax
        for await (const file of payload.files) {
          const f = await api.uploadFile(token, {
            path: '/',
            file: file as File,
          });

          files.push(f.data);
        }

        const data: UpdateTaskPayload = {
          id,
          token,
          files,
        };

        await api.updateTask(data);

        context.commit('setTaskSaving', false);

        resolve();
      } catch (error) {
        context.commit('setTaskSaving', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },
  async changeTaskStatus(context: any, payload: TaskStatusPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setTaskSaving', true);
      context.commit('setTaskError', null);

      try {
        await api.changeTaskStatus(payload);

        context.commit('setTaskSaving', false);

        resolve();
      } catch (error) {
        context.commit('setTaskSaving', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },
  async taskComments(context: any, payload: TaskCommentPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Comment[]>(async (resolve, reject) => {
      context.commit('setTaskCommentsLoading', true);
      context.commit('setTaskError', null);

      try {
        const resp = await api.taskComments(payload);

        const { data } = resp;

        context.commit('setTaskComments', data);
        context.commit('setTaskCommentsLoading', false);

        resolve(data);
      } catch (error) {
        context.commit('setTaskCommentsLoading', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },
  async postTaskComment(context: any, payload: TaskCommentPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setTaskSaving', true);
      context.commit('setTaskError', null);

      try {
        await api.postTaskComment(payload);

        const resp = await api.taskComments(payload);
        const { data } = resp;

        context.commit('setTaskSaving', false);
        context.commit('setTaskComments', data);

        resolve();
      } catch (error) {
        context.commit('setTaskSaving', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },
  async eSign(context: any, payload: ThirdPartyESignPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      context.commit('setTaskSaving', true);
      context.commit('setTaskError', null);

      try {
        const resp = await api.eSign(payload);
        const { data } = resp;

        context.commit('setTaskSaving', false);

        resolve(data);
      } catch (error) {
        context.commit('setTaskSaving', false);
        context.commit('setTaskError', error);

        reject(error);
      }
    });
  },
  async generateContactToken({ commit }: any, { token }: any): Promise<IdentityResponse | any> {
    return new Promise<IdentityResponse | any>((resolve, reject) => {
      api.generateContactToken(token)
        .then((resp: AxiosResponse<IdentityResponse>) => {
          const { data } = resp;
          resolve(data);
        })
        .catch((error: CustomError) => {
          reject(error);
        });
    });
  },
  async updateContactStatus({ commit }: any, { token, applicantId }: any):
    Promise<IdentityStatusResponse | any> {
    return new Promise<IdentityStatusResponse | any>((resolve, reject) => {
      api.updateContactStatus(token, applicantId)
        .then((resp: AxiosResponse<IdentityStatusResponse>) => {
          const { data } = resp;

          resolve(data);
        })
        .catch((error: CustomError) => {
          reject(error);
        });
    });
  },

  async checkContactStatus({ commit }: any, { token }: any): Promise<IdentityVerification | any> {
    return new Promise<IdentityVerification | any>((resolve, reject) => {
      api.checkContactStatus(token)
        .then((resp: AxiosResponse<IdentityVerification>) => {
          const { data } = resp;

          resolve(data);
        })
        .catch((error: CustomError) => {
          reject(error);
        });
    });
  },

  async onfidoSendDocuments({ commit }: any, { token }: any): Promise<IdentityVerification | any> {
    return new Promise<IdentityVerification | any>((resolve, reject) => {
      api.onfidoSendDocuments(token)
        .then((resp: AxiosResponse<IdentityVerification>) => {
          const { data } = resp;

          resolve(data);
        })
        .catch((error: CustomError) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setLoading(state: ThirdPartyState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: ThirdPartyState, error: CustomError) {
    state.error = error;
  },
  setSaving(state: ThirdPartyState, saving: boolean) {
    state.saving = saving;
  },
  setRequestFetching(state: ThirdPartyState, fetched: boolean) {
    state.isRequestFetched = fetched;
  },
  setRequestData(state: ThirdPartyState, data: DocumentRequestResult) {
    state.data = data;
    state.isRequestFetched = !!data;
  },
  setCategory(state: ThirdPartyState, { id, category }: { id: string; category: string }) {
    const document = state.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(document, 'category', category);
  },

  setDocument(state: ThirdPartyState, { id, document }: { id: string; document: string }) {
    const current = state.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(current, 'document', document);
  },
  setAction(state: ThirdPartyState, { id, action }: { id: string; action: string }) {
    const current = state.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(current, 'action', action);
  },
  setSelected(state: ThirdPartyState, { id, selected }: { id: string; selected: boolean }) {
    const document = state.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(document, 'selected', selected);
  },

  mutateObject(state: ThirdPartyState, item: Document) {
    const data: any = state.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      if (doc.id === item.id) {
        return item;
      }

      return doc;
    });

    // Vue.set(state, 'data', data);
  },
  addNewDocument(state: ThirdPartyState) {
    const data: any = state.data || {
      documents: [],
    };

    data.documents = [
      ...data.documents,
      getNewDocument(),
    ];

    state.data = data;
  },
  checkAll(state: ThirdPartyState, selected: boolean) {
    const data: any = state.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      // eslint-disable-next-line no-param-reassign
      doc.selected = selected;

      return doc;
    });
  },

  setHistoryLoading(state: ThirdPartyState, loading: boolean) {
    state.history.loading = loading;
  },
  setHistoryError(state: ThirdPartyState, error: CustomError) {
    state.history.error = error;
  },
  setHistorySaving(state: ThirdPartyState, saving: boolean) {
    state.history.saving = saving;
  },
  setHistoryData(state: ThirdPartyState, data: DocumentHistoryResult) {
    state.history.data = data;
    state.history.isDataFetched = !!data;
  },
  setHistoryDataFetched(state: ThirdPartyState, isFetched: boolean) {
    state.history.isDataFetched = isFetched;
  },
  setHistorySelected(state: ThirdPartyState, { id, selected }: { id: string; selected: boolean }) {
    const document = state.history.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(document, 'selected', selected);
  },

  mutateHistoryObject(state: ThirdPartyState, item: Document) {
    const data: any = state.history.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      if (doc.id === item.id) {
        return item;
      }

      return doc;
    });
  },
  checkHistoryAll(state: ThirdPartyState, selected: boolean) {
    const data: any = state.history.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      // eslint-disable-next-line no-param-reassign
      doc.selected = selected;

      return doc;
    });
  },

  setHistoryCommentLoading(state: ThirdPartyState, loading: boolean) {
    state.history.comment.loading = loading;
  },
  setHistoryCommentSaving(state: ThirdPartyState, saving: boolean) {
    state.history.comment.saving = saving;
  },
  setHistoryCommentError(state: ThirdPartyState, error: CustomError) {
    state.history.comment.error = error;
  },
  setHistoryCommentData(state: ThirdPartyState, comment: Comment) {
    const document = state.history.data?.documents
      .find((doc) => doc.id === comment.workflowContactDocument.id);

    if (document) {
      document.comments = [
        ...document.comments as Comment[],
        comment,
      ];
    }
  },
  setHistoryCommentsData(state: ThirdPartyState, { id, comments }:
    { id: string; comments: Comment[] }) {
    const document = state.history.data?.documents.find((doc) => doc.id === id);

    if (document) {
      document.comments = comments;
    }
  },

  // new document related mutations
  setNewDocumentLoading(state: ThirdPartyState, loading: boolean) {
    state.newDocument.loading = loading;
  },
  setNewDocumentError(state: ThirdPartyState, error: CustomError) {
    state.newDocument.error = error;
  },
  setNewDocumentSaving(state: ThirdPartyState, saving: boolean) {
    state.newDocument.saving = saving;
  },
  setNewDocumentData(state: ThirdPartyState, data: DocumentRequestResult) {
    state.newDocument.data = data;
    state.newDocument.isDataFetched = !!data;
  },
  setNewDocumentCategory(state: ThirdPartyState, { id, category }:
    { id: string; category: string }) {
    const document = state.newDocument.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(document, 'category', category);
  },

  setNewDocumentDocument(state: ThirdPartyState, { id, document }:
    { id: string; document: string }) {
    const current = state.newDocument.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(current, 'document', document);
  },
  setNewDocumentAction(state: ThirdPartyState, { id, action }:
    { id: string; action: string }) {
    const current = state.newDocument.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(current, 'action', action);
  },
  setNewDocumentSelected(state: ThirdPartyState, { id, selected }:
    { id: string; selected: boolean }) {
    const document = state.newDocument.data?.documents.find((doc) => doc.id === id) as Document;
    Vue.set(document, 'selected', selected);
  },

  mutateNewDocumentObject(state: ThirdPartyState, item: Document) {
    const data: any = state.newDocument.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      if (doc.id === item.id) {
        return item;
      }

      return doc;
    });

    // Vue.set(state, 'data', data);
  },
  addPartialNewDocument(state: ThirdPartyState) {
    const data: any = state.newDocument.data || {
      documents: [],
    };

    data.documents = [
      ...data.documents,
      getNewDocument(),
    ];

    state.data = data;
  },
  checkNewDocumentAll(state: ThirdPartyState, selected: boolean) {
    const data: any = state.newDocument.data || {
      documents: [],
    };

    data.documents = data.documents.map((doc: Document) => {
      // eslint-disable-next-line no-param-reassign
      doc.selected = selected;

      return doc;
    });
  },

  // task
  setTaskLoading(state: ThirdPartyState, loading: boolean) {
    state.task.loading = loading;
  },
  setTaskSaving(state: ThirdPartyState, saving: boolean) {
    state.task.saving = saving;
  },
  setTaskCommentsLoading(state: ThirdPartyState, loading: boolean) {
    state.task.loadingComments = loading;
  },
  setTaskError(state: ThirdPartyState, error: CustomError) {
    state.task.error = error;
  },
  setTask(state: ThirdPartyState, task: Task) {
    state.task.data = task;
  },
  setTaskComments(state: ThirdPartyState, comments: Comment[]) {
    const task = state.task.data as Task;
    task.comments = comments;

    state.task.data = { ...task };
  },
  reset(state: ThirdPartyState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): ThirdPartyState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const thirdParty: Module<ThirdPartyState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
