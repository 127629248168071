
import { Component } from 'vue-property-decorator';

import { actions } from '@/store';

import {
  BreadcrumbItem,
  Business,
  Document,
  DocumentType,
  Workflow,
} from '@/types';

import WorkflowComponent from './Workflow';

const breadcrumbs: BreadcrumbItem[] = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
  },
  {
    title: 'dashboard.menu.workflows',
    path: '/workflows',
  },
];

@Component
export default class ViewWorkflowDocumentDetail extends WorkflowComponent {
  dataFetched = false;

  documentId = '';

  get loading() {
    return this.$store.state.workflowDocuments.detail.loading;
  }

  get document(): Document {
    const workflowDocuments = this.$store.state.workflowDocuments.data[this.id as string] || {};
    const document: Document = workflowDocuments[this.documentId];

    return document || {};
  }

  get pBreadcrumbs() {
    let items = breadcrumbs;
    const workflowDocuments = this.$store.state.workflowDocuments.data[this.id as string] || {};
    const document: Document = workflowDocuments[this.documentId];

    if (document) {
      const workflow = (document.workflow as Workflow);
      items = [
        ...items,
        {
          title: workflow?.name,
          path: `/workflows/${workflow.id}/documents`,
        },
        {
          title: document.documentName || (document.document as DocumentType)?.name,
        },
      ];
    }

    return items;
  }

  get name() {
    const name = this.document.documentName || (this.document.document as DocumentType)?.name;

    // const actionName = (this.document.action as Action)?.name;

    // if (name && actionName) {
    //   name += `(${actionName})`;
    // }

    return name;
  }

  async loadData() {
    const { documentId } = this.$route.params;

    this.documentId = documentId;

    const details = await this.$store.dispatch(actions.WORKFLOW_DOCUMENTS_DETAIL, {
      id: this.id, documentId,
    });

    if (details && details.business) {
      const businesses = this.$store.getters['auth/identity']?.businesses;
      const sBusiness = businesses?.find((business: Business) => business.id === details.business);
      if (sBusiness) {
        this.$store.commit('business/setSelectedBusiness', sBusiness.id);
      }
    } else {
      this.$store.commit('business/setSelectedBusiness', '');
    }
  }
}
