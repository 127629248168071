const isProduction = process.env.NODE_ENV === 'production';

// declare const ga: any;

const noop = () => undefined;
const info = isProduction ? noop : console.info;
const log = isProduction ? noop : console.log;
const debug = isProduction ? noop : console.debug;
const error = (message?: any, ...optionalParams: any[]): void => {
  console.error(message, optionalParams);

  /*
  TODO: uncomment this when ga is configured
  ga('send', 'exception', {
    exDescription: message,
  });

  */
};

export default {
  info,
  log,
  debug,
  error,
};
