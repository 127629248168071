
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '../base-component';

@Component
export default class ViewWorkflowTopTabBar extends BaseComponent {
  @Prop() workflow?: string;

  @Prop({ default: 0 }) selectedIndex?: number;

  get pContact() {
    return this.workflow;
  }

  get pSelectedIndex() {
    return this.selectedIndex;
  }
}
