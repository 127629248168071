import {
  Contact,
  Module,
} from '@/types';
import { RootState, ContactListState } from '../states';

const getDefaultState = (): ContactListState => ({
  loading: false,
  data: {},
  error: null,
});

const getters = {
  getById: (state: ContactListState) => (id: string) => state.data[id],
};

const mutations = {
  setContact(state: ContactListState, contact: Contact) {
    state.data = {
      ...state.data,
      [contact.id as string]: contact,
    };
  },

  setContacts(state: ContactListState, contacts: Contact[]) {
    state.data = {
      ...state.data,
      ...contacts.reduce((prev: { [id: string]: Contact }, current: Contact) => {
        const obj = prev;

        obj[current.id as string] = current;

        return obj;
      }, {}),
    };
  },

  removeContact(state: ContactListState, id: string) {
    state.data = Object.keys(state.data)
      .filter((key) => key !== id)
      .reduce((prev, current) => {
        const contacts: any = prev;

        contacts[current] = state.data[current];

        return contacts;
      }, { });
  },
  reset(state: ContactListState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): ContactListState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const contactList: Module<ContactListState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};
