
import { Component, Emit, Prop } from 'vue-property-decorator';
import { Task, PaginatedResult, emptyPaginatedResult } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class TaskList extends BaseComponent {
  @Prop({ default: false }) selectable?: boolean;

  @Prop({ default: false }) hasPagination?: boolean;

  get pSelectable() {
    return this.selectable;
  }

  get pHasPagination() {
    return this.hasPagination;
  }

  @Prop({ default: emptyPaginatedResult })
    result?: PaginatedResult<Task>;

  private get pResult(): PaginatedResult<Task> | undefined {
    return this.result;
  }

  private set pResult(value: PaginatedResult<Task>| undefined) {
    this.result = value;
  }

  get pPagination() {
    return this.pResult?.pagination || {};
  }

  @Emit('onActionClick')
  onActionClick(task: Task, action: string) {
    // ignore
  }

  onToggle(task: Task, checked: boolean) {
    this.$emit('onToggle', task, checked);
  }

  onPageChange(page: number) {
    this.$emit('onPageChange', page);
  }
}
