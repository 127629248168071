import { ActionTree } from 'vuex';

import {
  CreateContact,
  CustomError,
  Contact,
  Module,
  Query,
} from '@/types';
import { Contact as api } from '@/api';
import { RootState, ContactState } from '../states';
import { Callback, callback } from './utils';

const getDefaultState = (): ContactState => ({
  data: '',
  all: [],
  loading: false,
  error: null,
});

const getters = {};

let listPromises: Callback[] = [];

const actions: ActionTree<ContactState, RootState> = {
  async getAll({ commit, state }: any, query: Query) {
    if (state.loading) {
      const cb = callback();

      listPromises.push(cb);

      return cb.promise;
    }

    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Contact[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.search(query || {});
        const { data } = resp;

        commit('setLoading', false);
        commit('setAllContacts', data.results);

        resolve(data.results);

        listPromises.forEach((cb) => {
          cb.resolve(data.results);
        });
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);

        listPromises.forEach((cb) => {
          cb.reject(error);
        });
      }

      listPromises = [];
    });
  },
  async getById({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getById(id);
        const { data } = resp;

        commit('setLoading', false);
        commit('setContact', data.id);
        commit('contactList/setContact', data, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async multiple({ commit }: any, ids: string[]) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.multiple(ids);
        const { data } = resp;

        const contactIds = data.map((contact) => contact.id);

        commit('setLoading', false);
        commit('setContact', contactIds);
        commit('contactList/setContacts', data, { root: true });

        resolve(contactIds);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async create({ commit }: any, payload: CreateContact) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.create(payload);
        const { data } = resp;

        commit('setLoading', false);
        commit('contactList/setContact', data, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async update({ commit }: any, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const { id, ...contact } = payload;
        const resp = await api.update(id, contact);
        const { data } = resp;

        commit('setLoading', false);
        commit('contactList/setContact', data, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async delete({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.delete(id);
        const { data } = resp;

        commit('setLoading', false);
        commit('setContact', data.id);

        commit('searchContacts/removeContact', data.id, { root: true });
        commit('contactList/removeContact', data.id, { root: true });

        resolve(data.id);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async updateVerification(ctx: any, { contactId, workflowId, isVerificationRequired }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      try {
        const resp = await api.updateVerification(contactId, workflowId, isVerificationRequired);
        const { data } = resp;

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: ContactState, loading: boolean) {
    state.loading = loading;
  },
  setAllContacts(state: ContactState, allContacts: Contact[]) {
    state.all = allContacts;
  },
  setContact(state: ContactState, data: string | string[]) {
    state.data = data;
  },
  setError(state: ContactState, error: CustomError) {
    state.error = error;
  },
  reset(state: ContactState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): ContactState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const contact: Module<ContactState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
