import { AxiosResponse } from 'axios';

import {
  TwoFactorAuthRequest,
  TwoFactorAuthVerifyRequest,
  AuthResponse,
  TwoFactorAuthResponse,
} from '@/types';
import { jwtApi } from './api_base';

export default class TwoFactorAuth {
  static send(payload: TwoFactorAuthRequest): Promise<AxiosResponse<TwoFactorAuthResponse>> {
    return jwtApi.post<TwoFactorAuthResponse>('/2fa', payload);
  }

  static verify(payload: TwoFactorAuthVerifyRequest): Promise<AxiosResponse<AuthResponse>> {
    return jwtApi.post<AuthResponse>('/2fa/verify', payload);
  }
}
