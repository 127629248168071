import Vue from 'vue';
import { FinalForm, FinalField } from 'vue-final-form';

import Input from './Input.vue';
import EmailInput from './EmailInput.vue';
import PhoneInput from './PhoneInput.vue';
import PasswordInput from './PasswordInput.vue';
import Checkbox from './Checkbox.vue';
import Select from './Select.vue';
import FileUpload from './FileUpload.vue';
import FileUploadBox from './FileUploadBox.vue';
import ThirdPartyDate from './ThirdPartyDate.vue';
import DateTime from './DateTime.vue';
import SwitchToggle from './SwitchToggle.vue';
import ActionList from './ActionList.vue';
import CategoryList from './CategoryList.vue';
import DocumentTypeList from './DocumentTypeList.vue';
import DocumentStatusList from './DocumentStatusList.vue';
import ContactListDropdown from './ContactListDropdown.vue';
import DocumentStatusListDropdown from './DocumentStatusListDropdown.vue';

Vue.component('final-form', FinalForm);
Vue.component('final-field', FinalField);

// eslint-disable-next-line vue/no-reserved-component-names
Vue.component('Input', Input);
Vue.component('EmailInput', EmailInput);
Vue.component('PhoneInput', PhoneInput);
Vue.component('PasswordInput', PasswordInput);
Vue.component('Checkbox', Checkbox);
// eslint-disable-next-line vue/no-reserved-component-names
Vue.component('Select', Select);
Vue.component('FileUpload', FileUpload);
Vue.component('FileUploadBox', FileUploadBox);
Vue.component('DateTime', DateTime);
Vue.component('SwitchToggle', SwitchToggle);
Vue.component('DocumentTypeList', DocumentTypeList);
Vue.component('ActionList', ActionList);
Vue.component('CategoryList', CategoryList);
Vue.component('ThirdPartyDate', ThirdPartyDate);
Vue.component('DocumentStatusList', DocumentStatusList);
Vue.component('ContactListDropdown', ContactListDropdown);
Vue.component('DocumentStatusListDropdown', DocumentStatusListDropdown);
