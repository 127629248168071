import ms from 'ms';
import {
  Component, Emit, Prop,
} from 'vue-property-decorator';
import {
  Task,
  Contact,
} from '@/types';
import BaseComponent from '../base-component';

@Component
export default class TaskListItem extends BaseComponent {
  @Prop() task?: Task;

  get pTask() {
    return this.task;
  }

  @Emit('onActionClick')
  onActionClick(task: Task, action: string) {
    // ignore
  }

  getUser() {
    return this.$store.state.auth.identity;
  }

  showDueDateWarning(task: Task) {
    const duration = ms(process.env.VUE_APP_TASK_OVERDUE_BY);
    return task.dueDate && Date.now() > (new Date(task.dueDate).getTime() - duration);
  }

  get contactNames() {
    const filters = this.$options.filters as any;
    const fullName = filters['full-name'];

    if (this.task?.contact) {
      return fullName(this.task?.contact);
    }

    const names = (this.task?.contacts as Contact[] || []).map((contact) => fullName(contact));

    return names.join(', ');
  }

  get isOwner() {
    return this.task?.assignedBy === 'user' && this.task?.assignedTo === 'user';
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  get isCompleted() {
    return this.pTask?.status === 'completed';
  }

  onToggle(checked: boolean) {
    this.$emit('onToggle', this.task, checked);
  }
}
