import { AxiosResponse } from 'axios';

import { DocumentRequestAction } from '@/types';

import {
  api,
} from './api_base';

export default class {
  static list(): Promise<AxiosResponse<DocumentRequestAction []>> {
    return api.get<DocumentRequestAction []>('/actions');
  }
}
