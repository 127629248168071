
import { Component } from 'vue-property-decorator';
import { actions } from '@/store';
import SignInBase from './SignInBase';

@Component
export default class SignIn extends SignInBase {
  async mounted() {
    if (this.$store.state.auth?.token) {
      await this.$store.dispatch(actions.SIGN_OUT);
    }
  }
}
