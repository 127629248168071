import { AxiosResponse } from 'axios';

import {
  Country,
} from '@/types';

import {
  api,
} from './api_base';

export default class {
  static getAll(): Promise<AxiosResponse<Country[]>> {
    return api.get<Country[]>('/countries?_limit=1000&_sort=code:ASC');
  }
}
