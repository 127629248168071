import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  Tag,
} from '@/types';
import { Tags as api } from '@/api';
import { RootState, TagsState } from '../states';

const getDefaultState = (): TagsState => ({
  all: [],
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<TagsState, RootState> = {
  async getAll({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Tag[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getAll();
        const { data } = resp;

        commit('setLoading', false);
        commit('setTags', data);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: TagsState, loading: boolean) {
    state.loading = loading;
  },
  setTags(state: TagsState, tags: Tag[]) {
    state.all = tags;
  },
  setError(state: TagsState, error: CustomError) {
    state.error = error;
  },
  reset(state: TagsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): TagsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const tags: Module<TagsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
