interface Query {
  filters: { [key: string]: string };
  page: number;
  pageSize?: number;
}

export default Query;

export const defaultQuery: Query = {
  page: 0,
  pageSize: 10,
  filters: {},
};
