import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  Query,
} from '@/types';
import { Workflow as api } from '@/api';
import { RootState, WorkflowSummaryState } from '../states';

const getDefaultState = (): WorkflowSummaryState => ({
  data: [],
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<WorkflowSummaryState, RootState> = {
  async summary({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const query: Query = { pageSize: 4, page: 0, filters: {} };
        const resp = await api.search(query);
        const { data } = resp;

        const ids = data.map((workflow) => workflow.id);

        commit('setLoading', false);
        commit('setWorkflows', ids);
        commit('workflows/setWorkflows', data, { root: true });

        resolve(ids);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: WorkflowSummaryState, loading: boolean) {
    state.loading = loading;
  },
  removeWorkflow(state: WorkflowSummaryState, id: string) {
    state.data = state.data.filter((key) => key !== id);
  },
  setWorkflows(state: WorkflowSummaryState, data: string[]) {
    state.data = data;
  },
  setError(state: WorkflowSummaryState, error: CustomError) {
    state.error = error;
  },
  reset(state: WorkflowSummaryState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowSummaryState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflowSummary: Module<WorkflowSummaryState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
