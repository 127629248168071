import { ActionTree } from 'vuex';

import { Module } from '@/types';
import { RootState, UIState } from '../states';

const getDefaultState = (): UIState => ({
  showLogin: false,
  sidebar: {
    expanded: false,
    isMobile: false,
  },
});

const getters = {};

const actions: ActionTree<UIState, RootState> = {
  async showLogin({ commit }: any) {
    return new Promise((resolve) => {
      commit('setLoginState', true);
      resolve(true);
    });
  },

  async hideLogin({ commit }: any) {
    return new Promise((resolve) => {
      commit('setLoginState', false);
      resolve(false);
    });
  },

  async showSidebarOnMobile({ commit }: any) {
    return new Promise((resolve) => {
      commit('showSidebarOnMobile');
      resolve(true);
    });
  },

  async expandSidebar({ commit }: any) {
    return new Promise((resolve) => {
      commit('setSidebarExpand', true);
      resolve(true);
    });
  },

  async collapseSidebar({ commit }: any) {
    return new Promise((resolve) => {
      commit('setSidebarExpand', false);
      resolve(false);
    });
  },
  async toggleSidebar({ commit }: any) {
    return new Promise((resolve) => {
      commit('toggleSidebar');
      resolve(true);
    });
  },
  async setSidebar({ commit }: any, sidebar: { expanded: boolean; isMobile: boolean }) {
    return new Promise((resolve) => {
      commit('setSidebar', sidebar);
      resolve(true);
    });
  },
};

const mutations = {
  setLoginState(state: UIState, showLogin: boolean) {
    state.showLogin = showLogin;
  },
  setSidebarExpand(state: UIState, expanded: boolean) {
    state.sidebar = {
      ...state.sidebar,
      expanded,
    };
  },
  toggleSidebar(state: UIState) {
    state.sidebar = {
      ...state.sidebar,
      expanded: !state.sidebar.expanded,
    };
  },
  showSidebarOnMobile(state: UIState) {
    state.sidebar = {
      expanded: true,
      isMobile: true,
    };
  },

  setSidebar(state: UIState, sidebar: { expanded: boolean; isMobile: boolean }) {
    state.sidebar = sidebar;
  },
  reset(state: UIState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): UIState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const ui: Module<UIState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
