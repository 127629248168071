
import { Component, Watch } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  BreadcrumbItem,
  Company,
  Contact,
  defaultQuery,
  PaginatedResult,
  SortItem,
} from '@/types';
import { actions } from '@/store';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:DESC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'firstNameLower:ASC,lastNameLower:ASC',
    label: 'Name',
    icon: 'sort-alphabetical-ascending',
  },
];

@Component
export default class Contacts extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.contacts',
    },
  ];

  sortItems = sortItems;

  selectedSort = sortItems[0];

  selectedCompany?: Company;

  private query = {
    ...defaultQuery,
    filters: {
      _sort: 'createdAt:DESC',
    },
  };

  dataFetched = false;

  selected: Contact[] = [];

  selectable = false;

  allChecked = false;

  get loading() {
    return this.$store.state.contactList.loading;
  }

  get pData() {
    const data = this.$store.state.searchContacts.data as PaginatedResult<string>;
    return {
      ...data,
      results: data.results.map((id: string) => ({ ...this.getContactById(id) })),
    };
  }

  onCompanyChange(company: Company) {
    this.selectedCompany = company;

    this.loadData({
      page: 1,
    });
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;

    this.loadData({
      page: 1,
    });
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getContactById(id: string) {
    const contact = this.$store.getters['contactList/getById'](id);

    return {
      ...contact,
      selected: this.selected.some((item) => item.id === contact.id),
    };
  }

  created() {
    this.loadData();
  }

  get businessId() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  @Watch('businessId')
  async loadData(query = {}) {
    try {
      let filters: any = {
        _sort: this.selectedSort.key,
      };

      if (this.selectedCompany?.id) {
        filters = {
          ...filters,
          company: this.selectedCompany.id as string,
        };
      }

      this.query = {
        ...this.query,
        ...query,
        filters,
      };

      this.selected = [];

      await this.$store.dispatch(
        actions.SEARCH_CONTACTS,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  onActionClick(contact: Contact, action: string) {
    switch (action) {
      case 'invite-to-workflow':
        this.$router.push({ name: 'WorkflowInvite', query: { contacts: contact.id as string } });
        break;
      case 'create-task':
        this.$router.push({ name: 'CreateTask', query: { contacts: contact.id as string } });
        break;
      case 'edit':
        this.$router.push({ name: 'EditContact', params: { id: contact.id as string } });
        break;
      case 'delete':
        this.handleDelete(contact);
        break;
      default:
        // do nothing
    }
  }

  handleDelete(contact: Contact) {
    this.$buefy.dialog.confirm({
      title: this.$t('screen.contacts.delete.title') as string,
      message: this.$tc('screen.contacts.delete.message', 1) as string,
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('yes') as string,
      onConfirm: (value, dialog) => {
        this.onDeleteClick([contact]);
        dialog.close();
      },
    });
  }

  clearSelection() {
    this.selected = [];
  }

  onGlobalToggle(checked: boolean) {
    this.selectable = checked;
    // if (checked) {
    //   this.selected = this.pData.results;
    // } else {
    //   this.allChecked = false;
    //   this.selected = [];
    // }

    // this.contactListKey = Date.now();
  }

  onToggle(contact: Contact, checked: boolean) {
    this.selected = checked
      ? [...this.selected || [], contact]
      : this.selected?.filter((c) => c.id !== contact.id);
  }

  async onDeleteClick(contacts: Contact[]) {
    try {
      const promises: Promise<any>[] = [];

      contacts.forEach((contact) => {
        promises.push(this.$store.dispatch(actions.CONTACT_DELETE, contact.id));
      });

      await Promise.all(promises);

      this.notify({
        message: this.$t('screen.contacts.delete.success.message') as string,
      });

      this.loadData({ page: 1 });
    } catch (error) {
      this.handleError(error, 'screen.contacts.delete.error.title');
    }
  }
}
