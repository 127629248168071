
import { Component, Emit, Prop } from 'vue-property-decorator';
import { SortItem } from '@/types';

import BaseComponent from './base-component';

@Component
export default class SortDropdown extends BaseComponent {
  @Prop({ default: () => [] }) items?: SortItem[];

  @Prop({
    default: () => ({}),
  })
    selected?: SortItem;

  get pSelected() {
    return this.selected;
  }

  get pItems() {
    return this.items;
  }

  @Emit('change')
  change(item: SortItem) {
    if (item.key !== this.selected?.key) {
      this.selected = item;
    }
  }
}
