import { Component, Vue } from 'vue-property-decorator';

import { actions } from '@/store';
import { CustomError } from '@/types';

interface NotificationConfig {
  message: string | any[];
  type?: string;
  position?: string;
  duration?: number;
  container?: string;
  queue?: boolean;
  actionText?: string | null;
  indefinite?: boolean;
  onAction?: () => any;
}

@Component
export default class BaseComponent extends Vue {
  protected snackbarRef: any;

  closeNotification() {
    if (this.snackbarRef) {
      this.snackbarRef.close();
    }
  }

  notify(params: NotificationConfig | string) {
    const config = params as any;

    const options = {
      position: 'is-bottom-left',
      ...config,
    };

    this.snackbarRef = this.$buefy.snackbar.open(options);

    return this.snackbarRef;
  }

  async showLogin() {
    await this.$router.replace({ name: 'SignIn' });
  }

  async signOut() {
    await this.$store.dispatch(actions.SIGN_OUT);
    await this.$router.replace({ name: 'Home' });
  }

  parseMessage(error: CustomError) {
    const data = error.detail || { code: 'error.generic' };

    let message = this.$t(data.code as string || data.desc as string) as string;

    if (message === data.code) {
      message = this.$t('error.generic') as string;
    }

    return message;
  }

  handleError(error: any, title: string | undefined = undefined) {
    const message = this.parseMessage(error);

    this.$buefy.dialog.alert({
      title: title && this.$t(title) as string,
      message,
      type: 'is-danger',
      // canCancel: true,
      hasIcon: true,
    });
  }

  preventClick(event: Event) {
    event.preventDefault();
  }

  // eslint-disable-next-line class-methods-use-this
  get allowDocumentsWithInvite() {
    return true;
  }

  get isIdentityVerificationEnabled() {
    return process.env.VUE_APP_ENABLE_IDENTITY_VERIFICATION === 'true';
  }

  get domainName() {
    return process.env.VUE_APP_DOMAIN_NAME;
  }

  // eslint-disable-next-line class-methods-use-this
  get websitePublicURL() {
    return process.env.VUE_APP_WEBSITE_PUBLIC_BASE_URL;
  }

  // eslint-disable-next-line class-methods-use-this
  get supportEmail() {
    return process.env.VUE_APP_SUPPORT_EMAIL;
  }

  // eslint-disable-next-line class-methods-use-this
  get showTutorialVideos() {
    return process.env.VUE_APP_SHOW_TUTORIAL_VIDEOS === 'true';
  }
}
