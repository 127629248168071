
import { Component } from 'vue-property-decorator';

import { User } from '@/types';
import { identityVerificationStatus } from '@/utils/constants';

import BaseComponent from '../base-component';

@Component
export default class DefaultHeader extends BaseComponent {
  get showDashboardLink() {
    const user = this.$store.state.auth.identity as User;

    return this.$store.state.auth.token
      && identityVerificationStatus.isVerified(user?.onfido as any)
      && user?.subscription?.status === 'paid';
  }
}
