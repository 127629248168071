
import { Component } from 'vue-property-decorator';
import isArray from 'lodash/isArray';

import InputComponent from './input-component';
import { isEmail } from '../../utils/validations';

@Component
export default class EmailInput extends InputComponent {
  get validates() {
    const validate = this.validate as any;
    const arr = [];

    if (validate) {
      if (isArray(validate)) {
        validate.forEach((f) => arr.push(f));
      } else {
        arr.push(validate);
      }
    }

    arr.push(isEmail('Not a valid email address'));

    return arr;
  }
}
