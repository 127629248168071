
import { Component, Watch } from 'vue-property-decorator';
import { actions } from '@/store';
import { Workflow } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class WorkflowSummaryList extends BaseComponent {
  workflows: string[] = [];

  dataFetched = false;

  get pWorkflows() {
    return (this.workflows || []).map((id: string) => ({ ...this.getWorkflowById(id) }));
  }

  getWorkflowById(id: string) {
    return this.$store.getters['workflows/getById'](id);
  }

  onActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'edit':
        this.onClickEdit(workflow);
        break;
      case 'delete':
        this.handleDelete(workflow);
        break;
      default:
      // do nothing
    }
  }

  onToggle(contact: Workflow, checked: boolean) {
    this.$emit('onToggle', contact, checked);
  }

  created() {
    this.loadData();
  }

  async loadData() {
    try {
      this.workflows = await this.$store.dispatch(actions.WORKFLOW_SUMMARY);
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  workflowName = '';

  workflow: Workflow | null = null;

  onClickEdit(workflow: Workflow) {
    this.workflow = workflow;
    (this.$refs.workflowRenameModal as any).openModal(workflow.name);
  }

  handleRename(workflowName: string) {
    if (this.workflow) {
      this.$store.dispatch(actions.WORKFLOW_UPDATE, {
        id: this.workflow.id,
        name: workflowName,
      }).then(() => {
        this.$store.dispatch(actions.TASK_SUMMARY, {
          page: 0,
          pageSize: 5,
          filters: {
            _sort: 'dueDate:DESC',
            status_ne: 'completed',
          },
        });
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  protected handleDelete(workflow: Workflow) {
    const workflowName = workflow.name;
    this.$buefy.dialog.confirm({
      title: this.$t('screen.workflows.delete.title') as string,
      message: (this.$t('screen.workflows.delete.singleDelete') as string)
        .replace('{workflowName}', workflowName),
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('delete') as string,
      onConfirm: () => {
        this.$buefy.toast.open({
          message: this.$t('screen.workflows.delete.deleting') as string,
          position: 'is-bottom',
        });
        this.onDeleteClick([workflow]);
      },
    });
  }

  protected async onDeleteClick(workflows: Workflow[]) {
    try {
      await this.$store.dispatch(actions.WORKFLOW_DELETE, workflows[0].id);

      this.notify({
        message: this.$t('screen.workflows.delete.success.message') as string,
      });

      await this.loadData();
      await this.$store.dispatch(actions.TASK_SUMMARY, {
        page: 0,
        pageSize: 5,
        filters: {
          _sort: 'dueDate:DESC',
          status_ne: 'completed',
        },
      });
    } catch (error) {
      this.handleError(error, 'screen.workflows.delete.error.title');
    }
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  @Watch('selectedBusiness')
  onChangeBusiness() {
    this.loadData();
  }
}
