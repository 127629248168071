
import { Component, Prop } from 'vue-property-decorator';
import { Person } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class ThirdPartyRequestedByBarSmall extends BaseComponent {
  @Prop({ default: false }) loading?: boolean;

  @Prop() person?: Person;

  get pLoading() {
    return this.loading;
  }

  get pPerson() {
    return this.person;
  }
}
