
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '../base-component';

@Component
export default class CategoryList extends BaseComponent {
  // @Prop({ default: true }) error: boolean;

  @Prop() selected?: string | undefined;

  @Prop({ default: () => false }) disabled?: boolean;

  get pSelected(): string | undefined {
    return this.selected;
  }

  set pSelected(selected: string | undefined) {
    this.selected = selected;
  }

  get pDisabled() {
    return this.disabled;
  }

  get categories() {
    return this.$store.getters['categories/list'];
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.$store.dispatch('categories/list');
  }

  onChange(value: string) {
    this.$emit('change', value);
  }
}
