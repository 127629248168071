import Vue from 'vue';

import ViewContactTopTabBar from './ViewContactTopTabBar.vue';
import ContactList from './ContactList.vue';
import ContactListItem from './ContactListItem.vue';
import ContactListTabBar from './ContactListTabBar.vue';
import ContactQuickActions from './ContactQuickActions.vue';
import AddUpdateContact from './AddUpdateContact.vue';
import ContactWorkflows from './ContactWorkflows.vue';

Vue.component('ViewContactTopTabBar', ViewContactTopTabBar);
Vue.component('ContactList', ContactList);
Vue.component('ContactListItem', ContactListItem);
Vue.component('ContactListTabBar', ContactListTabBar);
Vue.component('ContactQuickActions', ContactQuickActions);
Vue.component('AddUpdateContact', AddUpdateContact);
Vue.component('ContactWorkflows', ContactWorkflows);
