import { AxiosResponse } from 'axios';

import {
  Company,
  PaginatedResult,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static getAll(): Promise<AxiosResponse<PaginatedResult<Company>>> {
    return jwtApi.get<PaginatedResult<Company>>('/companies?_sort=name:ASC');
  }
}
