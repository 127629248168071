var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('b-field',{staticClass:"pt-2"},[_c('b-checkbox',{attrs:{"value":_vm.pDocument.selected},on:{"input":_vm.handleCheckChange}})],1),_c('div',{staticClass:"fields"},[_c('b-field',{attrs:{"label":"Document Category","label-position":"inside","type":{
        'is-danger': !_vm.pDocument.category && _vm.pDocument.showError && _vm.pDocument.errors
          && _vm.pDocument.errors.category,
      },"message":{
        [_vm.pDocument.errors && _vm.pDocument.errors.category
          ? _vm.pDocument.errors.category : '']: !_vm.pDocument.category
          && _vm.pDocument.showError && _vm.pDocument.errors && _vm.pDocument.errors.category,
      }}},[_c('CategoryList',{attrs:{"selected":_vm.pDocument.category,"disabled":!_vm.pDocument.id.startsWith('temp_')},on:{"change":_vm.handleCategoryChange}})],1),(_vm.options.showOther)?_c('b-field',{attrs:{"label":"Document Name","label-position":"inside","type":{
        'is-danger': !_vm.pDocument.documentName && _vm.pDocument.showError && _vm.pDocument.errors
          && _vm.pDocument.errors.documentName,
      },"message":{
        [_vm.pDocument.errors && _vm.pDocument.errors.documentName
          ? _vm.pDocument.errors.documentName : '']: !_vm.pDocument.documentName
          && _vm.pDocument.showError && _vm.pDocument.errors && _vm.pDocument.errors.documentName,
      }}},[_c('b-input',{model:{value:(_vm.pDocument.documentName),callback:function ($$v) {_vm.$set(_vm.pDocument, "documentName", $$v)},expression:"pDocument.documentName"}})],1):_c('b-field',{attrs:{"label":"Document Type","label-position":"inside","type":{
        'is-danger': !_vm.pDocument.document && _vm.pDocument.showError && _vm.pDocument.errors
          && _vm.pDocument.errors.document,
      },"message":{
        [_vm.pDocument.errors && _vm.pDocument.errors.document
          ? _vm.pDocument.errors.document : '']: !_vm.pDocument.document
          && _vm.pDocument.showError && _vm.pDocument.errors && _vm.pDocument.errors.document,
      }}},[_c('DocumentTypeList',{attrs:{"selected":_vm.pDocument.document,"category":_vm.pDocument.category},on:{"change":_vm.handleDocumentChange}})],1),_c('b-field',[_c('b-switch',{model:{value:(_vm.pDocument.hasDueDate),callback:function ($$v) {_vm.$set(_vm.pDocument, "hasDueDate", $$v)},expression:"pDocument.hasDueDate"}},[_vm._v("Due date/time")])],1),_c('DateTime',{attrs:{"disabled":!_vm.pDocument.hasDueDate,"date":_vm.pDocument.dueDate,"error":_vm.pDocument.hasDueDate
        && _vm.pDocument.showError && _vm.pDocument.errors && _vm.pDocument.errors.dueDate
        ? _vm.pDocument.errors.dueDate : ''},on:{"input":_vm.onDueDateChange}}),(_vm.pDocument.comment || _vm.pDocument.action || (_vm.pDocument.files && _vm.pDocument.files.length))?_c('b-button',{attrs:{"size":"is-medium","icon-left":"pencil"},on:{"click":_vm.handleAddInfo}},[_vm._v(" Edit Info ")]):_c('b-button',{attrs:{"size":"is-medium","icon-left":"plus"},on:{"click":_vm.handleAddInfo}},[_vm._v(" Add Info ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }