
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import prettyBytes from 'pretty-bytes';

@Component
export default class FileList extends Vue {
  @Prop({ default: () => [] }) files?: File[];

  @Prop({ default: '' }) name?: string;

  @Prop({ default: '' }) size?: string;

  @Prop({ default: true }) allowRemove?: boolean;

  @Prop({ default: true }) showHeading?: boolean;

  private get pName() {
    return this.name;
  }

  private get pSize() {
    return this.size;
  }

  get pFiles() {
    return this.files;
  }

  getSize(file: File) {
    return prettyBytes(file.size || (file as any).file.size);
  }

  deleteDropFile(index: number) {
    (this.files as File[]).splice(index, 1);
  }
}
