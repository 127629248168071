import {
  Document,
  Workflow,
  Module,
  CustomError,
} from '@/types';
import { RootState, WorkflowsState } from '../states';

const getDefaultState = (): WorkflowsState => ({
  loading: false,
  data: {},
  error: null,
  documents: {},
  newDocuments: {},
});

const getters = {
  getById: (state: WorkflowsState) => (id: string) => state.data[id],
};

const mutations = {
  setWorkflow(state: WorkflowsState, contact: Workflow) {
    state.data = {
      ...state.data,
      [contact.id as string]: contact,
    };
  },
  setWorkflows(state: WorkflowsState, contacts: Workflow[]) {
    state.data = {
      ...state.data,
      ...contacts.reduce((prev: { [id: string]: Workflow }, current: Workflow) => {
        const obj = prev;

        obj[current.id as string] = current;

        return obj;
      }, {}),
    };
  },
  removeWorkflow(state: WorkflowsState, id: string) {
    state.data = Object.keys(state.data)
      .filter((key) => key !== id)
      .reduce((prev, current) => {
        const contacts: any = prev;

        contacts[current] = state.data[current];

        return contacts;
      }, { });
  },
  updateWorkflow(state: WorkflowsState, workflow: Workflow) {
    if (state.data[workflow.id as string]) {
      state.data[workflow.id as string].name = workflow.name;
    }
  },

  setInitialDocumentsLoading(
    state: WorkflowsState,
    { id, loading }: { id: string; loading: boolean },
  ) {
    const doc = state.documents[id] || {};

    doc.loading = loading;

    state.documents[id] = { ...doc };
  },

  setInitialDocumentsError(
    state: WorkflowsState,
    { id, error }: { id: string; error: CustomError },
  ) {
    const doc = state.documents[id] || {};

    doc.error = error;

    state.documents[id] = { ...doc };
  },

  setInitialDocuments(state: WorkflowsState, { id, data }: { id: string; data: Document[] }) {
    const doc = state.documents[id] || {};

    doc.items = data;

    state.documents[id] = { ...doc };
  },

  setNewDocumentsSaving(
    state: WorkflowsState,
    { id, saving }: { id: string; saving: boolean },
  ) {
    const doc = state.newDocuments[id] || {};

    doc.saving = saving;

    state.newDocuments[id] = { ...doc };
  },

  setNewDocumentsError(
    state: WorkflowsState,
    { id, error }: { id: string; error: CustomError },
  ) {
    const doc = state.newDocuments[id] || {};

    doc.error = error;

    state.newDocuments[id] = { ...doc };
  },

  reset(state: WorkflowsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflows: Module<WorkflowsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};
