import Vue from 'vue';

import DefaultHeader from './DefaultHeader.vue';
import OnboardingHeader from './OnboardingHeader.vue';
import DashboardHeader from './DashboardHeader.vue';
import ThirdPartyHeader from './ThirdPartyHeader.vue';
import ContentHeader from './ContentHeader.vue';

Vue.component('DefaultHeader', DefaultHeader);
Vue.component('OnboardingHeader', OnboardingHeader);
Vue.component('DashboardHeader', DashboardHeader);
Vue.component('ThirdPartyHeader', ThirdPartyHeader);
Vue.component('ContentHeader', ContentHeader);
