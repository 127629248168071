import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  User,
} from '@/types';

import {
  User as api,
} from '@/api';

import storage from '@/storage';

import { RootState, UsersState } from '../states';

const getDefaultState = (): UsersState => ({
  data: {},
  loading: false,
  error: null,
});

const actions: ActionTree<UsersState, RootState> = {
  async me({ commit }: any): Promise<User> {
    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<User>(async (resolve) => {
      try {
        const resp = await api.me();

        const { data } = resp;

        storage.user = data;
        commit('setData', data);
        commit('auth/setIdentity', data, { root: true });

        resolve(data);
      } catch (error) {
        commit('setError', error);
      }

      commit('setLoading', false);
    });
  },
  async deleteUser() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.deleteUser();
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  async getActivity() {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await api.myActivity();
        const { data } = resp;
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: UsersState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: UsersState, error: CustomError) {
    state.error = error;
  },
  setData(state: UsersState, user: User) {
    state.data[user.id as string] = user;
  },
  reset(state: UsersState) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

const state = (): UsersState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const users: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
