import { AxiosResponse } from 'axios';

import {
  Comment,
  InvitationAcceptResponse,
  ThirdPartyResponse,
  DocumentRequestResult,
  DocumentHistoryResult,
  DocumentRequestPayload,
  DocumentCommentPayload,
  FileUploadPayload,
  Task,
  UpdateTaskPayload,
  TaskStatusPayload,
  TaskCommentPayload,
  IdentityResponse,
  IdentityStatusResponse,
  IdentityVerification,
} from '@/types';

import {
  api,
} from './api_base';

export default class {
  static accept(token: string): Promise<AxiosResponse<InvitationAcceptResponse>> {
    return api.post<InvitationAcceptResponse>(`/3p/${token}/accept`);
  }

  static detail(token: string): Promise<AxiosResponse<ThirdPartyResponse>> {
    return api.get<ThirdPartyResponse>(`/3p/${token}`);
  }

  static getRequest(token: string): Promise<AxiosResponse<DocumentRequestResult>> {
    return api.get<DocumentRequestResult>(`/3p/${token}/request`);
  }

  static history(token: string): Promise<AxiosResponse<DocumentHistoryResult>> {
    return api.get<DocumentHistoryResult>(`/3p/${token}/history`);
  }

  static postRequest(token: string, payload: DocumentRequestPayload): Promise<AxiosResponse<void>> {
    return api.post<void>(`/3p/${token}/request`, payload);
  }

  static updateRequest(token: string, payload: DocumentRequestPayload):
    Promise<AxiosResponse<void>> {
    return api.put<void>(`/3p/${token}/request`, payload);
  }

  static postNewDocumentRequest(token: string, payload: DocumentRequestPayload):
  Promise<AxiosResponse<void>> {
    return api.patch<void>(`/3p/${token}/request`, payload);
  }

  static postDocumentComment(token: string, payload: DocumentCommentPayload):
    Promise<AxiosResponse<Comment>> {
    const { documentId, ...data } = payload;
    return api.post<Comment>(`/3p/${token}/documents/${documentId}/comment`, data);
  }

  static documentComments(token: string, id: string):
    Promise<AxiosResponse<Comment[]>> {
    return api.get<Comment[]>(`/3p/${token}/documents/${id}/comments`);
  }

  static task(token: string, id: string):
    Promise<AxiosResponse<Task>> {
    return api.get<Task>(`/3p/${token}/tasks/${id}`);
  }

  static updateTask(payload: UpdateTaskPayload):
    Promise<AxiosResponse<void>> {
    const { token, id, ...data } = payload;
    return api.put<void>(`/3p/${token}/tasks/${id}`, data);
  }

  static changeTaskStatus(payload: TaskStatusPayload):
    Promise<AxiosResponse<void>> {
    const { token, id, ...data } = payload;
    return api.put<void>(`/3p/${token}/tasks/${id}/status`, data);
  }

  static taskComments(payload: TaskCommentPayload):
    Promise<AxiosResponse<Comment[]>> {
    const { token, id } = payload;
    return api.get<Comment[]>(`/3p/${token}/tasks/${id}/comments`);
  }

  static postTaskComment(payload: TaskCommentPayload):
    Promise<AxiosResponse<void>> {
    const { token, id, ...data } = payload;
    return api.post<void>(`/3p/${token}/tasks/${id}/comment`, data);
  }

  static uploadFile(token: string, payload: FileUploadPayload): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append('files', payload.file);
    formData.append('path', payload.path);

    return api.post<any>(`/3p/${token}/file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static eSign(payload: TaskCommentPayload):
    Promise<AxiosResponse<any>> {
    const { token, id } = payload;
    return api.post<any>(`/3p/${token}/files/${id}/esign`);
  }

  static generateContactToken(token: string): Promise<AxiosResponse<IdentityResponse>> {
    return api.post<IdentityResponse>(`/3p/${token}/identity-verification/token`);
  }

  static updateContactStatus(token: string, applicantId: string):
    Promise<AxiosResponse<IdentityStatusResponse>> {
    return api.put<IdentityStatusResponse>(`/3p/${token}/identity-verification/status/${applicantId}`);
  }

  static checkContactStatus(token: string): Promise<AxiosResponse<IdentityVerification>> {
    return api.get<IdentityVerification>(`/3p/${token}/identity-verification/status`);
  }

  static onfidoSendDocuments(token: string): Promise<AxiosResponse> {
    return api.get(`/3p/${token}/verifiedDocuments/send`);
  }
}
