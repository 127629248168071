var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-card c-verify"},[_c('div',{staticClass:"c-card__top"},[_c('div',{staticClass:"c-title-header c-title-header--only-heading"},[_c('div',{staticClass:"c-title-header__center is-half"},[_c('h2',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t('form.registrationSuccess.heading')))])])]),_c('h3',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.$t('form.registrationSuccess.subHeading')))]),_vm._m(0),_c('p',{staticClass:"has-text-centered mt-6",domProps:{"innerHTML":_vm._s(_vm.$t('form.registrationSuccess.desc',
                   { email: _vm.$store.state.auth.identity.email }))}})]),_c('div',{staticClass:"c-card__bottom"},[_c('b-button',{attrs:{"type":"button is-primary is-large mb-4 mt-4 is-fullwidth","expanded":""},on:{"click":_vm.showLogin}},[_vm._v(" "+_vm._s(_vm.$t('signIn'))+" ")]),_c('p',{staticClass:"has-text-centered"},[_c('a',{staticClass:"has-text-underline",attrs:{"href":`
mailto:${_vm.supportEmail}?subject=I%27m%20having%20trouble%20verifying%20my%20email%20address
&body=I%20have%20followed%20the%20instructions%20on%20${_vm.domainName}%20but%20I%20am%20still%20
unable%20to%20verify%20my%20email%20address.
%20Please%20advise.`,"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('qHavingTrouble'))+" ")])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('img',{attrs:{"src":require("../assets/castle-icon.svg"),"alt":""}})])
}]

export { render, staticRenderFns }