import Vue from 'vue';

import ThirdPartyAssignedToBar from './ThirdPartyAssignedToBar.vue';
import ThirdPartyRequestedByBar from './ThirdPartyRequestedByBar.vue';
import ThirdPartyAssignedByBar from './ThirdPartyAssignedByBar.vue';
import ThirdPartyWorkflowNameWithIcon from './ThirdPartyWorkflowNameWithIcon.vue';
import ThirdPartyDocumentListInputItem from './ThirdPartyDocumentListInputItem.vue';
import ThirdPartyHistoryDocumentListItem from './ThirdPartyHistoryDocumentListItem.vue';
import ThirdPartyDocumentListInputSkeletonItem from './ThirdPartyDocumentListInputSkeletonItem.vue';
import ThirdPartyHistoryDocumentListSkeletonItem from './ThirdPartyHistoryDocumentListSkeletonItem.vue';
import ThirdPartyAcceptInvitation from './ThirdPartyAcceptInvitation.vue';
import ThirdPartTask from './ThirdPartTask.vue';
import ThirdPartyDocumentRequest from './ThirdPartyDocumentRequest.vue';
import ThirdPartyDocumentRequestHistory from './ThirdPartyDocumentRequestHistory.vue';
import ThirdPartyDocumentNewRequest from './ThirdPartyDocumentNewRequest.vue';
import ThirdPartyRequestedByBarSmall from './ThirdPartyRequestedByBarSmall.vue';

Vue.component('ThirdPartyAssignedToBar', ThirdPartyAssignedToBar);
Vue.component('ThirdPartyRequestedByBar', ThirdPartyRequestedByBar);
Vue.component('ThirdPartyAssignedByBar', ThirdPartyAssignedByBar);
Vue.component('ThirdPartyWorkflowNameWithIcon', ThirdPartyWorkflowNameWithIcon);
Vue.component('ThirdPartyDocumentListInputItem', ThirdPartyDocumentListInputItem);
Vue.component('ThirdPartyHistoryDocumentListItem', ThirdPartyHistoryDocumentListItem);
Vue.component('ThirdPartyDocumentListInputSkeletonItem', ThirdPartyDocumentListInputSkeletonItem);
Vue.component('ThirdPartyHistoryDocumentListSkeletonItem', ThirdPartyHistoryDocumentListSkeletonItem);
Vue.component('ThirdPartyAcceptInvitation', ThirdPartyAcceptInvitation);
Vue.component('ThirdPartTask', ThirdPartTask);
Vue.component('ThirdPartyDocumentRequest', ThirdPartyDocumentRequest);
Vue.component('ThirdPartyDocumentRequestHistory', ThirdPartyDocumentRequestHistory);
Vue.component('ThirdPartyDocumentNewRequest', ThirdPartyDocumentNewRequest);
Vue.component('ThirdPartyRequestedByBarSmall', ThirdPartyRequestedByBarSmall);
