import { AxiosResponse } from 'axios';

import { DocumentRequestDocument } from '@/types';

import {
  api,
} from './api_base';

export default class {
  static list(): Promise<AxiosResponse<DocumentRequestDocument []>> {
    return api.get<DocumentRequestDocument []>('/documents');
  }
}
