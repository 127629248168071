import Vue from 'vue';
import { ActionTree } from 'vuex';

import {
  Action as api,
} from '@/api';

import {
  Action,
  Module,
} from '@/types';

import { RootState, ActionsState } from '../states';

const getDefaultState = (): ActionsState => ({
  data: {},
  ids: [],
  loading: false,
  error: null,
});

const getters = {
  list: (state: ActionsState) => state.ids.map((id) => state.data[id]),
  getById: (state: ActionsState) => (id: string) => state.data[id],
  uploadDocumentId: (state: ActionsState) => state.ids
    .map((id) => state.data[id])
    .find((action) => action.code === process.env.VUE_APP_DEFAULT_ACTION_CODE)?.id,
};

const actionActions: ActionTree<ActionsState, RootState> = {
  async list({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Action[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.list();
        const { data } = resp;
        const ids = data.map((action) => action.id);

        commit('setData', data);
        commit('setIds', ids);

        resolve(ids);
      } catch (error) {
        reject(error);
      }
    });
  },
};

const mutations = {
  setData(state: ActionsState, data: Action []) {
    const d = state.data || {};

    data.forEach((item) => {
      d[item.id || ''] = item;
    });

    Vue.set(state, 'data', d);
  },
  setIds(state: ActionsState, ids: string[]) {
    state.ids = ids;
  },
  reset(state: ActionsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): ActionsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const actions: Module<ActionsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions: actionActions,
  mutations,
};
