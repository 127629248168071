
import cloneDeep from 'lodash/cloneDeep';

import { Component, Prop } from 'vue-property-decorator';

import {
  BreadcrumbItem,
  Task,
  Contact,
  defaultQuery,
  PaginatedResult,
  emptyPaginatedResult,
  SortItem,
} from '@/types';
import { actions } from '@/store';

import TaskComponent from './Task';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:DESC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'dueDate:DESC',
    label: 'Due date',
    icon: 'calendar',
  },
  {
    key: 'title:ASC',
    label: 'Title asc',
    icon: 'sort-alphabetical-ascending',
  },
  {
    key: 'title:DESC',
    label: 'Title desc',
    icon: 'sort-alphabetical-descending',
  },
];

const queryWithFilters = cloneDeep(defaultQuery);

queryWithFilters.filters = {
  ...queryWithFilters.filters,
  assignedTo: 'user',
  status: 'pending',
  _sort: 'dueDate:DESC',
};

@Component
export default class Tasks extends TaskComponent {
  private query = queryWithFilters;

  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.tasks',
    },
  ];

  sortItems = sortItems;

  selectedSort = sortItems[0];

  selectedContact?: Contact;

  dataFetched = false;

  @Prop({ default: () => Date.now() }) taskListKey?: number;

  @Prop({ default: false }) selectable?: boolean;

  @Prop({ default: emptyPaginatedResult })
    result?: PaginatedResult<string>;

  @Prop({ default: () => [] }) selected?: Task[];

  @Prop({ default: false }) allChecked?: boolean;

  get pSelectable() {
    return this.selectable;
  }

  get pTaskListKey() {
    return this.taskListKey;
  }

  get loading() {
    return this.$store.state.tasks.loading;
  }

  get pData() {
    return {
      ...this.result,
      results: this.result?.results.map((id: string) => ({ ...this.getTaskById(id) })),
    };
  }

  get pSelected() {
    return this.selected;
  }

  get pAllChecked(): boolean | undefined {
    return this.allChecked;
  }

  set pAllChecked(value: boolean | undefined) {
    this.allChecked = value;
  }

  onContactChange(contact: Contact) {
    this.selectedContact = contact;

    this.loadData({
      page: 1,
    });
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;

    this.loadData({
      page: 1,
    });
  }

  onPageChange(page: number) {
    this.loadData({ page });
  }

  getTaskById(id: string) {
    return this.$store.getters['task/getById'](id);
  }

  mounted() {
    // this.loadStaleData();
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      let filters: any = {
        _sort: this.selectedSort.key,
      };

      if (this.selectedContact?.id) {
        filters = {
          ...filters,
          contact: this.selectedContact.id as string,
        };
      }

      this.query = {
        ...this.query,
        ...query,
        filters,
      };

      this.selected = [];

      this.result = await this.$store.dispatch(
        actions.TASK_LIST,
        this.query,
      );
    } catch (error) {
      // TODO: show error message
    }

    this.dataFetched = true;
  }

  loadStaleData() {
    this.result = this.$store.state.tasks.data as PaginatedResult<string>;
  }

  onActionClick(task: Task, action: string) {
    switch (action) {
      case 'invite-to-workflow':
        this.$router.push({ name: 'WorkflowInvite', query: { tasks: task.id as string } });
        break;
      case 'edit':
        this.$router.push({ name: 'EditContact', params: { id: task.id as string } });
        break;
      case 'delete':
        // this.handleDelete(task);
        break;
      default:
        // do nothing
    }
  }

  onToggle(task: Task, checked: boolean) {
    this.selected = checked
      ? [...this.selected || [], task]
      : this.selected?.filter((c) => c.id !== task.id);
  }

  refreshTasks() {
    this.loadData();
  }

  async onDeleteClick(tasks: Task[]) {
    try {
      const promises: Promise<any>[] = [];

      tasks.forEach((task) => {
        promises.push(this.$store.dispatch(actions.CONTACT_DELETE, task.id));
      });

      await Promise.all(promises);

      this.notify({
        message: this.$t('screen.contacts.delete.success.message') as string,
      });

      this.loadData({ page: 1 });
    } catch (error) {
      this.handleError(error, 'screen.contacts.delete.error.title');
    }
  }
}
