import Vue from 'vue';

import WorkflowsTabBar from './WorkflowsTabBar.vue';
import WorkflowQuickActions from './WorkflowQuickActions.vue';
import WorkflowContactsQuickActions from './WorkflowContactsQuickActions.vue';
import WorkflowListItem from './WorkflowListItem.vue';
import WorkflowList from './WorkflowList.vue';
import ViewWorkflowTopTabBar from './ViewWorkflowTopTabBar.vue';
import WorkflowContacts from './WorkflowContacts.vue';
import CreateUpdateWorkflow from './CreateUpdateWorkflow.vue';
import WorkflowActions from './WorkflowActions.vue';
import WorkflowSummaryList from './WorkflowSummaryList.vue';
import WorkflowDocumentRequestListItem from './WorkflowDocumentRequestListItem.vue';
import WorkflowDocumentRequests from './WorkflowDocumentRequests.vue';
import WorkflowDocumentDetail from './WorkflowDocumentDetail.vue';
import WorkflowRenameModal from './WorkflowRenameModal.vue';

Vue.component('WorkflowsTabBar', WorkflowsTabBar);
Vue.component('WorkflowQuickActions', WorkflowQuickActions);
Vue.component('WorkflowContactsQuickActions', WorkflowContactsQuickActions);
Vue.component('WorkflowListItem', WorkflowListItem);
Vue.component('WorkflowList', WorkflowList);
Vue.component('ViewWorkflowTopTabBar', ViewWorkflowTopTabBar);
Vue.component('WorkflowContacts', WorkflowContacts);
Vue.component('CreateUpdateWorkflow', CreateUpdateWorkflow);
Vue.component('WorkflowActions', WorkflowActions);
Vue.component('WorkflowSummaryList', WorkflowSummaryList);
Vue.component('WorkflowDocumentRequestListItem', WorkflowDocumentRequestListItem);
Vue.component('WorkflowDocumentRequests', WorkflowDocumentRequests);
Vue.component('WorkflowDocumentDetail', WorkflowDocumentDetail);
Vue.component('WorkflowRenameModal', WorkflowRenameModal);
