
import { Component } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';

import {
  BreadcrumbItem,
  CreateUpdateTaskPayload,
} from '@/types';
import { actions } from '@/store';

@Component
export default class EditTask extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.workflows',
      path: '/workflows',
    },
    {
      title: '...',
    },
    {
      title: 'dashboard.menu.tasks',
      path: '/tasks',
    },
    {
      title: '...',
    },
    {
      title: 'Update Task',
    },
  ];

  formHandler(payload: CreateUpdateTaskPayload) {
    const {
      workflow, ...pLoad
    } = payload;
    this.$store.dispatch(actions.TASK_GROUP_UPDATE, {
      workflow, payload: pLoad, groupId: this.$route.params.id,
    })
      .then(() => {
        this.notify({
          message: this.$t('screen.tasks.update.success.message') as string,
        });
        this.$router.replace(`/tasks/${this.$route.params.id}`);
      })
      .catch((err) => {
        this.handleError(err, 'screen.tasks.update.error.title');
      });
  }

  async mounted() {
    try {
      const task = await this.$store.dispatch(actions.TASK_GET_BY_GROUP_ID, this.$route.params.id);
      this.breadcrumbs.splice(2, 1, {
        title: task.workflow.name,
        path: `/workflows/${task.workflow.id}`,
      });
      this.breadcrumbs.splice(4, 1, {
        title: task.action.name,
        path: `/tasks/${this.$route.params.id}`,
      });
      (this.$refs.createUpdateTask as any).setTask(task);
    } catch (err) {
      this.handleError(err, 'error.generic');
    }
  }
}
