
import { Component, Prop } from 'vue-property-decorator';
import { ValidatorFunction } from '@/types';
import BaseComponent from './base-component';

@Component
export default class CompanyType extends BaseComponent {
  @Prop() protected validate?: ValidatorFunction | Array<ValidatorFunction>;

  @Prop({ default: false }) disabled?: boolean;

  get loading() {
    return this.$store.state.business.loading;
  }

  get types() {
    return this.$store.getters['business/getTypes'];
  }

  async mounted() {
    if (!this.$store.getters['business/isTypesFetched']) {
      await this.$store.dispatch('business/getTypes');
    }
  }

  changeValue(value: string) {
    (this.$refs.companyType as any).changeValue(value);
  }
}
