
import { Component, Watch } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  BreadcrumbItem,
  BusinessRole,
  SortItem,
  BusinessUser,
} from '@/types';

const sortItems: SortItem[] = [
  {
    key: 'createdAt:ASC',
    label: 'Added',
    icon: 'clock-outline',
  },
  {
    key: 'createdAt:DESC',
    label: 'Recently Added',
    icon: 'clock-outline',
  },
  {
    key: 'name:ASC',
    label: 'Name',
    icon: 'sort-alphabetical-ascending',
  },
];

@Component
export default class Users extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.users',
    },
  ];

  sortItems = sortItems;

  selectedSort = sortItems[0];

  selectable = false;

  selected: string[] = [];

  roleFilter: BusinessRole | null = null;

  loading = true;

  get businessRoles() {
    return this.$store.getters['business/getRoles'];
  }

  get users() {
    return this.$store.getters['business/getUsers'];
  }

  get selectedBusiness() {
    return this.$store.getters['business/getSelectedBusiness'];
  }

  get activeUsers() {
    return this.users.filter((user: BusinessUser) => user.status === 'accepted' || user.status === 'pending');
  }

  get filteredUsers() {
    if (this.roleFilter) {
      return this.activeUsers.filter(
        (user: BusinessUser) => this.roleFilter && this.roleFilter.id === user.business_role.id,
      );
    }
    return this.activeUsers;
  }

  get sortedUsers() {
    const users = [...this.filteredUsers];
    if (this.selectedSort.key === 'name:ASC') {
      users.sort((a, b) => {
        const nameA = this.getUserName(a).toLowerCase();
        const nameB = this.getUserName(b).toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    } else if (this.selectedSort.key === 'createdAt:DESC') {
      users.sort((a, b) => {
        const timeA = new Date(a.createdAt).getTime();
        const timeB = new Date(b.createdAt).getTime();
        if (timeA > timeB) {
          return -1;
        }
        if (timeA < timeB) {
          return 1;
        }
        return 0;
      });
    } else if (this.selectedSort.key === 'createdAt:ASC') {
      users.sort((a, b) => {
        const timeA = new Date(a.createdAt).getTime();
        const timeB = new Date(b.createdAt).getTime();
        if (timeA < timeB) {
          return -1;
        }
        if (timeA > timeB) {
          return 1;
        }
        return 0;
      });
    }
    return users;
  }

  get isAdmin() {
    const me = this.users.find((user: BusinessUser) => user.status === 'accepted' && this.$store.state.auth.identity.id === user.user.id);
    if (!me) {
      return false;
    }
    return me.business_role.name === 'Administrator';
  }

  @Watch('selectedBusiness')
  async fetchBusinessUsers() {
    try {
      this.loading = true;
      await this.$store.dispatch('business/getUsers');
    } catch (err) {
      this.handleError(err as Error);
    } finally {
      this.loading = false;
    }
  }

  getUserById(id: string) {
    const sUser = this.users.find((user: BusinessUser) => user.id === id);
    if (!sUser) {
      return false;
    }
    return sUser;
  }

  getUserName = (user: BusinessUser) => {
    if (user.user) {
      return `${user.user.firstName} ${user.user.lastName}`;
    }
    if (user.invite_user) {
      return user.invite_user.email;
    }
    return '';
  };

  mounted() {
    this.$store.dispatch('business/getRoles');
    this.fetchBusinessUsers();
  }

  clearSelection() {
    this.selectable = false;
  }

  toggleSelectable(checked: boolean) {
    this.selectable = checked;
  }

  changeSort(item: SortItem) {
    this.selectedSort = item;
  }

  filterByRole(role: BusinessRole | null) {
    this.roleFilter = role;
  }

  onSelectedChange(selected: string[]) {
    this.selected = selected;
  }

  handleDelete() {
    if (this.selected.length === 1) {
      this.revokeUser(this.selected[0]);
      this.selectable = false;
      this.selected = [];
      return;
    }
    let message = 'Are you sure you want to delete these users?<br>';
    this.selected.forEach((userId: string) => {
      const user = this.getUserById(userId);
      if (user) {
        const name = this.getUserName(user);
        message += `<br><b>${name}</b>`;
      }
    });
    this.$buefy.dialog.confirm({
      title: 'Delete Users?',
      message,
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('yes') as string,
      onConfirm: async (value, dialog) => {
        try {
          this.loading = true;
          for (let i = 0; i < this.selected.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            await this.$store.dispatch('business/revokeUser', this.selected[i]);
          }
          await this.$store.dispatch('business/getUsers');
          this.$buefy.toast.open({
            message: 'Users Removed',
            position: 'is-bottom',
          });
          this.selectable = false;
          this.selected = [];
        } catch (err) {
          this.handleError(err as Error);
        } finally {
          this.loading = false;
          dialog.close();
        }
      },
    });
  }

  revokeUser(userId: string) {
    this.$buefy.dialog.confirm({
      title: 'Delete User?',
      message: 'Are you sure you want to delete this user?',
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('yes') as string,
      onConfirm: async (value, dialog) => {
        try {
          this.loading = true;
          await this.$store.dispatch('business/revokeUser', userId);
          await this.$store.dispatch('business/getUsers');
          this.$buefy.toast.open({
            message: 'User Removed',
            position: 'is-bottom',
          });
        } catch (err) {
          this.handleError(err as Error);
        } finally {
          this.loading = false;
          dialog.close();
        }
      },
    });
  }
}
