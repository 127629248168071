
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';

@Component
export default class Breadcrumb extends BaseComponent {
  @Prop({ default: () => [] }) breadcrumbs?: BreadcrumbItem[];

  get pBreadcrumbs() {
    return this.breadcrumbs;
  }
}
