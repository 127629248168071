import { ActionTree } from 'vuex';

import {
  ContactInviteRequest,
  CustomError,
  Module,
  WorkflowContact,
} from '@/types';
import {
  Workflow as api,
  FileManager as fileManagerApi,
} from '@/api';
import { RootState, WorkflowInviteState } from '../states';

const getDefaultState = (): WorkflowInviteState => ({
  data: null,
  loading: false,
  error: null,
});

const postRequest = async (context: any, data: ContactInviteRequest) => {
  const payload: ContactInviteRequest = {
    ...data,
    documents: [],
  };

  const documents = data.documents.filter((doc) => doc.selected);

  let uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];

  if (!uploadDocumentId) {
    await context.dispatch('actions/list', {}, { root: true });

    uploadDocumentId = context.rootGetters['actions/uploadDocumentId'];
  }

  documents.forEach((doc) => {
    const {
      id,
      selected,
      hasDueDate,
      dueDate,
      document: documentId,
      documentName,
      showError,
      errors,
      ...rest
    } = doc;
    const document: any = rest;

    if (!(id as string).startsWith('temp_')) {
      document.id = id;
    }

    const otherId = context.rootGetters['categories/otherId'];

    if (doc.category === otherId) {
      document.documentName = documentName;
    } else {
      document.document = documentId;
    }

    if (hasDueDate && dueDate) {
      document.dueDate = new Date(dueDate).toISOString();
    }

    if (!doc.action) {
      document.action = uploadDocumentId;
    }

    payload.documents.push(document);
  });

  // eslint-disable-next-line no-restricted-syntax
  for await (const document of payload.documents) {
    if (document.files) {
      const files = [];

      // eslint-disable-next-line no-restricted-syntax
      for await (const file of document.files) {
        const f = await fileManagerApi.fileUpload(
          `/${data.workflow}`,
          file,
        );

        files.push(f.data);
      }

      document.files = files;
    }
  }

  return payload;
};

const getters = {};

const actions: ActionTree<WorkflowInviteState, RootState> = {
  async invite(context: any, payload: ContactInviteRequest): Promise<string> {
    const { commit } = context;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const updatedPayload = await postRequest(context, payload);
        const resp = await api.invite(updatedPayload);
        const { data } = resp;

        commit('setLoading', false);
        commit('setData', data);
        commit('workflowContacts/setWorkflow', data, { root: true });

        resolve(data.id as string);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async sendLink({ commit }: any, payload: ContactInviteRequest): Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        await api.sendLink(payload);

        commit('setLoading', false);

        resolve();
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: WorkflowInviteState, loading: boolean) {
    state.loading = loading;
  },
  setData(state: WorkflowInviteState, data: WorkflowContact) {
    state.data = data.id as string;
  },
  setError(state: WorkflowInviteState, error: CustomError) {
    state.error = error;
  },
  reset(state: WorkflowInviteState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowInviteState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflowInvite: Module<WorkflowInviteState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
