const messages = {
  appName: 'Glide',
  brand: 'Techcurl',
  home: 'Home',
  about: 'About',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  signOut: 'Sign Out',
  companyName: 'Company Name',
  companyRegistrationID: 'Company Registration ID',
  companyType: 'Company type',
  companyTypeDesc: 'Company type description',
  companyAddress1: 'Address 1',
  companyAddress2: 'Address 2',
  companyCity: 'Town',
  companyState: 'County/State',
  companyCountry: 'Country',
  companyPostcode: 'Post code/Zip code',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  workEmail: 'Work email',
  country: 'Country',
  mobileNumber: 'Mobile number',
  contactNumber: 'Contact number',
  telephone: 'Telephone',
  password: 'Password',
  createPassword: 'Create password',
  confirmPassword: 'Confirm password',
  newPassword: 'New password',
  company: 'Company',
  designation: 'Designation',
  position: 'Position',
  tooShort: '{0} is too short',
  tooLong: '{0} is too long',
  qHavingTrouble: 'Having Trouble?',
  success: 'Success!',
  continue: 'Continue',
  completeAccountSetup: 'Complete Account Set Up',
  mobileVerificationCode: 'Mobile Verification Code',
  verify: 'Verify',
  resendCode: 'Resend Code',
  this: 'This',
  code: 'Code',
  retry: 'Retry',
  cancel: 'Cancel',
  resetPassword: 'Request a new one',
  qForgotPassword: 'Forgot Password?',
  allCompanies: 'All Companies',
  allContacts: 'All Contacts',
  edit: 'Edit',
  move: 'Move',
  delete: 'Delete',
  createContact: 'Create Contact',
  inviteToWorkflow: 'Invite to Workflow',
  createTask: 'Create Task',
  resendInvite: 'Resend Invite',
  sendMessage: 'Send Message',
  workflow: 'Workflow | Workflows',
  activity: 'Activity | Activities',
  inProgress: 'In-progress',
  completed: 'Completed',
  info: 'Info',
  createWorkflow: 'Create Workflow',
  task: 'Task | Tasks',
  message: 'Message | Messages',
  document: 'Document | Documents',
  connected: 'Connected',
  pending: 'Pending',
  myTasks: 'My tasks',
  assignedTask: 'Assigned tasks',
  assignDocument: 'Assign Document | Assign Documents',
  inviteContact: 'Invite Contact(s) | Invite a Contact | Invite Contacts',
  newMessage: 'New Message',
  editWorkflow: 'Edit Workflow',
  deleteWorkflow: 'Delete Workflow',
  invite: 'Invite',
  invited: 'Invited',
  setting: 'Setting | Settings',
  myActivity: 'My Activity',
  report: 'Report | Reports',
  support: 'Support',
  tags: 'Tags',
  form: {
    signUp: {
      heading: 'Glide free trial',
      error: {
        title: 'We couldn\'t sign you up.',
        passwordMismatched: '@:createPassword and @:confirmPassword do not match',
      },
    },
    signIn: {
      error: {
        title: 'We couldn\'t sign you in.',
        invalid: {
          message: 'The email and password you entered did not match our records. Please double-check and retry.',
        },
      },
    },
    registrationSuccess: {
      heading: 'Verify your email address',
      subHeading: 'Check your email',
      desc: 'We sent an email to <br><strong>{email}</strong>',
    },
    verifyEmail: {
      heading: 'Verify your email address',
      subHeading: 'Success!',
      desc: 'We have now verified <br><strong>{email}</strong>',
      error: 'It looks like you have already verified your email address.',
    },
    TwoFactorAuth: {
      heading: 'Verification Code',
      desc: 'We\'ve sent a code to {mobile}. It may take a few moments to arrive.',
      error: {
        createFailed: 'Oops! something went wrong while sending the code. Please try again',
        invalidCode: 'Verification code did not match',
      },
    },
    forgotPassword: {
      heading: 'Reset password',
      desc: 'Enter the email associated with your account and we\'ll send an email with instructions to reset your password.',
      submit: {
        label: 'Send Instructions',
      },
      link: {
        signIn: 'Back to Sign In',
      },
      error: {
        title: 'We couldn\'t reset your password.',
      },
    },
    forgotPasswordInstructions: {
      heading: 'Forgotten your password?',
      subHeading: 'Check your email',
      desc: 'We have sent password recovery instructions to your email.',
      error: 'Didn\'t receive the email? Check your spam filter or',
      link: {
        forgotPassword: 'Request another',
      },
    },
    resetPassword: {
      heading: 'Create new password',
      desc: 'Your new password must be different from previously used passwords.',
      submit: {
        label: 'Reset Password',
      },
      link: {
        signIn: 'Back to Sign In',
      },
      error: {
        title: 'We couldn\'t reset your password.',
      },
    },
    identityVerification: {
      heading: 'Verify Identity',
      alreadyComputed: 'Your identity verification is already completed.',
      success: {
        message: 'Your identity verification is completed successfully.',
      },
      inProgress: {
        message: 'Your identity verification is in progress.',
      },
      expired: {
        title: 'Identity verification session expired.',
        message: 'Your identity verification session is expired. Please try again.',
      },
      error: {
        title: 'We couldn\'t verify your identity.',
        message: 'Please email technical support on <a href="mailto:support@glidebridge.com">support@glidebridge.com</a>',
      },
    },
    subscription: {
      heading: 'Complete account set up',
      success: {
        message: 'Your identity verification is completed successfully.',
      },
      error: {
        title: 'We couldn\'t complete your subscription.',
      },
    },
    createContact: {
      heading: 'Create a new contact',
      error: {
        title: 'We couldn\'t create the contact.',
      },
    },
    updateContact: {
      error: {
        title: 'We couldn\'t update the contact.',
      },
    },
    workflowInvite: {
      heading: 'Invite contacts to workflow',
      empty: {
        message: 'We couldn\'t find any contacts based on the filter criteria.',
        filterNoRecord: 'There are no contacts in your directory base the filters. Please try resetting filter.',
      },
      error: {
        title: 'We couldn\'t invite your contact.',
        required: {
          message: 'Please select a workflow.',
        },
      },
      cancel: {
        confirmation: {
          title: 'Are you sure?',
          message: 'If you leave the page your changes will be lost.',
        },
      },
    },
    thirdParty: {
      document: {
        request: {
          payload: {
            empty: {
              message: 'Attach file(s) to send your documents.',
            },
            emptyRequest: {
              message: 'Select at least one document to the send request.',
            },
          },
          success: {
            message: 'Document request sent successfully.',
          },
          error: {
            title: 'We couldn\'t send the documents.',
          },
        },
        history: {
          payload: {
            empty: {
              message: '@:form.thirdParty.document.request.payload.empty.message',
            },
            emptyRequest: {
              message: '@:form.thirdParty.document.request.payload.emptyRequest.message',
            },
          },
          success: {
            message: '@:form.thirdParty.document.request.success.message',
          },
          error: {
            title: '@:form.thirdParty.document.request.error.title',
          },
        },
      },
      task: {
        status: {
          change: {
            error: {
              title: 'We couldn\'t update the task.',
            },
          },
        },
        comment: {
          post: {
            error: {
              title: 'We couldn\'t post comment.',
            },
          },
        },
      },
    },
    fileManager: {
      fileUpload: {
        maxSize: 'File size limit exceeded (Max: 20MB)',
      },
    },
  },
  fileManager: {
    create: {
      folder: {
        invalid: 'Folder already exist.',
      },
    },
  },
  onboarding: {
    steps: {
      step1: 'Company',
      step2: 'User',
      step3: 'Payment',
      step4: 'Verify identity',
    },
  },
  error: {
    default: 'Error!',
    oops: 'Oops!',
    generic: 'Oops, something went wrong!',
    auth: {
      invalid: 'Username/password was incorrect',
    },
    fileManager: {
      create: {
        folder: 'Folder creation failed.',
      },
    },
    workflow: {
      alreadyExists: 'Workflow with this name already exists.',
    },
  },
  required: {
    default: 'Required',
    field: '{0} is required',
  },
  validations: {
    nCharLong: '{field} must be {num} characters long',
  },
  dashboard: {
    menu: {
      dashboard: 'Dashboard',
      workflows: 'Workflows',
      documents: 'Documents',
      contacts: 'Contacts',
      users: 'Users',
      messages: 'Messages',
      tasks: 'Tasks',
      requests: 'Requests',
      settings: 'Settings',
      activity: 'My Activity',
      reports: 'Reports',
      support: 'Support',
    },
  },
  screen: {
    contacts: {
      title: 'Contacts',
      empty: {
        message: 'There are no contacts in your directory. Please add a new contact.',
        filterNoRecord: 'There are no contacts in your directory base the filters. Please try resetting filter.',
      },
      delete: {
        title: 'Delete Contact?',
        message: 'Are you sure you want to delete this contact? | Are you sure you want to delete these contacts?',
        error: {
          title: 'We couldn\'t delete the contact(s)',
        },
        deleting: {
          message: 'Deleting contact...',
        },
        success: {
          message: 'Contact(s) deleted successfully.',
        },
      },
      workflow: {
        empty: {
          message: 'There are no workflows assigned to this contact. Please <router-link :to="{ name: \'WorkflowInvite\', query: { workflows: 1 } }"> invite a contact</router-link>.',
          filterNoRecord: 'There are no workflows assigned to this contact base the filters. Please try resetting filter.',
        },
      },
    },
    tasks: {
      title: 'Tasks',
      empty: {
        message: 'There are no tasks assigned to this workflow. Please <router-link :to="{ name: \'CreateTask\' }">create a new task</router-link>.',
        filterNoRecord: 'There are no tasks in your directory base the filters. Please try resetting filter.',
      },
      me: {
        empty: {
          message: 'You don\'t have any assigned tasks. Please <router-link :to="{ name: \'CreateTask\' }">create a new task</router-link> for your workflow.',
        },
      },
      assigned: {
        empty: {
          message: 'You haven\'t assigned any tasks to your workflow contact(s). Please <router-link :to="{ name: \'CreateTask\' }">create a new task</router-link> for your workflow.',
        },
      },
      delete: {
        title: 'Delete Task?',
        message: 'Are you sure you want to delete this task? | Are you sure you want to delete these tasks?',
        error: {
          title: 'We couldn\'t delete the task(s)',
        },
        success: {
          message: 'Task(s) deleted successfully.',
        },
      },
      create: {
        title: 'Create Task',
        error: {
          title: 'We couldn\'t create the task',
        },
        success: {
          message: 'Task created successfully.',
        },
      },
      update: {
        title: 'Update Task',
        error: {
          title: 'We couldn\'t update the task',
        },
        success: {
          message: 'Task updated successfully.',
        },
      },
    },
    workflows: {
      title: 'Contacts',
      empty: {
        message: 'There are no workflows. Please <router-link :to="{ name: \'CreateWorkflow\' }">create a workflow</router-link>.',
        filterNoRecord: 'There are no workflows base the filters. Please try resetting filter.',
      },
      completed: {
        empty: {
          message: 'Your completed workflows will appear here.',
        },
      },
      delete: {
        title: 'Delete Workflow?',
        message: 'Are you sure you want to delete this workflow? | Are you sure you want to delete these workflows?',
        singleDelete: 'Are you sure you want to delete workflow <b>{workflowName}</b>? This will remove all tasks and files within this workflow.',
        deleting: 'Deleting workflow...',
        error: {
          title: 'We couldn\'t delete the workflow(s)',
        },
        success: {
          message: 'Workflow(s) deleted successfully.',
        },
      },
      create: {
        name: 'Workflow name',
        template: 'Workflow template',
        error: {
          title: 'We couldn\'t create the workflow.',
        },
      },
      upgradePlan: {
        error: {
          title: 'Upgrade your plan.',
        },
      },
      rename: {
        name: 'Edit Workflow',
        error: {
          title: 'We couldn\'t update the workflow.',
          required: 'Workflow Name is required',
          maxLength: 'Workflow Name cannot be longer than 120 characters',
        },
        success: {
          message: 'Workflow updated successfully.',
        },
      },
      contact: {
        empty: {
          message: 'There are no contacts assigned to this workflow. Please <router-link :to="{ name: \'WorkflowInvite\', query: { workflows: 1 } }"> invite a contact</router-link>.',
          filterNoRecord: 'There are no contacts assigned to this workflow base the filters. Please try resetting filter.',
          connectedContacts: 'None of the contacts invited to the workflow have accepted the invitation.',
          pendingContacts: 'There are no pending invitations for this workflow.',
        },
        inviteSent: 'Invite sent successfully.',
        idvEnabled: 'Identity verification has been enabled.',
        idvDisabled: 'Identity verification has been disabled.',
      },
      task: {
        empty: {
          message: 'There are no tasks for this workflow.',
        },
        me: {
          empty: {
            message: 'You don\'t have any assigned tasks for this workflow. Please <router-link :to="{ name: \'CreateTask\', query: { workflow: this.$route.params.id } }">create a new task</router-link>.',
          },
        },
        assigned: {
          empty: {
            message: 'You haven\'t assigned any tasks to your workflow contact(s). Please <router-link :to="{ name: \'CreateTask\', query: { workflow: this.$route.params.id } }">create a new task</router-link> for your workflow.',
          },
        },
        completed: {
          empty: {
            message: 'There are no completed tasks for this workflow.',
          },
        },
      },
      invite: {
        title: 'Invite contacts to workflow',
      },
      document: {
        eSign: {
          error: {
            title: 'We couldn\'t eSign your document',
          },
        },
        request: {
          save: {
            error: {
              title: 'We couldn\'t save document request',
            },
            empty: {
              message: 'There are no documents selected to save.',
            },
            success: {
              message: 'Documents have been sent to your contact(s)',
            },
          },
          send: {
            error: {
              title: 'We couldn\'t send document(s)',
            },
          },
        },
      },
    },
    tags: {
      error: {
        size: 'Tag cannot exceed 30 characters.',
      },
    },
    users: {
      title: 'Users',
      invite: 'Invite user(s) to join your company account',
      actions: {
        invite: 'Invite User(s)',
      },
    },
  },
  requests: {
    document: {
      title: 'Document request',
    },
    history: {
      title: 'Request history',
    },
    new: {
      title: 'New document request',
      error: {
        title: 'We couldn\'t send the request',
      },
      success: {
        message: 'New document request sent successfully.',
      },
    },
  },

  // overrides
  request: {
    create: {
      failed: 'Sending request failed. Please try again.',
    },
  },
  Auth: {
    form: {
      error: {
        confirmed: 'Your account email is not confirmed',
        invalid: 'The email and password you entered did not match our records. Please double-check and retry.',
        email: {
          taken: 'This email is already taken',
        },
      },
    },
  },
  user: {
    mobile: {
      taken: 'This mobile number is already taken',
    },
  },
  contact: {
    label: 'Contact | Contacts',
    exist: 'Contact already exists with same email or phone number',
    exists: '@:contact.exist',
  },
  update: {
    task: {
      status: {
        by: {
          '3p': {
            failed: 'Task status update failed',
          },
        },
      },
      by: {
        '3p': {
          failed: 'Task update failed',
        },
      },
    },
  },
  thirdParty: {
    workflow: {
      invalid: 'This workflow is either invalid or deleted.',
    },
  },
  basic: {
    subscription: 'Starter account only allows 2 workflows. You can either delete previous workflows or <a href= "/subscription?upgrade=full">Upgrade your account</a> to add more.',
  },
};

export default messages;
