import { AxiosResponse } from 'axios';

import {
  SubscriptionPrice,
  SubscriptionCreateSessionRequestPayload,
  SubscriptionCreateSessionResponse,
  Subscription,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static prices(): Promise<AxiosResponse<SubscriptionPrice[]>> {
    return jwtApi.get<SubscriptionPrice[]>('/payment-gateway/prices');
  }

  static createSession(payload: SubscriptionCreateSessionRequestPayload):
    Promise<AxiosResponse<SubscriptionCreateSessionResponse>> {
    return jwtApi.post<SubscriptionCreateSessionResponse>('/payment-gateway/sessions', payload);
  }

  static createBillingSession(businessId?: string):
    Promise<AxiosResponse<SubscriptionCreateSessionResponse>> {
    const headers: any = {};
    if (businessId) {
      headers.business = businessId;
    } else {
      headers.business = '';
    }

    return jwtApi.post<SubscriptionCreateSessionResponse>('/payment-gateway/sessions/billing', {}, {
      headers,
    });
  }

  static subscription():
    Promise<AxiosResponse<Subscription>> {
    return jwtApi.get<Subscription>('/payment-gateway/subscription');
  }
}
