import { AxiosResponse } from 'axios';

import {
  User,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static me(): Promise<AxiosResponse<User>> {
    return jwtApi.get<User>('/users/me');
  }

  static deleteUser(): Promise<AxiosResponse> {
    return jwtApi.post('/users/remove');
  }

  static myActivity(): Promise<AxiosResponse> {
    return jwtApi.get('/audit-trail/audits');
  }
}
