
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { actions } from '@/store';
import { Subscription } from '@/types';

@Component
export default class CompanyList extends BaseComponent {
  subscription?: Subscription;

  created() {
    this.loadSubscriptions();
  }

  async loadSubscriptions() {
    this.subscription = await this.$store.dispatch(actions.PAYMENT_SUBSCRIPTION);
  }
}
