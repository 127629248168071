
import { Component, Watch } from 'vue-property-decorator';
import { actions } from '@/store';
import { Contact, Tag, Workflow } from '@/types';
import { debounce } from '@/utils';
import BaseComponent from './base-component';

const searchDebounce = debounce(500);

@Component
export default class SearchResult extends BaseComponent {
  tab = 'search';

  searchTerm = '';

  changeTab(tab: string) {
    this.selectedTagId = '';
    this.searchTerm = '';
    this.tab = tab;
  }

  get tags() {
    return this.$store.state.tags.all;
  }

  selectedTagId = '';

  selectTag(tag: Tag) {
    this.selectedTagId = tag.id || '';
  }

  @Watch('searchTerm')
  search() {
    if (this.searchTerm) {
      this.$store.dispatch('search/search', this.searchTerm);
    } else {
      this.$store.commit('search/reset');
    }
  }

  onSearchChange(e: InputEvent) {
    searchDebounce(() => {
      this.searchTerm = (e.target as HTMLInputElement).value.trim();
    });
  }

  searchFromRecent(query: string) {
    this.searchTerm = query;
  }

  removeSearch(index: number) {
    this.$store.commit('search/removeQuery', index);
  }

  get documentCount() {
    const count = this.$store.state.search.data.documents.entries.length;
    const ext = this.$store.state.search.data.documents.hasMore ? '+' : '';
    return `${count}${ext}`;
  }

  get tasksCount() {
    return this.$store.state.search.data.tasks.pagination.total;
  }

  get contactsCount() {
    return this.$store.state.search.data.contacts.pagination.total;
  }

  get workflowsCount() {
    return this.$store.state.search.data.workflows.pagination.total;
  }

  async mounted() {
    (this.$refs.searchInput as any).focus();
    await this.$store.dispatch(actions.TAGS_GET_ALL);
  }

  selectedWorkflowId = '';

  onWorkflowActionClick(workflow: Workflow, action: string) {
    switch (action) {
      case 'edit':
        if (workflow.id) {
          this.selectedWorkflowId = workflow.id;
          (this.$refs.workflowRenameModal as any).openModal(workflow.name);
        }
        break;
      case 'delete':
        this.onClickWorkflowDelete(workflow);
        break;
      default:
        this.$emit('onActionClick', workflow, action);
    }
  }

  handleWorkflowRename(workflowName: string) {
    if (this.selectedWorkflowId) {
      const payload = {
        id: this.selectedWorkflowId,
        name: workflowName,
      };
      this.$store.dispatch(actions.WORKFLOW_UPDATE, payload).then(() => {
        this.notify({
          message: this.$t('screen.workflows.rename.success.message') as string,
          type: 'is-success',
        });
        this.$store.commit('search/renameWorkflow', payload);
        (this.$refs.workflowRenameModal as any).closeModal();
      }).catch((err) => {
        this.handleError(err, this.$t('screen.workflows.rename.error.title') as string);
      });
    }
  }

  onClickWorkflowDelete(workflow: Workflow) {
    this.$buefy.dialog.confirm({
      title: this.$t('screen.workflows.delete.title') as string,
      message: (this.$t('screen.workflows.delete.singleDelete') as string)
        .replace('{workflowName}', workflow.name),
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('delete') as string,
      onConfirm: () => {
        this.$buefy.toast.open({
          message: this.$t('screen.workflows.delete.deleting') as string,
          position: 'is-bottom',
        });
        this.handleWorkflowDelete(workflow);
      },
    });
  }

  async handleWorkflowDelete(workflow: Workflow) {
    try {
      await this.$store.dispatch(actions.WORKFLOW_DELETE, workflow.id);

      this.notify({
        message: this.$t('screen.workflows.delete.success.message') as string,
      });

      this.$store.commit('search/deleteWorkflow', workflow.id);
    } catch (error) {
      this.handleError(error, 'screen.workflows.delete.error.title');
    }
  }

  onContactsActionClick(contact: Contact, action: string) {
    switch (action) {
      case 'invite-to-workflow':
        this.$router.push({ name: 'WorkflowInvite', query: { contacts: contact.id as string } });
        break;
      case 'create-task':
        this.$router.push({ name: 'CreateTask', query: { contacts: contact.id as string } });
        break;
      case 'edit':
        this.$router.push({ name: 'EditContact', params: { id: contact.id as string } });
        break;
      case 'delete':
        this.onClickContactDelete(contact);
        break;
      default:
      // do nothing
    }
  }

  onClickContactDelete(contact: Contact) {
    this.$buefy.dialog.confirm({
      title: this.$t('screen.contacts.delete.title') as string,
      message: this.$tc('screen.contacts.delete.message', 1) as string,
      cancelText: this.$t('cancel') as string,
      confirmText: this.$t('yes') as string,
      onConfirm: (value, dialog) => {
        this.$buefy.toast.open({
          message: this.$t('screen.contacts.delete.deleting.message') as string,
          position: 'is-bottom',
        });
        this.handleContactDelete(contact);
        dialog.close();
      },
    });
  }

  async handleContactDelete(contact: Contact) {
    try {
      await this.$store.dispatch(actions.CONTACT_DELETE, contact.id);

      this.notify({
        message: this.$t('screen.contacts.delete.success.message') as string,
      });

      this.$store.commit('search/deleteContact', contact.id);
    } catch (error) {
      this.handleError(error, 'screen.contacts.delete.error.title');
    }
  }

  get hasMore() {
    return this.$store.state.fileManager.hasMore;
  }

  onClickViewMore() {
    this.$router.push({ name: 'FileManagerSearch', query: { q: this.searchTerm } });
  }
}
