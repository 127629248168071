import { AxiosResponse } from 'axios';

import {
  Task,
  PaginatedResult,
  Query,
  CreateUpdateTaskPayload,
  File,
} from '@/types';

import {
  jwtApi,
} from './api_base';
import { buildQuery, buildSearchQuery } from './utils';

import fileManager from './file-manager';

export default class {
  static search(query: Query): Promise<AxiosResponse<PaginatedResult<Task>>> {
    return jwtApi.get<PaginatedResult<Task>>(`/tasks${buildSearchQuery(query)}`);
  }

  static getById(id: string): Promise<AxiosResponse<Task>> {
    return jwtApi.get<Task>(`/tasks/${id}`);
  }

  static async create(payload: CreateUpdateTaskPayload): Promise<AxiosResponse<Task>> {
    const promises: Promise<AxiosResponse<File>>[] = [];

    if (payload.files && payload.files.length) {
      payload.files.forEach((file) => {
        const p = fileManager.fileUpload(`/${payload.workflow}`, file);
        promises.push(p);
      });
    }
    const responses = await Promise.all(promises);
    const files = responses.map((resp) => resp.data);
    return jwtApi.post<Task>('/tasks', {
      ...payload,
      files,
    });
  }

  static getAssignedTask(query: Query): Promise<AxiosResponse<PaginatedResult<Task>>> {
    return jwtApi.get<PaginatedResult<Task>>(`/tasks/group${buildSearchQuery(query)}`);
  }

  static getByGroupId(id: string): Promise<AxiosResponse<Task>> {
    return jwtApi.get<Task>(`/tasks/group/${id}`);
  }

  static async updateTaskByGroup(data: any): Promise<AxiosResponse> {
    const { groupId, payload, workflow } = data;
    const promises: Promise<AxiosResponse>[] = [];

    if (payload.files && payload.files.length) {
      payload.files.forEach((file: File) => {
        const p = fileManager.fileUpload(`/${workflow}`, file);
        promises.push(p);
      });
    }
    const responses = await Promise.all(promises);
    const files = responses.map((resp) => resp.data);
    return jwtApi.put(`/tasks/group/${groupId}`, {
      ...payload,
      files,
    });
  }

  static setGroupStatus(id: string, payload: any): Promise<AxiosResponse> {
    return jwtApi.put(`/tasks/group/${id}/status`, payload);
  }

  static sendReminder(id: string): Promise<AxiosResponse> {
    return jwtApi.post(`/tasks/group/${id}/remind`);
  }

  static getCommentsByGroupId(id: string): Promise<AxiosResponse> {
    return jwtApi.get(`/tasks/group/${id}/comments`);
  }
}
