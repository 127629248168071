
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import { BreadcrumbItem } from '@/types';
import storage from '@/storage';

@Component
export default class Dashboard extends BaseComponent {
  hasTitleBar = false;

  expanded = false;

  toggleTab = false;

  isImageModalActive = false;

  isPaymentSuccess = true;

  title = '';

  showWelcome = true;

  openWelcome() {
    this.showWelcome = true;
    storage.showWelcome = true;
  }

  closeWelcome() {
    this.showWelcome = false;
    storage.showWelcome = false;
  }

  toggleSidebarMenu() {
    this.expanded = !this.expanded;
  }

  toggleTabs() {
    this.toggleTab = !this.toggleTab;
  }

  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '',
    },
  ];

  actionHandler(action: string) {
    if (action === 'create-folder') {
      (this.$refs.fileActions as any).onClickCreateMode();
    } else if (action === 'file-upload') {
      (this.$refs.fileActions as any).onClickUploadOnlyMode();
    }
  }

  mounted() {
    this.showWelcome = storage.showWelcome ?? true;
    if (this.$route.query.payment === 'success') {
      this.$buefy.dialog.alert({
        title: 'Payment success',
        message: 'We have received your payment. Your subscription has started.',
        type: 'is-success',
        confirmText: 'Done',
        canCancel: false,
        onConfirm: () => {
          this.$router.push({
            name: 'Dashboard',
          });
        },
      });
    }
  }
}
