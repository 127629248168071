// eslint-disable-next-line import/prefer-default-export
import { IdentityVerification } from '@/types';

export const identityVerificationStatus = Object.freeze({
  isVerified: (onfido: IdentityVerification) => {
    if (process.env.VUE_APP_ENABLE_IDENTITY_VERIFICATION === 'true') {
      return onfido?.status === 'verified' || (onfido?.status === 'complete' && onfido?.result === 'clear');
    }
    return true;
  },
});

export const salt = Object.freeze({
  STORAGE: process.env.VUE_APP_STORAGE_SALT,
});

export const mainMenu = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
    icon: 'home-outline',
  },
  {
    title: 'dashboard.menu.workflows',
    path: '/workflows',
    icon: 'call-merge',
  },
  {
    title: 'dashboard.menu.documents',
    path: '/file-manager',
    icon: 'file-outline',
  },
  {
    title: 'dashboard.menu.contacts',
    path: '/contacts',
    icon: 'account-supervisor',
  },
  {
    title: 'dashboard.menu.users',
    path: '/users',
    icon: 'account-plus',
  },
  /*
  {
    title: 'dashboard.menu.messages',
    path: '/messages',
    icon: 'forum-outline',
  },
  */
  {
    title: 'dashboard.menu.tasks',
    path: '/tasks',
    icon: 'clipboard-clock-outline',
  },
  /*
  {
    title: 'dashboard.menu.reports',
    path: '/reports',
    icon: 'chart-box-outline',
  },
  */
  {
    title: 'dashboard.menu.settings',
    path: '/settings',
    icon: 'cog-outline',
  },
  {
    title: 'dashboard.menu.support',
    path: '/support',
    icon: 'lifebuoy',
  },
];
