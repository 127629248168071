import { ActionTree } from 'vuex';

import {
  Contact,
  CustomError,
  Module,
  PaginatedResult,
  WorkflowContact,
} from '@/types';
import { Workflow as api } from '@/api';
import { RootState, WorkflowContactsState } from '../states';

const getDefaultState = (): WorkflowContactsState => ({
  data: {},
  ids: {},
  loading: false,
  error: null,
});

const getters = {
  getById: (state: WorkflowContactsState) => (id: string) => state.data[id],
  getByWorkflow: (state: WorkflowContactsState) => (id: string) => (status: string) => {
    if (state.ids[id] && state.ids[id][status]) {
      return state.ids[id][status];
    }

    return {
      pagination: {
        page: 0,
        pageSize: 10,
      },
      results: [],
    };
  },
  getByContact: (state: WorkflowContactsState) => (id: string) => {
    let workflowContact;

    // TODO: optimize the statement
    Object.keys(state.data).forEach((key) => {
      if (state.data[key]
        && (state.data[key].contact === id || (state.data[key].contact as Contact).id === id)) {
        workflowContact = state.data[key];
      }
    });

    return workflowContact;
  },
};

const actions: ActionTree<WorkflowContactsState, RootState> = {
  async search({ commit }: any, { id, status, query }: any): Promise<PaginatedResult<string>> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<PaginatedResult<string>>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const updateQuery = {
          ...query,
          filters: {
            ...query.filters,
          },
        };

        if (status) {
          updateQuery.filters.status = status;
        }

        const resp = await api.contacts(id, updateQuery);
        const { data } = resp;

        const result = {
          ...data,
          results: data.results.map((wc) => wc.id as string),
        };

        commit('setLoading', false);
        commit('setWorkflows', { id, status: status || 'all', data });
        commit('contactList/setContacts', data.results.map((workflow) => workflow.contact), { root: true });

        resolve(result);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: WorkflowContactsState, loading: boolean) {
    state.loading = loading;
  },
  setWorkflows(state: WorkflowContactsState, { id, status, data }: any) {
    state.ids[id] = state.ids[id] || {};
    state.ids[id][status] = {
      ...data,
      results: data.results.map((wc: WorkflowContact) => wc.id),
    };

    const workflowContacts: { [key: string]: WorkflowContact } = {};

    data.results.forEach((wc: WorkflowContact) => {
      workflowContacts[wc.id as string] = {
        ...wc,
        workflow: (wc.workflow as any).id || wc.workflow,
        contact: (wc.contact as any).id || wc.contact,
      };
    });

    state.data = {
      ...state.data,
      ...workflowContacts,
    };

    data.results.forEach((wc: WorkflowContact) => {
      state.data[wc.id as string] = wc;
    });
  },
  setWorkflow(state: WorkflowContactsState, data: WorkflowContact) {
    const id = data.workflow as string;
    const status = 'all';

    state.ids[id] = state.ids[id] || {};
    const wcs = state.ids[id][status] || {
      pagination: {},
      results: [],
    };

    state.ids[id][status] = {
      pagination: wcs.pagination,
      results: wcs.results
        .includes(data.id as string) ? wcs.results : [...wcs.results, data.id as string],
    };

    state.ids = { ...state.ids };

    state.data = {
      ...state.data,
      [data.id as string]: data,
    };
  },
  setError(state: WorkflowContactsState, error: CustomError) {
    state.error = error;
  },
  reset(state: WorkflowContactsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowContactsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflowContacts: Module<WorkflowContactsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
