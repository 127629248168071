import { ActionTree } from 'vuex';

import {
  CustomError,
  emptyPaginatedResult,
  Module,
  SearchResults,
} from '@/types';
import { Search as api } from '@/api';
import storage from '@/storage';
import { RootState, SearchState } from '../states';

const getDefaultState = (): SearchState => ({
  queries: storage.localStorage.recentSearches,
  data: {
    workflows: emptyPaginatedResult(),
    tasks: emptyPaginatedResult(),
    contacts: emptyPaginatedResult(),
    documents: {
      entries: [],
      cursor: '',
      hasMore: false,
    },
  },
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<SearchState, RootState> = {
  async search({ commit }: any, query: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<SearchResults | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query);
        const { data } = resp;

        commit('setLoading', false);
        commit('setResults', data);
        commit('fileManager/setFiles', data.documents, { root: true });
        commit('files/setFiles', data.documents.entries, { root: true });
        commit('addQuery', query);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: SearchState, loading: boolean) {
    state.loading = loading;
  },
  setResults(state: SearchState, results: SearchResults) {
    state.data = results;
  },
  setError(state: SearchState, error: CustomError) {
    state.error = error;
  },
  reset(state: SearchState) {
    Object.assign(state, getDefaultState());
  },
  deleteContact(state: SearchState, id: string) {
    state.data.contacts.results = state.data.contacts.results.filter(
      (contact) => contact.id !== id,
    );
    state.data.contacts.pagination.total = (state.data.contacts.pagination.total || 1) - 1;
  },
  renameWorkflow(state: SearchState, { id, name }: any) {
    const index = state.data.workflows.results.findIndex(
      (workflow) => workflow.id === id,
    );
    if (index > -1) {
      state.data.workflows.results[index].name = name;
    }
  },
  deleteWorkflow(state: SearchState, id: string) {
    state.data.workflows.results = state.data.workflows.results.filter(
      (workflow) => workflow.id !== id,
    );
    state.data.workflows.pagination.total = (state.data.workflows.pagination.total || 1) - 1;
  },
  addQuery(state: SearchState, query: string) {
    const index = state.queries.indexOf(query);
    if (index > -1) {
      state.queries.splice(index, 1);
    }
    if (state.queries.length >= 5) {
      state.queries.pop();
    }
    state.queries.unshift(query);
    storage.localStorage.recentSearches = state.queries;
  },
  removeQuery(state: SearchState, index: number) {
    if (index > -1) {
      state.queries.splice(index, 1);
    }
    storage.localStorage.recentSearches = state.queries;
  },
};

const state = (): SearchState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const search: Module<SearchState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
