
import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import { Country as CountryType, ValidatorFunction } from '@/types';

import BaseComponent from './base-component';

@Component
export default class Country extends BaseComponent {
  @Prop() protected validate?: ValidatorFunction | Array<ValidatorFunction>;

  @Prop({ default: 'country' }) name?: string;

  @Prop({ default: 'country' }) label?: string;

  @Prop({ default: false }) showName?: string;

  @Prop({ default: false }) disabled?: string;

  get loading() {
    return this.$store.state.country.loading;
  }

  async mounted() {
    if (!this.$store.getters['country/isFetched']) {
      await this.$store.dispatch(actions.COUNTRY_GET_ALL);
    }
    if (!(this.$refs.country as any)?.getValue()) {
      const britain = this.$store.state.country.all.find((country: CountryType) => country.code === 'GB');
      this.changeValue(britain);
    }
  }

  // async loadData() {
  //   if (!this.$store.getters['country/isFetched']) {
  //     await this.$store.dispatch(actions.COUNTRY_GET_ALL);
  //   }
  //   const countries = this.$store.getters['country/getCountries'];
  //
  //   const defaultSelected = countries.find((country: CountryType) => country.code === 'GB');
  //
  //   if (defaultSelected) {
  //     (this.$refs.country as any).changeValue(defaultSelected, null);
  //   }
  // }

  changeValue(value: any) {
    (this.$refs.country as any).changeValue(value);
  }
}
