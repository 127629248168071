import { AxiosResponse } from 'axios';

import {
  RegistrationRequest,
  AuthRequest,
  AuthResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '@/types';
import { api } from './api_base';

export default class Auth {
  static register(payload: RegistrationRequest): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/auth/local/register', payload);
  }

  static authenticate(payload: AuthRequest): Promise<AxiosResponse<AuthResponse>> {
    return api.post<AuthResponse>('/auth/local', payload);
  }

  static verifyEmail(token: string): Promise<AxiosResponse<AuthResponse>> {
    return api.get<AuthResponse>(`/auth/email-confirmation?confirmation=${token}`);
  }

  static forgotPassword(payload: ForgotPasswordRequest):
    Promise<AxiosResponse<ForgotPasswordResponse>> {
    return api.post<ForgotPasswordResponse>('/auth/forgot-password', payload);
  }

  static resetPassword(payload: ResetPasswordRequest):
    Promise<AxiosResponse<ResetPasswordResponse>> {
    return api.post<ResetPasswordResponse>('/auth/reset-password', payload);
  }
}
