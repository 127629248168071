import Vue from 'vue';
import {
  CustomError,
  Module,
  File,
} from '@/types';
import { RootState, FilesState } from '../states';

const getDefaultState = (): FilesState => ({
  data: {},
});

const getters = {};

const actions = {};

const mutations = {
  setLoading(state: FilesState, loading: boolean) {
    state.loading = loading;
  },
  setFiles(state: FilesState, files: File[]) {
    files.forEach((file) => {
      state.data[file.id as string] = file;
    });
  },
  setFile(state: FilesState, file: File) {
    Vue.set(state.data, file.id, file);
  },
  deleteFiles(state: FilesState, ids: string[]) {
    ids.forEach((id) => {
      Vue.delete(state.data, id);
    });
  },
  setError(state: FilesState, error: CustomError) {
    state.error = error;
  },
  reset(state: FilesState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): FilesState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const files: Module<FilesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
