
import { Component } from 'vue-property-decorator';

import { actions } from '@/store';
import BaseComponent from '../base-component';

@Component
export default class OnboardingHeader extends BaseComponent {
  async signOut() {
    await this.$store.dispatch(actions.SIGN_OUT);
    await this.$router.replace({ name: 'Home' });
  }
}
