
import { Component } from 'vue-property-decorator';
import ms from 'ms';
import BaseComponent from '@/components/base-component';

import storage from '@/storage';
import { actions } from '@/store';

const expiryThreshold = ms(process.env.VUE_APP_IDLE_TIME_BEFORE_SESSION_EXPIRY || '30m');

@Component({
})
export default class DefaultLayout extends BaseComponent {
  sessionExpiryHandler: any = 0;

  created() {
    this.checkSessionExpiry();
    this.scheduleSessionExpire();
  }

  scheduleSessionExpire() {
    this.sessionExpiryHandler = setInterval(() => {
      this.expireSession();
    }, expiryThreshold);
  }

  checkSessionExpiry() {
    const { lastActiveAt } = storage;

    if (lastActiveAt) {
      if (Date.now() > lastActiveAt + expiryThreshold) {
        this.expireSession();
      }
    }
  }

  async expireSession() {
    const { user } = storage;
    if (user) {
      await this.$store.dispatch(actions.SIGN_OUT);
    }
  }

  beforeDestroy() {
    clearInterval(this.sessionExpiryHandler);
  }
}
