import Vue from 'vue';
import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  File,
  FileResponse,
  FileRenameRequest,
  FileDeleteRequest,
  PaginatedResult,
} from '@/types';
import { FileManager as api } from '@/api';
import { RootState, FileManagerState } from '../states';

const getDefaultState = (): FileManagerState => ({
  list: [],
  currentDir: undefined,
  cursor: '',
  hasMore: false,
  loading: false,
  error: null,
  saving: false,
});

const getters = {};

const actions: ActionTree<FileManagerState, RootState> = {
  async getList({ commit }: any, payload: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getList(payload || '');
        const { data } = resp;

        commit('setLoading', false);
        commit('setFiles', data);
        commit('files/setFiles', data.entries, { root: true });
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async getRecent({ commit }: any, payload: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getRecent();
        const { data } = resp;

        commit('setLoading', false);
        commit('setFileIds', data);
        commit('files/setFiles', data, { root: true });
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async getDetails({ commit }: any, payload: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<File | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getDetails(payload);
        const { data } = resp;

        commit('setLoading', false);
        commit('setCurrentDir', data);
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async addFolder({ commit }: any, folderPath: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<File | any>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        const resp = await api.addFolder(folderPath);
        const { data } = resp;
        commit('addFileOrFolder', data.id as string);
        commit('files/setFile', data, { root: true });
        resolve(data);
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setSaving', false);
      }
    });
  },
  async uploadFile({ commit }: any, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        const resp = await api.fileUpload(payload.path, payload.files);
        const { data } = resp;
        commit('addFileOrFolder', data.id);
        commit('files/setFile', data, { root: true });
        resolve(data);
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setSaving', false);
      }
    });
  },
  async addTags({ commit }: any, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        const resp = await api.addTag(payload);
        const { data } = resp;

        resolve(data);
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setSaving', false);
      }
    });
  },
  async getTags({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getTags(id);
        const { data } = resp;

        commit('setLoading', false);
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async rename({ commit }: any, payload: FileRenameRequest) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        const resp = await api.rename(payload);
        const { data } = resp;
        commit('files/setFile', data, { root: true });
        resolve(data);
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setSaving', false);
      }
    });
  },
  async delete({ commit }: any, { payload, ids }: { payload: FileDeleteRequest; ids: string[] }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        await api.delete(payload);

        commit('deleteFiles', ids);
        commit('files/deleteFiles', ids, { root: true });

        resolve();
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setSaving', false);
      }
    });
  },
  async getFilesByTagId({ commit }: any, { id, query }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<PaginatedResult<File> | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getFilesByTagId(id, query);
        const { data } = resp;

        commit('setLoading', false);
        commit('setFileIds', data.results);
        commit('files/setFiles', data.results, { root: true });
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async search({ commit, state }: any, query: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<FileResponse | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query, state.cursor);
        const { data } = resp;

        commit('setLoading', false);
        commit('appendFiles', data);
        commit('files/setFiles', data.entries, { root: true });
        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: FileManagerState, loading: boolean) {
    state.loading = loading;
  },
  setSaving(state: FileManagerState, saving: boolean) {
    state.saving = saving;
  },
  setFileIds(state: FileManagerState, files: File[]) {
    state.list = files.map((file) => file.id as string);
  },
  appendFiles(state: FileManagerState, response: FileResponse) {
    response.entries.forEach((file) => {
      if (state.list.indexOf(file.id) === -1) {
        state.list.push(file.id);
      }
    });
    state.cursor = response.cursor;
    state.hasMore = response.hasMore;
  },
  setFiles(state: FileManagerState, response: FileResponse) {
    state.list = response.entries.map((file) => file.id as string);
    state.cursor = response.cursor;
    state.hasMore = response.hasMore;
  },
  addFileOrFolder(state: FileManagerState, id: string) {
    if (!state.list.includes(id)) {
      state.list.push(id);
    }
  },
  setCurrentDir(state: FileManagerState, currentDir: File) {
    state.currentDir = currentDir;
  },
  deleteFiles(state: FileManagerState, ids: string[]) {
    ids.forEach((id) => {
      const index = state.list.indexOf(id);
      if (index > -1) {
        Vue.delete(state.list, index);
      }
    });
  },
  setError(state: FileManagerState, error: CustomError) {
    state.error = error;
  },
  reset(state: FileManagerState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): FileManagerState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const fileManager: Module<FileManagerState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
