import {
  Task,
  Module,
} from '@/types';
import { RootState, TaskListState } from '../states';

const getDefaultState = (): TaskListState => ({
  loading: false,
  data: {},
  error: null,
  groupedData: {},
});

const getters = {
  getById: (state: TaskListState) => (id: string) => state.data[id],
};

const mutations = {
  setTask(state: TaskListState, task: Task) {
    state.data = {
      ...state.data,
      [task.id as string]: task,
    };
  },

  setTasks(state: TaskListState, tasks: Task[]) {
    state.data = {
      ...state.data,
      ...tasks.reduce((prev: { [id: string]: Task }, current: Task) => {
        const obj = prev;

        obj[current.id as string] = current;

        return obj;
      }, {}),
    };
  },
  setGroupedTasks(state: TaskListState, tasks: Task[]) {
    tasks.forEach((task: Task) => {
      state.groupedData[task.groupId] = task;
    });

    state.groupedData = { ...state.groupedData };
  },
  setGroupedTask(state: TaskListState, task: Task) {
    state.groupedData[task.groupId] = task;

    state.groupedData = { ...state.groupedData };
  },
  markAsDone(state: TaskListState, groupId: string) {
    if (state.groupedData[groupId]) {
      state.groupedData[groupId].status = 'completed';
    }
  },
  reset(state: TaskListState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): TaskListState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const task: Module<TaskListState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};
