import Vue from 'vue';
import ms from 'ms';

import { TwoFAState } from '@/types';

import file from './file';

// eslint-disable-next-line import/prefer-default-export
export const debounce = (time: number) => {
  let handler: any;
  return (callback: () => void) => {
    if (handler) {
      clearTimeout(handler);
      handler = null;
    }

    handler = setTimeout(() => callback(), time);

    return handler;
  };
};

export const require2FA = (twoFA: TwoFAState | null | undefined) => {
  if (twoFA) {
    const duration = ms(process.env.VUE_APP_2FA_VALIDITY)
                      || 1000 * 60 * 60 * 24; // 24h by default

    return Date.now() <= (twoFA.verifiedAt + duration);
  }

  return false;
};

export const fileUtils = file;

export const renderComponent = (template: string, data: any = undefined) => {
  const res = Vue.compile(template);

  return new Vue({
    data,
    render: res.render,
    staticRenderFns: res.staticRenderFns,
  });
};
