
import { Component } from 'vue-property-decorator';

import { actions } from '@/store';
import { Contact, Document } from '@/types';

import WorkflowComponent from './Workflow';

@Component
export default class WorkflowNewDocumentRequest extends WorkflowComponent {
  documents: Document[] = [];

  updatedDocuments: Document[] = [];

  contact?: string;

  contacts: Contact[] = [];

  get saving() {
    const document = this.$store.state.workflows.newDocuments || {};
    return document[this.id as string]?.saving;
  }

  get loadingContacts() {
    return this.$store.state.workflowContacts.loading;
  }

  onDocumentChange(documents: Document[]) {
    console.log('onDocumentChange', documents);
    this.updatedDocuments = documents;
  }

  beforeMount() {
    this.loadContacts();
  }

  mounted() {
    this.documents = [
      {
        id: `temp_${Date.now()}`,
        selected: true,
        category: '',
        document: '',
      } as any,
    ];
  }

  async loadContacts() {
    const result = await this.$store.dispatch(actions.WORKFLOW_SEARCH_CONTACTS, {
      id: this.id,
      query: {
        filters: {},
      },
    });

    this.contacts = result.results
      .map((id: string) => this.$store.state.workflowContacts.data[id])
      .filter((wc: any) => wc.status === 'accepted')
      .map((wc: any) => wc.contact);
  }

  validate() {
    if (!this.updatedDocuments.some((doc) => doc.selected)) {
      this.handleError({
        detail: {
          code: 'form.thirdParty.document.request.payload.empty.message',
        },
      }, 'form.thirdParty.document.request.error.title');
      return false;
    }

    let valid = true;

    const otherCategoryId = this.$store.getters['categories/otherId'];

    this.updatedDocuments.forEach((doc) => {
      if (doc.selected) {
        if (!this.validateDocument(doc, otherCategoryId)) {
          valid = false;
        }
      }
    });

    this.documents = this.updatedDocuments;

    return valid;
  }

  validateDocument = (doc: Document, otherCategoryId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {};
    let valid = true;

    if (!doc.category) {
      valid = false;
      errors.category = 'Document Category is required';
    }

    if (doc.category === otherCategoryId && !doc.documentName) {
      valid = false;
      errors.documentName = 'Document Name is required';
    }

    if (doc.category !== otherCategoryId && !doc.document) {
      valid = false;
      errors.document = 'Document Type is required';
    }

    if (doc.hasDueDate && !doc.dueDate) {
      valid = false;
      errors.dueDate = 'Due Date is required';
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const matchedDoc: any = doc;
    matchedDoc.errors = errors;
    matchedDoc.showError = true;

    return valid;
  };

  async handleModalSave() {
    let loadingComponent;

    if (!this.contact) {
      this.$buefy.dialog.alert({
        title: this.$tc('requests.new.error.title', 1) as string,
        message: 'Contact is required',
      });

      return;
    }

    if (!this.validate()) {
      return;
    }

    try {
      loadingComponent = this.$buefy.loading.open({
        isFullPage: true,
        canCancel: false,
      });

      await this.$store.dispatch('workflow/newDocumentsRequest', {
        workflow: this.id,
        contact: this.contact,
        documents: this.updatedDocuments,
      });

      this.notify({
        message: this.$t('requests.new.success.message') as string,
        type: 'is-success',
      });

      this.$router.replace({ name: 'ViewWorkflowDocuments', params: { id: this.id as string } });
    } catch (error) {
      this.handleError(error, 'requests.new.error.title');
    }

    if (loadingComponent) {
      loadingComponent.close();
    }
  }
}
