import { Component, Prop } from 'vue-property-decorator';

import { actions } from '@/store';
import { BreadcrumbItem, Contact } from '@/types';
import Layout from '@/layouts/Layout';

const breadcrumbs: BreadcrumbItem[] = [
  {
    title: 'dashboard.menu.dashboard',
    path: '/dashboard',
  },
  {
    title: 'dashboard.menu.contacts',
    path: '/contacts',
  },
];

@Component
export default class ContactComponent extends Layout {
  contact?: Contact | undefined;

  @Prop({ default: '' }) id?: string;

  get pContact(): Contact {
    this.contact = this.$store.getters['contactList/getById'](this.id);

    const contact: any = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    };

    return this.contact || contact;
  }

  get pBreadcrumbs() {
    let items = breadcrumbs;

    const contact = this.$store.getters['contactList/getById'](this.id);

    if (contact) {
      items = [
        ...items,
        {
          title: `${contact.firstName} ${contact.lastName}`,
        },
      ];
    }

    return items;
  }

  created() {
    const { id } = this.$route.params;

    this.id = id;

    if (id) {
      this.loadData();
    }
  }

  async loadData() {
    await this.$store.dispatch(actions.CONTACT_GET_BY_ID, this.id);
  }

  async onDeleteClick(contacts: Contact[]) {
    try {
      await this.$store.dispatch(actions.CONTACT_DELETE, contacts[0].id);

      this.notify({
        message: this.$t('screen.contacts.delete.success.message') as string,
      });

      this.$router.back();
    } catch (error) {
      this.handleError(error, 'screen.contacts.delete.error.title');
    }
  }
}
