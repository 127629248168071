
import get from 'lodash/get';
import { Component, Prop } from 'vue-property-decorator';
import { actions } from '@/store';
import {
  Company,
  Contact,
  PaginatedResult,
  SortItem,
  WorkflowContact,
} from '@/types';

import { identityVerificationStatus } from '@/utils/constants';
import BaseComponent from '../base-component';

@Component
export default class WorkflowContacts extends BaseComponent {
  getField = get;

  intervalHandler: any = 0;

  dataFetched = false;

  @Prop({ default: () => [] })
    sortItems?: SortItem[];

  @Prop()
    selectedSort?: SortItem;

  @Prop()
    company?: string;

  @Prop()
    workflow?: string;

  @Prop()
    status?: string;

  @Prop({ default: () => [] })
    workflowContactIds?: string[];

  @Prop({ default: 'screen.workflows.contact.empty.connectedContacts' })
    emptyMessage?: string;

  get pSortItems() {
    return this.sortItems;
  }

  get pWorkflow() {
    return this.workflow;
  }

  get pStatus() {
    return this.status;
  }

  get pWorkflowContactIds() {
    return this.workflowContactIds;
  }

  get isStatusPending() {
    return this.pStatus === 'pending';
  }

  get pData() {
    // Note: do not remove this line as it makes the proprty to rerender
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const workflowContacts = this.$store.state.workflowContacts.ids;

    const ids = this.workflowContactIds || [];
    const data = (ids).map((id: string) => (this.getById(id)));

    let arr = data;

    if (this.company) {
      arr = data
        .filter((wc) => (((wc.contact as Contact).company as Company).id
          || (wc.contact as Contact).company as string) === this.company);
    }

    arr.sort(this.sort);

    return arr;
  }

  get pEmptyMessage() {
    return this.emptyMessage;
  }

  isVerificationEnabled = (contact: any) => contact?.isVerificationRequired;

  isContactVerified = (contact: any) => identityVerificationStatus.isVerified(contact.onfido);

  getById(id: string) {
    const workflowContact: WorkflowContact = this.$store.getters['workflowContacts/getById'](id);
    workflowContact.contact = this.$store.getters['contactList/getById']((workflowContact.contact as Contact).id || workflowContact.contact);

    return workflowContact;
  }

  created() {
    this.loadStaleData();
    this.loadData();
  }

  async loadData(query = {}) {
    try {
      const result = await this.$store.dispatch(
        actions.WORKFLOW_SEARCH_CONTACTS,
        {
          id: this.workflow,
          status: this.status,
          query,
        },
      );

      this.workflowContactIds = result.results;
    } catch (error) {
      this.handleError(error);
    }

    this.dataFetched = true;
  }

  async onActionClick(e: Event, wc: WorkflowContact, action: string) {
    e.preventDefault();

    const contact = (wc.contact as Contact).id ? (wc.contact as Contact).id : wc.contact as string;

    switch (action) {
      case 'resend-invite':
        // eslint-disable-next-line no-case-declarations
        const resendLoadingRef = this.$buefy.loading.open({
          isFullPage: true,
        });
        try {
          await this.$store.dispatch('workflowInvite/sendLink', {
            contact: contact as string,
            workflow: this.workflow,
          });
          this.$buefy.toast.open({
            message: this.$t('screen.workflows.contact.inviteSent') as string,
            position: 'is-bottom',
          });
        } catch (error) {
          this.handleError(error, 'form.workflowInvite.error.title');
        } finally {
          resendLoadingRef.close();
        }
        break;
      case 'send-reminder':
        // eslint-disable-next-line no-case-declarations
        const smLoadingRef = this.$buefy.loading.open({
          isFullPage: true,
        });
        try {
          await this.$store.dispatch(actions.WORKFLOW_INVITE, {
            workflow: this.workflow,
            contact: contact as string,
          });
          this.$buefy.toast.open({
            message: this.$t('screen.workflows.contact.inviteSent') as string,
            position: 'is-bottom',
          });
        } catch (error) {
          this.handleError(error, 'form.workflowInvite.error.title');
        } finally {
          smLoadingRef.close();
        }
        break;
      case 'enable-identity-verification':
      case 'disable-identity-verification':
        // eslint-disable-next-line no-case-declarations
        const idvLoadingRef = this.$buefy.loading.open({
          isFullPage: true,
        });
        try {
          const isVerificationRequired = action === 'enable-identity-verification';
          await this.$store.dispatch('contact/updateVerification', {
            contactId: contact as string,
            workflowId: this.workflow,
            isVerificationRequired,
          });
          await this.loadData();
          if (isVerificationRequired) {
            this.$buefy.toast.open({
              message: this.$t('screen.workflows.contact.idvEnabled') as string,
              position: 'is-bottom',
            });
          } else {
            this.$buefy.toast.open({
              message: this.$t('screen.workflows.contact.idvDisabled') as string,
              position: 'is-bottom',
            });
          }
        } catch (error) {
          this.handleError(error);
        } finally {
          idvLoadingRef.close();
        }
        break;
      case 'create-task':
        await this.$router.push({
          name: 'CreateTask',
          query: {
            contacts: contact as string,
            workflow: this.workflow,
          },
        });
        break;
      case 'edit':
        await this.$router.push({ name: 'EditContact', params: { id: contact as string } });
        break;
      default:
        this.$emit('onActionClick', this.workflow, wc.contact, action);
    }
  }

  onCompanyChange(company: Company) {
    this.company = company.id;
  }

  onSortChange(item: SortItem) {
    this.selectedSort = item;
  }

  loadStaleData() {
    if (this.$store.state.workflowContacts.ids[this.workflow || ''] && this.$store.state.workflowContacts.ids[this.workflow || ''][this.status || '']) {
      const data = this.$store.state.workflowContacts.ids[this.workflow || ''][this.status || ''] as PaginatedResult<string>;
      this.workflowContactIds = data.results;
    }
  }

  sort(a: any, b: any) {
    // this.dateField = 'invitedAt';
    // this.selectedSort?.key || (this.status === 'accepted' ? 'acceptedAt' : 'invitedAt');
    const field = this.selectedSort?.key || 'invitedAt';

    const time1 = new Date(get(a, field)).getTime();
    const time2 = new Date(get(b, field)).getTime();

    return time2 - time1;
  }

  destroyed() {
    clearInterval(this.intervalHandler);
  }
}
