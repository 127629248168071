
import { Component, Prop } from 'vue-property-decorator';

import { Action } from '@/types';
import BaseComponent from '../base-component';

@Component
export default class ActionList extends BaseComponent {
  @Prop({ default: 0 }) roleType?: number;

  @Prop() selected?: string | undefined;

  get pSelected(): string | undefined {
    return this.selected;
  }

  set pSelected(selected: string | undefined) {
    this.selected = selected;
  }

  get actions() {
    const actions: Action[] = this.$store.getters['actions/list'];
    const roleType = this.roleType as number;

    return actions.filter((action) => (
      action.applicableTo as number > 0
      && (action.applicableTo as number & 2) === 2
    ) || action.applicableTo === undefined);
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.$store.dispatch('actions/list');
  }

  onChange(value: string) {
    this.$emit('change', value);
  }
}
