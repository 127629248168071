import Vue from 'vue';
import { ActionTree } from 'vuex';

import {
  Document,
  DocumentStatus,
  CustomError,
  Module,
  Query,
  Workflow,
  DocumentCommentPayload,
  UpdateDocumentPayload,
  DocumentStatusPayload,
  DocumentAssignFilesPayload,
  ESignRequestPayload,
  ESign,
  WorkflowFile,
} from '@/types';

import { Workflow as api } from '@/api';

import { RootState, WorkflowDocumentsState } from '../states';

interface StatusPayload {
  id: string;
  status?: 'none' | 'generating-url' | 'signing' | 'signed';
  error?: CustomError;
}

const getDefaultState = (): WorkflowDocumentsState => ({
  ids: {},
  data: {},
  loading: false,
  error: null,
  saving: false,
  detail: {
    id: '',
    loading: false,
    error: null,
    saving: false,
  },
  comment: {
    loading: false,
    saving: false,
    error: null,
  },
  status: {
    loading: false,
    saving: false,
    error: null,
  },
  files: {
    loading: false,
    saving: false,
    error: null,
  },
  eSign: {
    data: {},
    loading: false,
    error: null,
  },
});

const getters = {};

const actions: ActionTree<WorkflowDocumentsState, RootState> = {
  async list({ commit }: any, { id, query }: { id: string; query: Query }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[]>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.documents(id, query || {});
        const { data: documents } = resp;

        commit('setLoading', false);
        commit('setData', { id, documents });

        resolve(documents.map((doc) => doc.id as string));
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async detail({ commit }: any, { id, documentId }: { id: string; documentId: string }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      commit('setDetailLoading', true);
      commit('setDetailError', null);

      try {
        const resp = await api.documentDetail(id, documentId);
        const { data } = resp;

        commit('setDetailLoading', false);
        commit('setDetailData', data);

        resolve(data);
      } catch (error) {
        commit('setDetailLoading', false);
        commit('setDetailError', error);

        reject(error);
      }
    });
  },

  async comment({ commit }: any, payload: DocumentCommentPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      commit('setCommentSaving', true);
      commit('setCommentError', null);

      try {
        await api.comment(payload);
        const resp = await api.documentDetail(payload.id as string, payload.documentId);
        const { data } = resp;

        commit('setCommentSaving', false);
        commit('setDetailData', data);

        resolve(data.id as string);
      } catch (error) {
        commit('setCommentSaving', false);
        commit('setCommentError', error);

        reject(error);
      }
    });
  },
  async changeStatus(context: any, payload: DocumentStatusPayload) {
    const { commit } = context;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string>(async (resolve, reject) => {
      commit('setStatusSaving', true);
      commit('setStatusError', null);

      try {
        let documentStatuses: DocumentStatus[] = context.rootGetters['documentStatuses/list'];

        if (!documentStatuses.length) {
          await context.dispatch('documentStatuses/list', {}, { root: true });

          documentStatuses = context.rootGetters['documentStatuses/list'];
        }
        const documentStatus = documentStatuses.find((status) => status.code === payload.status);

        const internalPayload = payload;

        internalPayload.status = documentStatus?.id as string;

        await api.changeDocumentStatus(internalPayload);

        const resp = await api
          .documentDetail(internalPayload.id as string, internalPayload.documentId);

        const { data } = resp;

        commit('setStatusSaving', false);
        commit('setDetailData', data);

        resolve(data.id as string);
      } catch (error) {
        commit('setStatusSaving', false);
        commit('setStatusError', error);

        reject(error);
      }
    });
  },
  async updateDocument({ commit }: any, payload: UpdateDocumentPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setDocumentDetailSaving', true);
      commit('setDocumentDetailError', null);
      try {
        await api.updateDocument(payload);
        commit('setDocumentDetailSaving', false);
        resolve();
      } catch (error) {
        commit('setDocumentDetailSaving', false);
        commit('setDocumentDetailError', error);
        reject(error);
      }
    });
  },
  async assignFiles({ commit }: any, payload: DocumentAssignFilesPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setFilesSaving', true);
      commit('setFilesError', null);

      try {
        await api.assignFiles(payload);

        commit('setFilesSaving', false);

        resolve();
      } catch (error) {
        commit('setFilesSaving', false);
        commit('setFilesError', error);

        reject(error);
      }
    });
  },
  async sendDocuments({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setSaving', true);
      commit('setError', null);

      try {
        await api.sendDocuments(id);

        commit('setSaving', false);

        resolve();
      } catch (error) {
        commit('setSaving', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async eSign({ commit }: any, payload: ESignRequestPayload) {
    commit('setESignLoading', true);

    commit('setESignStatus', {
      id: payload.id,
      status: 'generating-url',
    });

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<ESign>(async (resolve, reject) => {
      try {
        const resp = await api.signUrl(payload);
        const { data } = resp;

        commit('setESignStatus', {
          id: payload.id,
          status: 'signing',
        });

        resolve(data);
      } catch (error) {
        commit('setESignStatus', {
          id: payload.id,
          status: 'none',
        });

        commit('setESignError', {
          id: payload.id,
          error,
        });

        reject(error);
      }

      commit('setESignLoading', false);
    });
  },
};

const mutations = {
  setLoading(state: WorkflowDocumentsState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: WorkflowDocumentsState, error: CustomError) {
    state.error = error;
  },
  setSaving(state: WorkflowDocumentsState, saving: boolean) {
    state.saving = saving;
  },
  setData(state: WorkflowDocumentsState, { id, documents }: { id: string; documents: Document[] }) {
    const data = state.data[id] || {};
    documents.forEach((doc: Document) => {
      data[doc.id as string] = doc;
    });

    state.data[id] = data;

    state.ids = {
      ...state.ids,
      [id]: documents.map((doc) => doc.id as string),
    };
  },

  setDetailLoading(state: WorkflowDocumentsState, loading: boolean) {
    state.detail.loading = loading;
  },
  setDetailError(state: WorkflowDocumentsState, error: CustomError) {
    state.detail.error = error;
  },
  setDetailData(state: WorkflowDocumentsState, document: Document) {
    const workflowDocuments = state.data[(document.workflow as Workflow).id as string] || {};
    state.data[(document.workflow as Workflow).id as string] = workflowDocuments;
    workflowDocuments[document.id as string] = document;

    state.data = { ...state.data };
    state.detail.id = document.id as string;
  },

  setCommentSaving(state: WorkflowDocumentsState, saving: boolean) {
    state.comment.saving = saving;
  },
  setCommentError(state: WorkflowDocumentsState, error: CustomError) {
    state.comment.error = error;
  },

  setStatusSaving(state: WorkflowDocumentsState, saving: boolean) {
    state.status.saving = saving;
  },
  setStatusError(state: WorkflowDocumentsState, error: CustomError) {
    state.status.error = error;
  },

  setFilesSaving(state: WorkflowDocumentsState, saving: boolean) {
    state.files.saving = saving;
  },
  setFilesError(state: WorkflowDocumentsState, error: CustomError) {
    state.files.error = error;
  },

  setDocumentDetailSaving(state: WorkflowDocumentsState, saving: boolean) {
    state.detail.saving = saving;
  },
  setDocumentDetailError(state: WorkflowDocumentsState, error: CustomError) {
    state.detail.error = error;
  },

  setESignLoading(state: WorkflowDocumentsState, loading: boolean) {
    state.eSign.loading = loading;
  },
  setESignStatus(state: WorkflowDocumentsState, payload: StatusPayload) {
    state.eSign.data[payload.id] = {
      id: payload.id,
      status: payload.status,
      error: null,
      loading: false,
    };
  },
  setESignFileStatus(
    state: WorkflowDocumentsState,
    payload: { workflow: string; document: string; file: string },
  ) {
    const data = state.data[payload.workflow];
    const document: Document = data[payload.document];
    const workflowFile: WorkflowFile = (document.files as any[])
      .find((file) => file.id === payload.file);

    if (workflowFile) {
      workflowFile.status = 'signing';
      workflowFile.statusUpdatedAt = new Date().toISOString();

      state.data = { ...state.data };
    }
  },
  setESignError(state: WorkflowDocumentsState, payload: StatusPayload) {
    state.eSign.data[payload.id] = {
      id: payload.id,
      ...state.data[payload.id],
      error: payload.error,
    };
  },
  reset(state: WorkflowDocumentsState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowDocumentsState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflowDocuments: Module<WorkflowDocumentsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
