import { ActionTree } from 'vuex';

import {
  CustomError,
  Document,
  Module,
  Template,
} from '@/types';
import { Template as api } from '@/api';
import { RootState, TemplatesState } from '../states';

const getDefaultState = (): TemplatesState => ({
  all: [],
  loading: false,
  error: null,
  documents: {},
});

const getters = {};

const actions: ActionTree<TemplatesState, RootState> = {
  async getAll({ commit }: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Template[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getAll();
        const { data } = resp;

        commit('setLoading', false);
        commit('setTemplates', data);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async documents({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Document[]>(async (resolve, reject) => {
      commit('setDocumentsLoading', { id, loading: true });
      commit('setDocumentsError', { id, error: null });

      try {
        const resp = await api.documents(id);
        const { data } = resp;

        commit('setDocumentsLoading', { id, loading: false });
        commit('setTemplateDocuments', { id, data });

        resolve(data);
      } catch (error) {
        commit('setDocumentsLoading', { id, loading: false });
        commit('setDocumentsError', { id, error });

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: TemplatesState, loading: boolean) {
    state.loading = loading;
  },
  setTemplates(state: TemplatesState, templates: Template[]) {
    state.all = templates;
  },
  setError(state: TemplatesState, error: CustomError) {
    state.error = error;
  },

  setDocumentsLoading(state: TemplatesState, { id, loading }: { id: string; loading: boolean }) {
    const templateStatus = state.documents[id] || {};

    templateStatus.loading = loading;

    state.documents[id] = { ...templateStatus };
  },

  setDocumentsError(state: TemplatesState, { id, error }: { id: string; error: CustomError }) {
    const templateStatus = state.documents[id] || {};

    templateStatus.error = error;

    state.documents[id] = { ...templateStatus };
  },

  setTemplateDocuments(state: TemplatesState, { id, data }: { id: string; data: Document[] }) {
    const templateStatus = state.documents[id] || {};

    templateStatus.items = data;

    state.documents[id] = { ...templateStatus };
  },

  reset(state: TemplatesState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): TemplatesState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const templates: Module<TemplatesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
