import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isValidEmail from 'validator/es/lib/isEmail';
import isValidMobilePhone from 'validator/es/lib/isMobilePhone';
import isSPassword from 'validator/es/lib/isStrongPassword';

const required = (message: any) => (value: any) => ((isString(value) ? value.trim() : value)
  ? undefined : message);

const min = (length: number, message: any) => (value: any) => {
  if (isString(value)) {
    return value.length >= length ? undefined : message;
  }

  if (isNumber(value)) {
    return value >= length ? undefined : message;
  }

  return message;
};

const minTrimmed = (length: number, message: any) => (value: any) => {
  if (isString(value)) {
    return value.trim().length >= length ? undefined : message;
  }

  if (isNumber(value)) {
    return value >= length ? undefined : message;
  }

  return message;
};

const minButNotRequired = (length: number, message: any) => (value: any) => {
  if (value) {
    return value.length >= length ? undefined : message;
  }

  return undefined;
};

const max = (length: number, message: any) => (value: any) => {
  if (isString(value)) {
    return value.length <= length ? undefined : message;
  }

  if (isNumber(value)) {
    return value <= length ? undefined : message;
  }

  return message;
};

const isEmail = (message: any) => (value: any) => {
  if (value && value.trim()) {
    return isValidEmail(value) ? undefined : message;
  }

  return message;
};

const isMobilePhone = (message: any) => (value: any) => {
  if (value && value.trim()) {
    return isValidMobilePhone(value) ? undefined : message;
  }

  return message;
};

const isStrongPassword = (message: any) => (value: any) => (isSPassword(value, {
  minLength: 8,
  minLowercase: 1,
  minUppercase: 1,
  minNumbers: 1,
  minSymbols: 0,
}) ? undefined : message);

export {
  required,
  min,
  max,
  isEmail,
  isMobilePhone,
  isStrongPassword,
  minButNotRequired,
  minTrimmed,
};

export default {
  required,
  min,
  max,
  isEmail,
  isMobilePhone,
  isStrongPassword,
  minButNotRequired,
  minTrimmed,
};
