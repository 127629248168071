import { AxiosResponse } from 'axios';

import {
  FileDeleteRequest,
  FileRenameRequest,
  FileResponse,
  File,
  Tag,
  PaginatedResult,
} from '@/types';
import { buildSearchQuery } from '@/api/utils';

import {
  jwtApi,
} from './api_base';

export default class {
  static getList(path: string): Promise<AxiosResponse<FileResponse>> {
    return jwtApi.get<FileResponse>(`/file-manager/list?path=${path}`);
  }

  static getRecent(): Promise<AxiosResponse<FileResponse>> {
    return jwtApi.get<FileResponse>('/file-manager/recent');
  }

  static getDetails(path: string): Promise<AxiosResponse<File>> {
    return jwtApi.get<File>(`/file-manager/meta-data?path=${path}`);
  }

  static addFolder(path: string): Promise<AxiosResponse<File>> {
    return jwtApi.post<File>('/file-manager/folder', { path, attributes: [] });
  }

  static fileUpload(path: string, files: any): Promise<AxiosResponse<File>> {
    const formData = new FormData();
    formData.append('files', files);
    formData.append('path', path);
    return jwtApi.post<File>('/file-manager/file-upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  static rename(payload: FileRenameRequest): Promise<AxiosResponse<FileResponse>> {
    return jwtApi.post<FileResponse>('/file-manager/rename', payload);
  }

  static addTag(payload: any): Promise<AxiosResponse> {
    return jwtApi.post('/file-manager/tags', payload);
  }

  static getTags(id: string): Promise<AxiosResponse<Tag[]>> {
    return jwtApi.get<Tag[]>(`/file-manager/tags/${id}`);
  }

  static delete(payload: FileDeleteRequest): Promise<AxiosResponse<FileResponse>> {
    return jwtApi.post<FileResponse>('/file-manager/delete', payload);
  }

  static getFilesByTagId(id: string, query: any): Promise<AxiosResponse<PaginatedResult<File>>> {
    return jwtApi.get<PaginatedResult<File>>(
      `/file-manager/tags/${id}/files${buildSearchQuery(query)}`,
    );
  }

  static search(query: string, cursor: string): Promise<AxiosResponse<FileResponse>> {
    const pageSize = 10;
    let searchQuery = `_q=${query}&pageSize=${pageSize}`;
    if (cursor) {
      searchQuery += `&cursor=${cursor}`;
    }
    return jwtApi.get<FileResponse>(
      `/file-manager/search?${searchQuery}`,
    );
  }
}
