
import { Component } from 'vue-property-decorator';
import { StripeCheckout } from '@vue-stripe/vue-stripe';

import { actions } from '@/store';
import {
  Business,
  CustomError,
  SubscriptionCreateSessionRequestPayload,
  SubscriptionCreateSessionResponse,
  SubscriptionPrice,
} from '@/types';

import BaseComponent from '@/components/base-component';

@Component({
  components: {
    StripeCheckout,
  },
})
export default class Subscription extends BaseComponent {
  publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;

  loading = false;

  isUpgrade = false;

  isExpired = false;

  prices: SubscriptionPrice[] = [];

  subscriptionType = 'full';

  selectedPriceId = '';

  sessionId = '';

  error?: CustomError | null;

  get filteredPrices() {
    return this.prices.filter((price) => {
      if (this.subscriptionType === 'full') {
        return price.nickname !== 'basic';
      }
      return price.nickname === 'basic';
    });
  }

  get isSubscribed() {
    const user = this.$store.getters['auth/identity'];
    const selectedBusiness = this.$store.getters['business/getSelectedBusiness'];
    if (selectedBusiness) {
      const sBusiness = user.businesses.find(
        (business: Business) => business.id === selectedBusiness,
      );
      return sBusiness?.subscription?.status === 'paid';
    }

    return user.subscription?.status === 'paid';
  }

  get isSubscribedBasic() {
    const user = this.$store.getters['auth/identity'];

    const selectedBusiness = this.$store.getters['business/getSelectedBusiness'];
    if (selectedBusiness) {
      return false;
    }

    return user.subscription?.status === 'paid' && user.subscription?.nickname === 'basic';
  }

  mounted() {
    if (this.isSubscribed && !this.isSubscribedBasic) {
      this.$router.replace('/dashboard');
    }
    if (this.$route.query.expired) {
      this.isExpired = true;
    }
    if (this.$route.query.upgrade) {
      this.isUpgrade = true;
    }
  }

  changeSubscriptionType(subscriptionType: string) {
    this.subscriptionType = subscriptionType;
    this.selectedPriceId = '';
  }

  created() {
    this.loadData();
  }

  get isBusiness() {
    return !!this.$store.getters['business/getSelectedBusiness'];
  }

  async loadData() {
    this.error = null;
    try {
      await this.loadPrices();
    } catch (error: any) {
      this.error = error;
    }
  }

  async loadPrices() {
    this.prices = await this.$store.dispatch(actions.PAYMENT_GATEWAY_PRICES);

    this.prices.forEach((price: any) => {
      if (price.interval === 'year') {
        this.selectedPriceId = price.id;
      }
    });
  }

  selectPrice(price: any) {
    this.selectedPriceId = price.id;
  }

  async checkout() {
    const result = await this.createSession();

    if (result) {
      (this.$refs.checkoutRef as any).redirectToCheckout();
    }
  }

  async createSession() {
    try {
      const payload: SubscriptionCreateSessionRequestPayload = {
        mode: 'subscription',
        lineItems: [{
          price: this.selectedPriceId,
          quantity: 1,
        }],
      };

      const data: SubscriptionCreateSessionResponse = await this.$store
        .dispatch(actions.PAYMENT_GATEWAY_CREATE_SESSION, payload);

      this.sessionId = data.id;

      return true;
    } catch (error) {
      this.handleError(error, 'form.subscription.error.title');
    }

    return false;
  }
}
