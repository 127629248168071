var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',[(_vm.pWorkflows.length && !_vm.isCompleted)?_c('li',[_c('router-link',{attrs:{"to":{
        name: 'CreateTask',
        query: {
          workflow: _vm.pWorkflows
            .map((workflow) => workflow.id),
        },
      }}},[_c('b-icon',{attrs:{"icon":"clipboard-clock-outline"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('createTask')))])],1)],1):_vm._e(),(_vm.pWorkflows.length && !_vm.isCompleted)?_c('li',[_c('router-link',{attrs:{"to":{
        name: 'WorkflowInvite',
        query: {
          workflows: _vm.pWorkflows
            .map((workflow) => workflow.id),
        },
      }}},[_c('b-icon',{attrs:{"icon":"account-supervisor"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$tc('inviteContact', 0)))])],1)],1):_vm._e(),(_vm.pWorkflows.length && !_vm.isCompleted)?_c('li',[_c('a',{on:{"click":_vm.onClickRename}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t('editWorkflow')))])],1)]):_vm._e(),(_vm.pWorkflows.length && !_vm.isCompleted)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'WorkflowNewDocumentRequest', params: { id: _vm.pWorkflows[0].id } }}},[_c('b-icon',{attrs:{"icon":"text-box-plus-outline"}}),_c('span',{staticClass:"text"},[_vm._v("New Document Request")])],1)],1):_vm._e(),(_vm.pWorkflows.length)?_c('li',[_c('a',{on:{"click":_vm.handleDelete}},[_c('b-icon',{attrs:{"icon":"delete has-text-primary"}}),_c('span',{staticClass:"text has-text-primary"},[_vm._v(_vm._s(_vm.$t('deleteWorkflow')))])],1)]):_vm._e(),_c('WorkflowRenameModal',{ref:"workflowRenameModal",on:{"onRename":_vm.handleRename}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }