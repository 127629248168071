import Vue from 'vue';

Vue.filter('full-name', (value: any) => {
  if (!value) return '';

  let name = value.firstName;

  if (value.lastName) {
    name += (name ? ' ' : '') + value.lastName;
  }

  return name;
});
