import { format as formatFun, formatRelative as formatRelativeFun, compareDesc } from 'date-fns';
import { enIN } from 'date-fns/locale';

export const stringToBoolean = (str: string) => {
  const regex = /^\s*(true|1|on)\s*$/i;

  return regex.test(str);
};

interface FormatRelativeOptions {
  startWithCaps?: boolean;
}

export const formatRelative = (
  value: Date | string | number | undefined | null,
  options: FormatRelativeOptions = {},
) => {
  if (value) {
    let date = value;

    if (typeof date === 'string') {
      date = new Date(value);
    }

    const result = formatRelativeFun(date, new Date(), { locale: enIN });

    if (options.startWithCaps) {
      return result[0].toUpperCase() + result.substr(1);
    }

    return result;
  }

  return '';
};

interface DateOptions {
  startWithCaps?: boolean;
}

export const formatDate = (value: Date | string | number | undefined | null, format = 'P p') => {
  if (value) {
    let date = value;

    if (typeof date === 'string') {
      date = new Date(value);
    }

    const options = {
      locale: enIN,
    };

    return formatFun(date, format, options);
  }

  return '';
};

export const sortByDateDesc = (key: string) => (a: any, b: any) => {
  const date1 = new Date(a[key] || Number.MAX_SAFE_INTEGER);
  const date2 = new Date(b[key] || Number.MAX_SAFE_INTEGER);

  return compareDesc(date1, date2);
};

export default {
  stringToBoolean,
  formatRelative,
  formatDate,
  sortByDateDesc,
};
