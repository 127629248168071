import { ActionTree } from 'vuex';

import {
  Module,
  Session,
} from '@/types';
import { Session as api } from '@/api';
import storage from '@/storage';
import { RootState, SessionState } from '../states';

const getDefaultState = (): SessionState => ({
  id: storage.localStorage.sessionId,
});

const actions: ActionTree<SessionState, RootState> = {
  async start({ commit }: any):
    Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      const resp = await api.start();
      const { data } = resp;

      commit('setData', data);
      storage.localStorage.sessionId = data.id;
      resolve();
    });
  },
  async update({ state }: any):
    Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      if (state.id) {
        await api.update(state.id);
      }
      resolve();
    });
  },
  async end(context: any):
    Promise<void> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve) => {
      if (context.state.id) {
        await api.end(context.state.id);
      }
      context.commit('reset');
      resolve();
    });
  },
};

const mutations = {
  setData(state: SessionState, data: Session) {
    state.id = data.id;
  },
  reset(state: SessionState) {
    Object.assign(state, getDefaultState());
  },
};

const getters = {};

const state = (): SessionState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const session: Module<SessionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
