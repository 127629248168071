
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BreadcrumbItem, DocumentRequestResult } from '@/types';

import Workflow from './Workflow';

@Component
export default class AHDocumentRequest extends Workflow {
  @Prop() data?: DocumentRequestResult;

  @Watch('this.data.workflow')
  get pBreadcrumbs() {
    let items: BreadcrumbItem[] = [
      {
        title: 'dashboard.menu.dashboard',
        path: '/dashboard',
      },
      {
        title: 'dashboard.menu.workflows',
        path: '/workflows/requests',
      },
    ];

    if (this.data?.workflow) {
      items = [
        ...items,
        {
          title: this.data?.workflow?.name,
        },
      ];
    }
    return items;
  }

  created() {
    this.$store.commit('thirdParty/reset');
  }

  onDataLoaded(data: DocumentRequestResult) {
    this.data = data;
  }
}
