
import { Component, Prop } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  Company, CreateContact,
} from '@/types';
import { actions } from '@/store';
import {
  min, max, required, minButNotRequired,
} from '@/utils/validations';

@Component
export default class AddUpdateContact extends BaseComponent {
  @Prop() btnText?: string;

  @Prop() formHandler?: (payload: any) => any;

  @Prop({ default: false }) dataLoading!: boolean;

  isEmailDisabled = false;

  email = '';

  validators = {
    firstName: [
      required(this.$t('required.field', [this.$t('firstName')])),
      max(30, this.$t('tooLong', [this.$t('firstName')])),
    ],
    lastName: [
      required(this.$t('required.field', [this.$t('lastName')])),
      max(30, this.$t('tooLong', [this.$t('lastName')])),
    ],
    designation: [
      minButNotRequired(2, this.$t('tooShort', [this.$t('designation')])),
    ],
    email: [
      required(this.$t('required.field', [this.$t('email')])),
      min(6, this.$t('tooShort', [this.$t('email')])),
    ],
    phone: [
      required(this.$t('required.field', [this.$t('contactNumber')])),
      max(16, this.$t('tooLong', [this.$t('contactNumber')])),
    ],
  };

  companyName = '';

  companyNameErr = '';

  onSelectCompany(selected: any) {
    if (selected) {
      this.companyName = selected.name;
    }
  }

  assignValues(contact: CreateContact) {
    this.isEmailDisabled = true;
    (this.$refs.firstName as any).changeValue(contact.firstName);
    (this.$refs.lastName as any).changeValue(contact.lastName);
    this.email = contact.email;
    (this.$refs.phone as any).changeValue(contact.phone);
    if (contact.designation) {
      (this.$refs.designation as any).changeValue(contact.designation);
    }
    if (contact.company) {
      this.onSelectCompany(contact.company);
    }
  }

  get allCompanyList() {
    return this.$store.state.companyList.all.filter((company: Company) => company.name
      .toString()
      .toLowerCase()
      .startsWith(this.companyName ? this.companyName.toLowerCase() : ''));
  }

  handleSubmit(values: any) {
    let company;
    this.companyNameErr = '';
    if (this.companyName !== '') {
      if (this.companyName.length < 3) {
        this.companyNameErr = 'Company name is too short';
        return;
      }
      const selectedCompany = this.$store.state.companyList.all.find(
        (cmp: Company) => cmp.name.toLowerCase() === this.companyName.toLowerCase(),
      );
      if (selectedCompany) {
        company = {
          id: selectedCompany.id,
        };
      } else {
        company = {
          name: this.companyName,
        };
      }
    }

    const payload: CreateContact = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email ? values.email : this.email,
      phone: values.phone,
    };

    if (values.designation) {
      payload.designation = values.designation;
    }

    if (company) {
      payload.company = company;
    }

    if (this.formHandler) {
      this.formHandler(payload);
    }
  }

  mounted() {
    this.$store.dispatch(actions.COMPANY_GET_ALL);
  }
}
