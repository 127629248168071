
import { Component } from 'vue-property-decorator';
import ms from 'ms';

import BaseComponent from '@/components/base-component';
import storage from '@/storage';
import { actions } from '@/store';
import { User } from '@/types';
import { debounce } from '@/utils';

const saveLastActiveAtDebounce = debounce(ms('5s'));

const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

const expiryThreshold = ms(process.env.VUE_APP_IDLE_TIME_BEFORE_SESSION_EXPIRY || '30m');

@Component
export default class DashboardLayout extends BaseComponent {
  userFetchHandler: any = 0;

  sessionExpiryHandler: any = 0;

  saveLastActiveAtHandler: any = 0;

  created() {
    this.checkSessionExpiry();

    const { $mq } = this as any;

    if ($mq !== 'sm') {
      this.$store.dispatch(actions.UI_SET_SIDEBAR, {
        expanded: true,
        isMobile: false,
      });
    }

    this.registerFetchUser();

    this.addResetTimeListener();
    this.scheduleSessionExpire();
  }

  async registerFetchUser() {
    clearInterval(this.userFetchHandler);

    await this.fetchUser();

    this.userFetchHandler = setInterval(() => {
      this.fetchUser();
      this.sessionHandler();
    }, ms('10m'));
  }

  async sessionHandler() {
    if (!this.$store.state.session.id) {
      await this.$store.dispatch('session/start');
    } else {
      await this.$store.dispatch('session/update');
    }
  }

  isSubscribed = (user: User) => {
    const subscribedBusiness = user.businesses?.find(
      (business) => business.subscription?.status === 'paid',
    );
    if (subscribedBusiness) {
      return true;
    }
    return user.subscription?.status === 'paid';
  };

  async fetchUser() {
    const user: User = await this.$store.dispatch(actions.USER_ME);

    if (!this.isSubscribed(user)) {
      this.notify({
        message: 'Please renew your subscription to continue using the application.',
        indefinite: true,
      });

      await this.$router.replace('/subscription');
    }
  }

  addResetTimeListener() {
    events.forEach((name) => {
      document.addEventListener(name, this.resetIdleTime, true);
    });
  }

  removeResetTimeListener() {
    events.forEach((name) => {
      document.removeEventListener(name, this.resetIdleTime, true);
    });
  }

  scheduleSessionExpire() {
    this.sessionExpiryHandler = setTimeout(() => {
      this.expireSession();
    }, expiryThreshold);
  }

  resetIdleTime() {
    clearTimeout(this.sessionExpiryHandler);

    this.scheduleSessionExpire();

    this.saveLastActiveAtHandler = saveLastActiveAtDebounce(() => {
      storage.lastActiveAt = Date.now();
    });
  }

  checkSessionExpiry() {
    let { lastActiveAt } = storage;

    if (!lastActiveAt) {
      lastActiveAt = Date.now();
      storage.lastActiveAt = lastActiveAt;
    }

    if (Date.now() > lastActiveAt + expiryThreshold) {
      this.expireSession(false);
    }
  }

  async expireSession(showMessage = true) {
    if (showMessage) {
      this.$buefy.dialog.alert({
        title: 'Session expired',
        message: 'Your session has expired due to inactivity.',
      });
    }

    await this.$store.dispatch(actions.SIGN_OUT);
    await this.$router.replace({ name: 'Home' });
  }

  beforeDestroy() {
    clearTimeout(this.sessionExpiryHandler);
    clearTimeout(this.saveLastActiveAtHandler);
    this.removeResetTimeListener();
  }

  destroyed() {
    clearInterval(this.userFetchHandler);
  }
}
