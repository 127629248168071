
import { Component } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import {
  BreadcrumbItem, CreateWorkflow,
} from '@/types';
import { actions } from '@/store';

@Component
export default class CreateWorkflowComponent extends BaseComponent {
  breadcrumbs: BreadcrumbItem[] = [
    {
      title: 'dashboard.menu.dashboard',
      path: '/dashboard',
    },
    {
      title: 'dashboard.menu.workflows',
    },
  ];

  upgradeModal = false;

  async mounted() {
    if (this.isSubscribedBasic) {
      try {
        await this.$store.dispatch(
          actions.WORKFLOWS_SEARCH,
        );
        this.upgradeModal = this.workflowsList.length >= 2;
      } catch (err) {
        this.handleError(err);
      }
    }
  }

  get isSubscribedBasic() {
    const user = this.$store.getters['auth/identity'];

    const selectedBusiness = this.$store.getters['business/getSelectedBusiness'];
    if (selectedBusiness) {
      return false;
    }

    return user.subscription?.status === 'paid' && user.subscription?.nickname === 'basic';
  }

  get workflowsList() {
    return this.$store.getters['workflowSearch/getWorkflows'];
  }

  onUpgradeConfirm() {
    return this.$router.replace('/subscription?upgrade=full');
  }

  onUpgradeCancel() {
    return this.$router.replace('/workflows');
  }

  formHandler(payload: CreateWorkflow) {
    this.$store.dispatch(actions.WORKFLOW_CREATE, payload)
      .then((id: string) => {
        this.$router.push({ name: 'ViewWorkflowContacts', params: { id } });
      })
      .catch((err) => {
        if (err.detail && err.detail.desc && err.detail.desc === 'basic.subscription') {
          this.upgradeModal = true;
        } else {
          this.handleError(err, 'screen.workflows.create.error.title');
        }
      });
  }
}
