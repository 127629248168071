import Vue from 'vue';

import Layout from './Layout.vue';
import DefaultLayout from './DefaultLayout.vue';
import ThirdPartyLayout from './ThirdPartyLayout.vue';
import OnboardingLayout from './OnboardingLayout.vue';
import RegistrationLayout from './RegistrationLayout.vue';
import DashboardLayout from './DashboardLayout.vue';
import ListViewLayout from './ListViewLayout.vue';
import CreateLayout from './CreateLayout.vue';
import EditLayout from './EditLayout.vue';
import NoContainerLayout from './NoContainerLayout.vue';
import ContentLayout from './ContentLayout.vue';

Vue.component('Layout', Layout);
Vue.component('ListViewLayout', ListViewLayout);
Vue.component('CreateLayout', CreateLayout);
Vue.component('EditLayout', EditLayout);
Vue.component('NoContainerLayout', NoContainerLayout);
Vue.component('ContentLayout', ContentLayout);

export default {
  DefaultLayout,
  RegistrationLayout,
  OnboardingLayout,
  DashboardLayout,
  ThirdPartyLayout,
  NoContainerLayout,
  ContentLayout,
};
