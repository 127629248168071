import { ActionTree } from 'vuex';

import {
  CreateUpdateTaskPayload,
  CustomError,
  Module,
  Query,
  PaginatedResult,
  Task,
} from '@/types';
import { Task as api } from '@/api';
import { RootState, TasksState } from '../states';

const getDefaultState = (): TasksState => ({
  groupedTasks: {
    data: {
      results: [],
      pagination: {
        page: 1,
        pageSize: 10,
      },
    },
    loading: false,
    error: null,
  },
  data: {
    results: [],
    pagination: {
      page: 1,
      pageSize: 10,
    },
  },
  loading: false,
  error: null,
});

const getters = {};

const actions: ActionTree<TasksState, RootState> = {
  async getById({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Task | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getById(id);
        const { data } = resp;

        commit('setLoading', false);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async create({ commit }: any, payload: CreateUpdateTaskPayload) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.create(payload);
        const { data } = resp;

        commit('setLoading', false);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async getAll({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query || {});
        const { data } = resp;
        const result = {
          ...data,
          results: data.results.map((task) => task.id),
        };

        commit('setLoading', false);
        commit('setTasks', result);
        commit('task/setTasks', data.results, { root: true });

        resolve(result);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async getAssignedTasks({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<PaginatedResult<string> | any>(async (resolve, reject) => {
      commit('setGroupedTasksLoading', true);
      commit('setGroupedTasksError', null);

      try {
        const resp = await api.getAssignedTask(query || {});
        const { data } = resp;

        const d = {
          ...data,
          results: data.results.map((task: Task) => task.groupId),
        };

        commit('setGroupedTasksLoading', false);
        commit('setGroupedTasksData', d);
        commit('task/setGroupedTasks', data.results, { root: true });

        resolve(d);
      } catch (error) {
        commit('setGroupedTasksLoading', false);
        commit('setGroupedTasksError', error);

        reject(error);
      }
    });
  },
  async getByGroupId({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        // const query: Query = { pageSize: 5, page: 1, filters: {} };
        const resp = await api.getByGroupId(id);
        const { data } = resp;

        commit('setLoading', false);
        commit('task/setGroupedTask', data, { root: true });

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async groupUpdate({ commit }: any, payload: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.updateTaskByGroup(payload);
        const { data } = resp;

        commit('setLoading', false);

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async markAsDone({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.setGroupStatus(id, {
          status: 'completed',
        });
        const { data } = resp;

        commit('setLoading', false);
        commit('task/markAsDone', id, { root: true });

        resolve(data);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },
  async sendReminder({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        await api.sendReminder(id);

        resolve();
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setLoading', false);
      }
    });
  },
  async getComments({ commit }: any, id: string) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.getCommentsByGroupId(id);
        const { data } = resp;

        resolve(data);
      } catch (error) {
        commit('setError', error);

        reject(error);
      } finally {
        commit('setLoading', false);
      }
    });
  },
};

const mutations = {
  setLoading(state: TasksState, loading: boolean) {
    state.loading = loading;
  },
  setTasks(state: TasksState, { results }: any) {
    state.data = results.map((task: Task) => task.id);
  },
  setContacts(state: TasksState, data: PaginatedResult<string>) {
    state.data = data;
  },
  setGroupedTasksLoading(state: TasksState, loading: boolean) {
    state.groupedTasks.loading = loading;
  },
  setGroupedTasksError(state: TasksState, error: CustomError) {
    state.groupedTasks.error = error;
  },
  setGroupedTasksData(state: TasksState, data: PaginatedResult<string>) {
    state.groupedTasks.data = data;
  },
  setError(state: TasksState, error: CustomError) {
    state.error = error;
  },
  reset(state: TasksState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): TasksState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const tasks: Module<TasksState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
