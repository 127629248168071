
import Vue from 'vue';
import {
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

@Component
export default class Paginator extends Vue {
  @Prop({ default: 0 }) current?: number;

  @Prop({ default: 0 }) total?: number;

  @Prop({ default: 3 }) rangeBefore?: number;

  @Prop({ default: 3 }) rangeAfter?: number;

  @Prop({ default: 10 }) perPage?: number;

  get pCurrentStep() {
    return this.current;
  }

  set pCurrentStep(current: number | undefined) {
    this.current = current;
  }

  get pTotal() {
    return this.total;
  }

  get pRangeBefore() {
    return this.rangeBefore;
  }

  get pRangeAfter() {
    return this.rangeAfter;
  }

  get pPerPage() {
    return this.perPage;
  }

  @Emit('change')
  change() {
    // ignore
  }
}
