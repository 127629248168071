import { ActionTree } from 'vuex';

import {
  CustomError,
  Module,
  PaginatedResult,
  emptyPaginatedResult,
  Query,
} from '@/types';
import { Workflow as api } from '@/api';
import {
  RootState,
  WorkflowSearchState,
} from '../states';

const getDefaultState = (): WorkflowSearchState => ({
  data: [],
  loading: false,
  error: null,
  requests: {
    loading: false,
    error: null,
    data: emptyPaginatedResult(),
  },
});

const getters = {
  getWorkflows(state: WorkflowSearchState) {
    return state.data;
  },
};

const actions: ActionTree<WorkflowSearchState, RootState> = {
  async search({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<string[] | any>(async (resolve, reject) => {
      commit('setLoading', true);
      commit('setError', null);

      try {
        const resp = await api.search(query || {});
        const { data } = resp;

        const ids = data.map((workflow) => workflow.id);

        commit('setLoading', false);
        commit('setWorkflows', ids);
        commit('workflows/setWorkflows', data, { root: true });

        resolve(ids);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', error);

        reject(error);
      }
    });
  },

  async requests({ commit }: any, query: Query) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<PaginatedResult<string>>(async (resolve, reject) => {
      commit('setRequestsLoading', true);
      commit('setRequestsError', null);

      try {
        const resp = await api.requests(query || {});
        const { data } = resp;

        const workflows = data.results.map((workflow) => workflow);

        const result: PaginatedResult<string> = {
          ...data,
          results: data.results.map((workflow) => workflow.id as string),
        };

        commit('setRequestsLoading', false);
        commit('setRequestsWorkflows', result);
        commit('workflows/setWorkflows', workflows, { root: true });

        resolve(result);
      } catch (error) {
        commit('setRequestsLoading', false);
        commit('setRequestsError', error);

        reject(error);
      }
    });
  },
};

const mutations = {
  setLoading(state: WorkflowSearchState, loading: boolean) {
    state.loading = loading;
  },
  removeWorkflow(state: WorkflowSearchState, id: string) {
    state.data = state.data.filter((key) => key !== id);
  },
  setWorkflows(state: WorkflowSearchState, data: string[]) {
    state.data = data;
  },
  setError(state: WorkflowSearchState, error: CustomError) {
    state.error = error;
  },

  setRequestsLoading(state: WorkflowSearchState, loading: boolean) {
    state.requests.loading = loading;
  },
  setRequestsWorkflows(state: WorkflowSearchState, data: PaginatedResult<string>) {
    state.requests.data = data;
  },
  setRequestsError(state: WorkflowSearchState, error: CustomError) {
    state.requests.error = error;
  },
  reset(state: WorkflowSearchState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): WorkflowSearchState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const workflowSearch: Module<WorkflowSearchState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
