
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseComponent from '@/components/base-component';
import { Document } from '@/types';

@Component
export default class ThirdPartyDocumentListInputItem extends BaseComponent {
  @Prop({ default: () => ({}) }) document?: Document;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ default: () => ({}) }) options: any;

  get pDocument() {
    return this.document;
  }

  @Watch('$store.state.categories.ids')
  onCategoriesLoad() {
    this.options = {
      ...this.options,
      showOther: this.$store.getters['categories/otherId'] === this.pDocument?.category,
    };
  }

  handleCheckChange(selected: boolean) {
    const { id } = this.document as Document;

    this.$emit('checkChanged', { id, selected });
  }

  handleCategoryChange(category: string) {
    const { id } = this.document as Document;

    this.$emit('categoryChanged', { id, category });

    this.options = {
      ...this.options,
      showOther: this.$store.getters['categories/otherId'] === category,
    };
  }

  handleDocumentChange(document: string) {
    const { id } = this.document as Document;

    this.$emit('documentTypeChanged', { id, document });
  }

  onDueDateChange(date: Date) {
    (this.document as Document).dueDate = date;
  }

  handleAddInfo() {
    this.$emit('showInfo', this.document);
  }
}
