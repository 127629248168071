import { AxiosResponse } from 'axios';

import {
  Document,
  Template,
} from '@/types';

import {
  jwtApi,
} from './api_base';

export default class {
  static getAll(): Promise<AxiosResponse<Template>> {
    return jwtApi.get<Template>('/templates');
  }

  static documents(id: string): Promise<AxiosResponse<Document[]>> {
    return jwtApi.get<Document[]>(`/templates/${id}/documents`);
  }
}
