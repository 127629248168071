import { ActionTree } from 'vuex';

import {
  DocumentType as api,
} from '@/api';

import {
  CustomError,
  DocumentType,
  Module,
} from '@/types';

import { RootState, DocumentTypesState } from '../states';
import { Callback, callback } from './utils';

const getDefaultState = (): DocumentTypesState => ({
  data: {},
  ids: [],
  loading: false,
  error: null,
});

const getters = {
  list: (state: DocumentTypesState) => state.ids.map((id) => state.data[id]),
};

let listPromises: Callback[] = [];

const actions: ActionTree<DocumentTypesState, RootState> = {
  async list({ commit, state }: any) {
    if (state.loading) {
      const cb = callback();

      listPromises.push(cb);

      return cb.promise;
    }

    commit('setLoading', true);
    commit('setError', null);

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<DocumentType[] | any>(async (resolve, reject) => {
      try {
        const resp = await api.list();
        const { data } = resp;
        const ids = data.map((item) => item.id);

        commit('setData', data);
        commit('setIds', ids);

        resolve(ids);

        listPromises.forEach((cb) => {
          cb.resolve(ids);
        });
      } catch (error) {
        commit('setError', error);

        reject(error);

        listPromises.forEach((cb) => {
          cb.reject(error);
        });
      }

      commit('setLoading', false);

      listPromises = [];
    });
  },
};

const mutations = {
  setLoading(state: DocumentTypesState, loading: boolean) {
    state.loading = loading;
  },
  setError(state: DocumentTypesState, error: CustomError) {
    state.error = error;
  },
  setData(state: DocumentTypesState, data: DocumentType []) {
    const d = state.data || {};

    data.forEach((item) => {
      d[item.id || ''] = item;
    });

    state.data = d;
  },

  setIds(state: DocumentTypesState, ids: string[]) {
    state.ids = ids;
  },
  reset(state: DocumentTypesState) {
    Object.assign(state, getDefaultState());
  },
};

const state = (): DocumentTypesState => getDefaultState();

// eslint-disable-next-line import/prefer-default-export
export const documentTypes: Module<DocumentTypesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
