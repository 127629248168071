
import { Component, Prop, Watch } from 'vue-property-decorator';

import BaseComponent from '@/components/base-component';
import layouts from '@/layouts';
import { Document } from '@/types';

@Component
export default class InviteDocumentList extends BaseComponent {
  globalCheck = true;

  showModal = false;

  modalItem: Document | any = {};

  internalDocuments: Document[] = [];

  @Prop({ default: () => [] }) documents?: Document[];

  @Prop({ default: () => false }) loading?: boolean;

  get pLoading() {
    return this.loading;
  }

  @Watch('documents')
  onDocumentChange(documents: Document[]) {
    this.internalDocuments = documents.map((document) => ({ ...document }));
  }

  @Watch('internalDocuments')
  onInternalDocumentChange(documents: Document[]) {
    this.$emit('onChange', documents);
  }

  toggleGlobalCheck(selected: boolean) {
    this.globalCheck = selected;

    this.internalDocuments = this.internalDocuments.map((document) => ({ ...document, selected }));
  }

  handleAddRow() {
    this.internalDocuments = [
      ...this.internalDocuments,
      {
        id: `temp_${Date.now()}`,
        selected: true,
        category: '',
        document: '',
      } as any,
    ];
  }

  checkChanged({ id, selected }: { id: string; selected: boolean }) {
    this.internalDocuments = this.internalDocuments.map((document) => {
      if (document.id === id) {
        return {
          ...document,
          selected,
        };
      }

      return document;
    });

    const selectedCount = this.internalDocuments.filter((doc) => doc.selected).length;

    this.globalCheck = this.internalDocuments.length === selectedCount;
  }

  categoryChanged({ id, category }: { id: string; category: string }) {
    this.internalDocuments = this.internalDocuments.map((document) => {
      if (document.id === id) {
        return {
          ...document,
          category,
        };
      }

      return document;
    });
  }

  documentTypeChanged({ id, document }: { id: string; document: string }) {
    this.internalDocuments = this.internalDocuments.map((doc) => {
      if (doc.id === id) {
        return {
          ...doc,
          document,
        };
      }

      return doc;
    });
  }

  handleShowModal(document: Document) {
    const { action } = document;

    this.modalItem = {
      ...document,
      action: action || '',
      files: document.files || [],
    };

    this.showModal = true;
  }

  handleCloseModal() {
    this.showModal = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.modalItem = {} as any;
  }

  onActionChange(action: string) {
    this.modalItem = {
      ...this.modalItem,
      action,
    } as Document;
  }

  handleModalSave() {
    this.internalDocuments = this.internalDocuments.map((doc) => {
      if (doc.id === this.modalItem.id) {
        return this.modalItem;
      }

      return doc;
    });

    this.showModal = false;
  }
}
