var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c-tasks__lists__item",class:{
    'c-tasks__lists__item--danger': _vm.showDueDateWarning(_vm.pTask) && _vm.pTask.status !== 'completed',
  }},[_c('router-link',{attrs:{"to":_vm.taskLink}},[(_vm.pTask.assignedBy === 'contact')?_c('div',{staticClass:"left"},[_c('Avatar',{attrs:{"name":_vm._f("full-name")(_vm.pTask.contact)}})],1):_c('div',{staticClass:"left"},[_c('Avatar',{attrs:{"name":_vm._f("full-name")(_vm.pTask.user)}})],1)]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"is-flex is-justify-content-space-between"},[_c('router-link',{staticClass:"is-flex is-flex-grow-1 is-justify-content-space-between",attrs:{"to":_vm.taskLink}},[_c('h3',[_c('span',{staticClass:"task-title"},[_vm._v(" "+_vm._s(_vm.pTask.title)+" ")]),(_vm.pTask.assignedBy === 'contact')?_c('p',{staticClass:"sub-text is-size-7"},[_vm._v(" Requested by: "+_vm._s(_vm._f("full-name")(_vm.pTask.contact))+" ")]):_vm._e(),(_vm.pTask.assignedBy === 'user'
              && _vm.pTask.assignedTo !== 'user')?_c('p',{staticClass:"sub-text is-size-7"},[_vm._v(" Assigned to: "+_vm._s(_vm.contactNames)+" ")]):_vm._e(),(_vm.pTask.assignedBy === 'user'
              && _vm.pTask.assignedTo === 'user')?_c('p',{staticClass:"sub-text is-size-7"},[_vm._v(" Assigned to "+_vm._s(_vm.selectedBusiness ? 'business' : 'me')+" ")]):_vm._e()]),(_vm.pTask.dueDate && _vm.pTask.status !== 'completed')?_c('span',{staticClass:"sub-text has-text-weight-semibold",class:{
            'has-text-primary': _vm.showDueDateWarning(_vm.pTask) && _vm.pTask.status !== 'completed',
          }},[_vm._v(" "+_vm._s(_vm._f("formatRelative")(_vm.pTask.dueDate,{ startWithCaps: true }))+" ")]):_vm._e()]),(_vm.isOwner && !_vm.isCompleted)?_c('div',{staticClass:"action-with-btn is-flex is-flex-direction-column is-align-items-flex-end"},[_vm._t("actions",function(){return [_c('TaskActions',{attrs:{"task":_vm.pTask},on:{"onActionClick":_vm.onActionClick}})]})],2):_vm._e(),(_vm.isCompleted)?_c('div',{staticClass:"action-with-btn is-flex is-flex-direction-column is-align-items-flex-end is-justify-content-center"},[_vm._t("actions",function(){return [_c('b-button',{attrs:{"type":"is-success is-small is-outlined"}},[_vm._v(" Completed ")]),(_vm.pTask.updated_by)?_c('p',{staticClass:"sub-text is-size-7 mb-0 mt-1"},[_vm._v(" By: "+_vm._s(_vm.pTask.updated_by.firstName)+" "+_vm._s(_vm.pTask.updated_by.lastName)+" ")]):_vm._e()]})],2):_vm._e()],1),_c('router-link',{staticClass:"is-flex is-flex-grow-1",attrs:{"to":_vm.taskLink}},[_c('div',{staticClass:"is-flex is-flex-wrap is-justify-content-space-between is-align-items-center",staticStyle:{"width":"100%"}},[_c('b-tag',{attrs:{"type":_vm.showDueDateWarning(_vm.pTask) && _vm.pTask.status !== 'completed' ? 'is-white' : ''}},[_c('img',{attrs:{"src":require("../../assets/flag.svg"),"width":"18","alt":""}}),_c('span',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(" ")+_vm._s(_vm.pTask.workflow.name)+" ")])]),(_vm.showMarkAsDone)?_c('b-button',{attrs:{"type":"is-primary is-small is-outlined","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.markAsDone.apply(null, arguments)}}},[_vm._v(" Mark as Done ")]):_vm._e()],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }